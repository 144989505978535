import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { WebserviceService } from './webservice.service';
import { UserApiService } from './user-api.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { InterfaceTextService } from './interface-text.service';
import { EventManagerService } from './common/event-manager.service';

declare var paypal;
declare var Stripe;

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  storeProvider: any;
  productList: any;


  private productConfig: any = null;

  private purchase_end = new Subject<any>();
  onPurchaseEnd = this.purchase_end.asObservable();

  private validation_start = new Subject<any>();
  onValidationStart = this.validation_start.asObservable();
  private eventListeners:any = {};
  constructor(
    private store: InAppPurchase2,
    private platform: Platform,
    private webservice: WebserviceService,
    private userApi: UserApiService,
    private interfaceText: InterfaceTextService,
    private eventManager: EventManagerService
  ) { 
    if (this.eventListeners.langUpdateConfirmed) this.eventListeners.langUpdateConfirmed.unsubscribe();
    this.eventListeners.langUpdateConfirmed = this.eventManager.bind('language.update_confirmed').subscribe(async (promptOptn:any)=>{
      this.productList = await this.getProductList();
    });

  }

  sID: any = 1;
  _count: number = 1;

  // temp implementation, move to dedicated file later
  benifitList: any = null;
  loadBodyText() {
    let screen_name = "common";
    return this.interfaceText.getBodyText(screen_name);
  }
  loadAdditionalData() {
    return new Promise((resolve) => {
      this.benifitList = environment.layoutVersion == 3 ? this.loadBodyText()['plan_benifitsV3'] : this.loadBodyText()['plan_benifits'];
      resolve(null);
    });
  }


  isPremiumUser() {
    let promise = new Promise((resolve, reject) => {
      this.userApi.getUser().then((user: any) => {
        if (user.jwt) {
          if (user.premium) {
            let paymentInfo = {
              premium: user.premium,
              suscription: user.suscription
            }
            resolve(paymentInfo);
          } else {
            reject();
          }
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  canPurchase() {
    let promise = new Promise((resolve, reject) => {
      this.userApi.getUser().then((user: any) => {
        if (user.jwt) {
          if (user.active) {
            resolve(null);
          } else {
            reject({ inactive: true, jwt: user.jwt });
          }
        } else {
          reject({ nouser: true });
        }
      }).catch(() => {
        reject({ nouser: true });
      });
    });
    return promise;
  }


  /**
   * init iap store
   */
  private initIAPStore() {
    this.establishStoreConnection();
    this.storeRefresh();
  }
  getAllProducts() {
    return this.productConfig;
  }
  getProductList(provider?:string) {
    return new Promise((resolve, reject) => {
      let configUrl = environment.plansConfigDir + environment.language + '/' + 'products'+(environment.production ? '': '.sandbox')+'.json';
      this.webservice.get(configUrl, { bucket: true, local: false }, response => {
      // this.webservice.get(environment.plansConfig, { bucket: false, local: true }, response => {
        this.productConfig = response;
        
        let iap_product_config: any = null, iap_product: Array<any> = [];
        if(provider) {
          iap_product_config = response[provider];
        } else {
          if (this.storeProvider == 'ios') iap_product_config = response["ios-appstore"];
          else if (this.storeProvider == 'android') iap_product_config = response["android-playstore"];
          else iap_product_config = iap_product_config = response["paypal"];
        }
        

        for (let k in iap_product_config) {
          let p: any = iap_product_config[k];
          p.id = k;
          p.type = this.store[iap_product_config[k].type];
          iap_product.push(p);
        }
        // iap_product.sort((a, b) => (a.duration_level < b.duration_level) ? 1 : ((b.duration_level < a.duration_level) ? -1 : 0));
        iap_product.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        resolve(iap_product);
      }, err => {
        reject();
      });

    });


  }

  private establishStoreConnection() {

    this.store.verbosity = this.store.QUIET;
    environment.production ? () => { } : this.store.autoFinishTransactions = true;
    this.setStoreValidator();

    for (let i = 0; i < this.productList.length; i++) {
      this.registerProducts(this.productList[i]);
      this.getProduct(this.productList[i].id).catch((error) => { });
      this.productEventHandler(this.productList[i]);
    }

  }

  /**
   * register products in the app
   */
  private registerProducts(product: any) {
    this.store.register(product);
  }


  /**
   * get events from Appstore/Playstore
   */

  private productEventHandler(product: any) {
    this.store.when(product.id).approved((p) => {
      // console.log("in approved > ", p);
      console.log("in approved > ");
      p.verify();
    });

    this.store.when(product.id).verified((p) => {
      // console.log("in verified > ", p);
      console.log("in verified > ");
      p.finish();
    });

    this.store.when(product.id).updated((p) => {
      // console.log("in updated > ", p);
      console.log("in updated > ", p);
      p.verify();
    });

  }



  /**
  * validator, to call service in emo server 
  * to validte the product purchase status
  */
  validatorTimeout: any = null;
  private setStoreValidator() {
    this.store.validator = (product: any, callback: any) => {

      console.log("========================= in validator ========================== ", product);
      // console.log(product.transaction.appStoreReceipt);

      this.validation_start.next(this.currentOrderIndex);

      this.emoValidator(product, (res?: any) => {
        this.purchase_end.next(res);
        typeof callback == 'function' && res && res.user ? callback(true, { applicationUser: res.user }) : callback(true, product.transaction);
      });

    }
  }

  iapUserMismatch: boolean = false;
  emoValidator(product: any, callback: any) {

    this.userApi.getUser().then((user: any) => {

      if (!user.jwt) {
        let obj = { ok: false }
        typeof callback == 'function' ? callback(obj) : () => { };
      }

      if (user && user.suscription && user.suscription.method) {
        let store = null;
        if (this.storeProvider == 'ios') {
          store = "ios-appstore";
        } else if (this.storeProvider == 'android') {
          store = "android-playstore";
        }

        if (store && user.premium && user.suscription.method != store) {
          let obj = { ok: false }
          typeof callback == 'function' ? callback(obj) : () => { };
          return;
        }
      }

      let validationData: object = {
        token: user.jwt,
        data: {
          receipt: product.transaction.type == 'ios-appstore' ? product.transaction.appStoreReceipt : product.transaction.receipt,
          provider: product.transaction.type
        }
      }

      this.webservice.post('validate', validationData, response => {
        this.iapUserMismatch = false;
        if (response.ok) {
          this.userApi.setUser(this.userApi.user).then(() => {
            let obj = { ok: true, premium: true, user: this.userApi.user.username };
            typeof callback == 'function' ? callback(obj) : () => { };
          }).catch(() => {
            let obj = { ok: false }
            typeof callback == 'function' ? callback(obj) : () => { };
          });

        } else {

          if (response.error == "not_unique") {
            this.iapUserMismatch = true;
          }

          if (this.userInitiatedPurchase) {
            typeof callback == 'function' ? callback(response) : () => { };
          } else {
            let obj = { ok: false }
            this.purchase_end.next(obj);
            typeof callback == 'function' ? callback(obj) : () => { };
          }


        }

        this.userInitiatedPurchase = false;
      }, error => {
        let obj = { ok: false }
        typeof callback == 'function' ? callback(obj) : () => { };
        this.userInitiatedPurchase = false;
      });
    }).catch(() => {
      let obj = { ok: false }
      typeof callback == 'function' ? callback(obj) : () => { };
    });
  }


  /**
  * get product details
  */
  private getProduct(productId: any) {
    let promise = new Promise((resolve, reject) => {
      let iap_product = this.store.get(productId);
      if (!iap_product) {
        reject("product not defined");
      } else if (iap_product.state === this.store.REGISTERED) {
        let now = new Date().getTime();
        console.log("Product loading at " + now + " > ", iap_product);
        reject("loading");
      } else if (iap_product.state === this.store.INVALID) {
        reject("product not valid");
      } else {
        resolve(iap_product);
      }
    });
    return promise;
  }

  /**
  * order any product to purchase
  */
  currentOrderIndex: string = null;
  private userInitiatedPurchase: boolean = false;


  orderProduct(index: any) {
    let promise = new Promise((resolve, reject) => {
      this.userApi.getUser().then((user: any) => {
        if (user.jwt) {

          this.currentOrderIndex = index;
          this.userInitiatedPurchase = true;


          if (this.storeProvider == 'ios' || this.storeProvider == 'android') {
            this.getProduct(this.productList[index].id).then(async (iap_product: any) => {
              if (this.storeProvider == 'android' && user.premium) {
                let oldPurchase: any = null;
                if (user.suscription && user.suscription.duration) {
                  for (let i = 0; i < this.productList.length; i++) {
                    if (parseInt(this.productList[i].code) == parseInt(user.suscription.duration)) {
                      oldPurchase = await this.getProduct(this.productList[i].id);
                      break;
                    }
                  }
                } else {
                  oldPurchase = await this.getProduct(this.productList[0].id);
                }
                if (oldPurchase.transaction) {
                  let additionalData = {
                    oldPurchasedSkus: [oldPurchase.id],
                    applicationUsername: user.username
                  }
                  this.store.order(iap_product, additionalData).error((err) => {
                    console.log("order error ", err);
                  }).then((re) => {
                    console.log("order success ", re);
                  });
                } else {
                  let additionalData = {
                    applicationUsername: user.username
                  }
                  this.store.order(iap_product).error((err) => {
                    console.log("order error ", err);
                  }).then((re) => {
                    console.log("order success ", re);
                  });
                }
              } else {


                console.log("order product", iap_product);
                this.store.order(iap_product).then((re) => {
                  console.log("successfully order ", re);
                  // bind user email with receipt
                  this.store.applicationUsername = user.username;
                  console.log(user);

                }).error((err) => {
                  console.log("order error", err);
                });
                console.log("after order call");

                // this.store.order(iap_product).error(() => {
                //   setTimeout(() => {
                //     this.store.refresh();
                //   }, 10000);
                // }).then(() => {
                //   setTimeout(() => {
                //     this.store.refresh();
                //   }, 10000);
                // });
                // this.store.refresh();
              }
              resolve();
            }).catch((err) => {
              console.log(err);
              reject(err);
            });
          }
        }
      });
    });
    return promise;
  }

  /**
  * order any product to purchase
  */
  getPrice(index: any) {
    let promise = new Promise((resolve, reject) => {
      if (this.storeProvider == 'ios' || this.storeProvider == 'android') {
        this.getProduct(this.productList[index].id).then((iap_product: any) => {

          // this.userApi.getUser().then((user: any) => {
          //   let postData: any = {token: user.jwt, data: { product: iap_product }}
          //   this.webservice.post("ios-product", postData, response => {}, (error: any) => {});
          // });

          console.log(iap_product)
          let _pr=(iap_product.priceMicros/1000000).toFixed(2);
          let curSign = iap_product.price.replace(/\d|\.|,/g, '')
          let _price = {
            "duration" : this.productList[index].duration || {},
            "price": iap_product.price,
            "amount": _pr,
            "currency": curSign.trim(),
          }
         console.log(_price);

          resolve(_price);
        }).catch((err) => {
          this.store.when(this.productList[index].id).valid((p: any) => {
            resolve(p.price);
          });
        });
      } else {
        let _price = {
          "duration" : this.productList[index].duration,
          "price": this.productList[index].price,
          "amount": this.productList[index].amount,
          "currency": this.productList[index].currency,
        }
        resolve(_price);
      }
    });
    return promise;
  }

  /**
   * refresh store to initialize iap process or
   * initialize restore purchase
   */
  storeRefresh() {
    environment.production ? () => { } : this.store.autoFinishTransactions = true;
    this.store.refresh();
  }


  /**
   * Initialize iap
   */
  initialize() {
    return new Promise(async (resolve, reject) => {
      this.storeProvider = this.platform.is('cordova') ? this.platform.is('android') ? 'android' : 'ios' : 'web';
      console.log("this.storeProvider ", this.storeProvider)
      this.productList = await this.getProductList();
      if (this.storeProvider == 'ios' || this.storeProvider == 'android') {
        this.initIAPStore();
      } else { }
      resolve({ ok: true });
    });
  }



  /**
   * initialize paypal
   */
  loadPaypal() {
    let promise = new Promise((resolve, reject) => {
      if (window.document.getElementById('paypal-api-script')) window.document.getElementById('paypal-api-script').remove();

      if (!window.document.getElementById('paypal-api-script')) {
        var s = window.document.createElement("script");
        s.id = "paypal-api-script";
        s.type = "text/javascript";
        s.src = "https://www.paypal.com/sdk/js?client-id=" + environment.paypalClientId + "&vault=true";
        s.onload = () => {
          resolve();
        }
        s.onerror = (err) => {
          reject(err);
        }
        window.document.body.appendChild(s);


      } else {
        resolve();
      }
    });
    return promise
  }
  initPaypalPurchase(_id: any, button: any) {
    let self = this;
    let paypal_plan: any = null;
    for (let i = 0; i < this.productList.length; i++) {
      if (parseInt(this.productList[i].code) == parseInt(_id)) {
        paypal_plan = this.productList[i].id;
      }
    }

    paypal.Buttons({
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          'plan_id': paypal_plan,
        });
      },
      onApprove: function (data, actions) {
        console.log(data);
        //alert('You have successfully created subscription ' + data.subscriptionID);  
        self.onPaypalPaymentApprove(data.subscriptionID, (success) => {
          let obj = { ok: true, premium: true }
          self.purchase_end.next(obj);
        }, (error) => {
          let obj = { ok: false }
          self.purchase_end.next(obj);
        });
      },
      onCancel: function (data) {
        // Show a cancel page, or return to cart  
        console.log(data);
        let obj = { ok: false }
        self.purchase_end.next(obj);
      },
      onError: function (err) {
        // Show an error page here, when an error occurs  
        console.log(err);
        let obj = { ok: false }
        self.purchase_end.next(obj);
      }
    }).render(button);

  }

  onPaypalPaymentApprove(subscriptionID, onSuccess, onError) {
    this.userApi.getUser().then((user: any) => {
      if (user.jwt) {
        let postData: any = {
          token: user.jwt,
          data: { subscriptionID: subscriptionID }
        };
        this.webservice.post("paypal/verify", postData, response => {
          this.userApi.setUser(user).then(() => {
            onSuccess(response);
          }).catch(() => { onError({ error: 'cant set user' }); });
        }, (error: any) => {
          console.log(error);
          onError(error);
        });
      }
    });
  }


  /**
   * Stripe payment implementations
   */
  stripe: any = null;
  stripeElements: any = null;
  initStripePurchase() {
    let promise = new Promise((resolve, reject) => {
      if (!window.document.getElementById('stripe-custom-form-script')) {
        var s = window.document.createElement("script");
        s.id = "stripe-custom-form-script";
        s.type = "text/javascript";
        s.src = "https://js.stripe.com/v3/";
        s.onload = () => {
          this.stripe = Stripe(environment.stripePublishKey);
          this.stripeElements = this.stripe.elements();
          let stripeObj = {
            stripe: this.stripe,
            elements: this.stripeElements
          }
          resolve(stripeObj);
        }
        s.onerror = () => {
          reject();
        }
        window.document.body.appendChild(s);
      } else {
        let stripeObj = {
          stripe: this.stripe,
          elements: this.stripeElements
        }
        resolve(stripeObj);
      }
    });
    return promise;
  }

  createStripeSession(duration:any, options?:any) {
    let promise = new Promise((resolve, reject) => {
      this.userApi.getUser().then((user: any) => {
        if (user.jwt) {
          let postData: any = {
            token: user.jwt,
            data: { }
          };
          if(options && options.successUrl) postData.data.successUrl = options.successUrl;
          if(options && options.cancelUrl) postData.data.cancelUrl = options.cancelUrl;
          this.webservice.post("stripe/session/" + duration + (options && options.redeem ? '/redeem' : ''), postData, response => {
            if (response.ok) {
              resolve(response);
              this.openStripeChckout(response.sessionId)
            } else {
              reject();
            }
          }, (error: any) => {
            console.log(error);
            reject(error);
          });
        }
      });
    });
    return promise;
  }

  openStripeChckout(sessionId: any) {
    this.stripe.redirectToCheckout({
      sessionId: sessionId
    }).then(function (result) {
      console.log(result);
    });
  }

  onStripePaymentSuccess(sessionId) {
    let promise = new Promise((resolve, reject) => {
      this.userApi.getUser().then((user: any) => {
        if (user.jwt) {
          let postData: any = {
            token: user.jwt,
            data: { sessionId: sessionId }
          };
          this.webservice.post("stripe/success", postData, async (response) => {
            if (response.ok) {
              await this.userApi.resetJWT(true);
              this.userApi.setUser(user).then(() => {
                resolve(response);
              }).catch(() => { });
            }
          }, (error: any) => {
            console.log(error)
            //reject(error);
          });
        }
      });
    });
    return promise;
  }

}
