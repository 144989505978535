import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  isOnline: boolean = true;
  type: string;
  networkChangeCallback:any;

  private on_network_change = new Subject<any>();
  onNetworkChange = this.on_network_change.asObservable();

  constructor(
    private network: Network
  ) { 

    this.network.onDisconnect().subscribe(() => {
      this.isOnline = false;
      this.type = '';
      let re = {isOnline : this.isOnline, type: ''}
      this.on_network_change.next(re);
    });

    this.network.onConnect().subscribe(() => {
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      setTimeout(() => {
        if (this.network.type) {
          this.isOnline = true;
          this.type = this.network.type;
          let re = {isOnline : this.isOnline, type: this.type}
          this.on_network_change.next(re);
        }
      }, 3000);
    });

  }


  /*onNetWorkChange(callback:(re:any)=>any) {
    this.networkChangeCallback = callback;
  }*/

  getNetworkStatus(callback:(re:any)=>any) {
    let re = {isOnline : this.isOnline, type: this.type}
    callback(re);
  }
}
