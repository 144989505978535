import { Injectable } from '@angular/core';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import * as firebase_web from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import { environment } from '../../environments/environment';
import { WebserviceService } from './webservice.service';
import { UserFlagsService } from './common/user-flags.service';
import { AppRateService } from './app-rate.service';
import { SharedDataService } from './common/shared-data.service';
import { EventManagerService } from './common/event-manager.service';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseEventsService {
  analyticsWeb: any;
  private eConfig: Array<any> = [];
  notificationDeeplink: any = null;
  private eventListeners:any = {};
  constructor(
    private firebase: FirebaseX,
    private platform: Platform,
    private device: Device,
    private webservice: WebserviceService,
    private userFlags: UserFlagsService,
    private appRate : AppRateService,
    public sharedDataService:SharedDataService,
    private eventManager: EventManagerService
  ) {
    
    if (!this.platform.is("cordova")) {
      if (!firebase_web.default.apps.length) { 
        firebase_web.default.initializeApp(environment.firebaseConfig);
      }
      this.analyticsWeb = firebase_web.default.analytics();
    }

    if (this.eventListeners.langUpdateConfirmed) this.eventListeners.langUpdateConfirmed.unsubscribe();
    this.eventListeners.langUpdateConfirmed = this.eventManager.bind('language.update_confirmed').subscribe(async (promptOptn:any)=>{
      this.preload();
    });
  }

  preload() {
    return new Promise((resolve, reject)=>{
      this.webservice.get("popups20/v2/" + environment.language + "/" + environment.popupConfig, { bucket: true, local: false }, response => {
        this.eConfig = response;
        resolve({ok:true});
      }, err =>{
        reject(err);
      });
      // this.webservice.get("course/" + environment.popupConfig, { bucket: false, local: true }, response => {
      //   this.eConfig = response;
      //   resolve();
      // }, err =>{
      //   reject(err);
      // });
    });
    
  }

  onMessageReceived() {
    this.firebase.onMessageReceived().subscribe(data => {
      if (data.url) {
        this.notificationDeeplink = data.url;
      }
      console.log(`User opened a notification`, data);
    });
  }

  getToken() {
    let promise = new Promise((resolve, reject) => {
      if (!this.platform.is("cordova")) {
        (async () => {
          this.initFirebaseWebMessaging().then(() => {}).catch(() => {});
          let token = await this.requestPermission();
          if (token) {
            resolve(token)
          } else {
            let token: any = sessionStorage.getItem('fbt');
            if (!token) {
              token = new Date().getTime();
              sessionStorage.setItem('fbt', token);
            }
            resolve(token);
          }
        })();
      } else {
        if (this.device.platform.toLowerCase() == "ios") {
          this.firebase.hasPermission().then((data: any) => {
            if (!data) {
              if(localStorage.getItem('iosFBTPermissionDenied') && localStorage.getItem('iosFBTPermissionDenied') == 'denied') {
                let token:any = new Date().getTime();
                sessionStorage.setItem('fbt', token);
                resolve(token);
              } else {
                this.firebase.grantPermission().then((responce)=>{
                  if(!responce) {
                    localStorage.setItem('iosFBTPermissionDenied', 'denied');
                    let token:any = new Date().getTime();
                    sessionStorage.setItem('fbt', token);
                    resolve(token);
                  } else {
                    this.firebase.getToken().then(token => {
                      resolve(token);
                    }).catch(error => {
                      console.log('token error ', error);
                      reject(false);
                    });
                  }
                });
              }
            } else {
              this.firebase.getToken().then(token => {
                resolve(token);
              }).catch(error => {
                console.log('token error ', error);
                reject(false);
              });
            }
          });
        } else {
          this.firebase.getToken().then(token => {
            resolve(token);
          }).catch(error => {
            reject(false);
          });
        }
      }
    });
    return promise;
  }


  logEvents(eventName: string, param?: any) {
    // if(eventName == "guided_tour_viewed") {
    console.log("-firebase-SET-EVENT ::::: ", eventName, param);
    // }

    if (!param) param = {};
    if (this.platform.is("cordova")) {
      this.firebase.logEvent(eventName, param);
    } else {
      this.analyticsWeb.logEvent(eventName, param);
    }

    this.emoEventTracker(eventName, param);
  }

  setScreen(screen: any) {
    // if (screen.url) console.log("-firebase-SET-SCREEN ::::: ", this.getScreenName(screen.url));
    // if (screen.name) console.log("-firebase-SET-SCREEN ::::: ", screen.name);

    if (this.platform.is("cordova")) {
      if (screen.url) {
        this.firebase.setScreenName(this.getScreenName(screen.url));
      } else if (screen.name) {
        this.firebase.setScreenName(screen.name);
      }
    } else {
      if (screen.url) {
        console.log("this.getScreenName(screen.url) >>>>>>>>>>>>>>>>>>>>>>> ", this.getScreenName(screen.url));
        this.analyticsWeb.setCurrentScreen(this.getScreenName(screen.url));
      } else if (screen.name) {
        console.log("screen.name>>>>>>>>>>>>>>>>>> ", screen.name);
        this.analyticsWeb.setCurrentScreen(screen.name);
      }
    }
  }

  getScreenName(url: string) {
    let screen: string = null;

    if (url.includes("launch")) {
      screen = "SCREEN_onboarding_video";
    }

    else if (url.includes("authentication")) {
      let tmp = url.split(";");
      tmp = tmp[0].split("/");
      screen = "SCREEN_onboarding_" + tmp[tmp.length - 1];
    }

    else if (url.includes("tab1")) {
      if (url.includes(";") && url.includes("lid")) {
        screen = "SCREEN_lesson_menu";
      } else {
        screen = "SCREEN_main_menu";
      }
    }

    else if (url.includes("tab2")) {
      if (url.includes("page2")) {
        screen = "SCREEN_review_by_lesson";
      } else {
        screen = "SCREEN_review";
      }
    }

    else if (url.includes("tab3")) {
      if (url.includes("terms")) {
        let tmp = url.split("/");
        screen = "SCREEN_plans_" + tmp[tmp.length - 1];
      } else if(url.includes('/payment')) {
        screen = "SCREEN_plans_web_payment";
      } else {
        screen = "SCREEN_plans";
      }
    }

    else if (url.includes("tab4")) {
      if (url.includes("tab4/")) {
        let tmp = url.split("/");
        screen = "SCREEN_account_" + tmp[tmp.length - 1];
      } else {
        screen = "SCREEN_account";
      }
    }

    else if (url.includes("vocab")) {
      screen = "SCREEN_vocab_menu";
    }

    else if (url.includes("exercise")) {
      if (url.includes("type=lesson")) {
        screen = "SCREEN_exercise";
      } else if (url.includes("type=test")) {
        screen = "SCREEN_test";
      } else {
        screen = "SCREEN_revision_exer";
      }
    }

    else if (url.includes("video")) {
      screen = "SCREEN_video";
    }

    else if (url.includes("result")) {
      screen = "SCREEN_result";
    }

    else if (url.includes("tutorial-slides")) {
      screen = "SCREEN_guided_tour_recap";
    }

    else if (url.includes("verify-account")) {
      screen = "SCREEN_verify_account";
    }

    if (!screen) {
      console.warn("screen name not set");
      return "not set";
    } else {
      screen = screen.replace(/-/gi, "_");
      return screen;
    }


  }


  initFirebaseWebMessaging() {
    return new Promise((resolve, reject) => {
      navigator.serviceWorker.ready.then((registration) => {
        if (!firebase_web.default.messaging.isSupported()) {
          resolve();
          return;
        }
        try {
          const messaging = firebase_web.default.messaging();
          messaging.useServiceWorker(registration);
          messaging.usePublicVapidKey(environment.vapidKey);
          messaging.setBackgroundMessageHandler((payload) => {
            // console.log("notification payload >> ", payload);
            window.location.href = "https://pwa.englishmobile.com";
            alert("msg in bg??")
          });
          messaging.onMessage((payload) => {
            // console.log("notification payload >> ", payload);
            alert("msg??")
          });
          messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken: string) => {
              // console.log(refreshedToken);
            }).catch((err) => {
              console.error(err);
            });
          });
          resolve();
        } catch (e) {
          console.log(e);
          reject(e);
        }



      }, (err) => {
        reject(err);
      });
    });
  }

  requestPermission() {

    return new Promise(async (resolve) => {
      try {
        if (!Notification) {
          resolve(false);
          return;
        }
      } catch (e) {
        resolve(false);
        return;
      }

      if (!firebase_web.default.messaging.isSupported()) {
        resolve(false);
        return;
      }
      try {
        const messaging = firebase_web.default.messaging();
        await messaging.requestPermission();
        const token: string = await messaging.getToken();
        resolve(token);
      } catch (err) {
        // No notifications granted
        resolve(false);
      }


    });
  }

  private emo_free_trial_popup = new Subject<any>();
  onEmoFreeTrialPopup = this.emo_free_trial_popup.asObservable();

  private emo_in_app_popup = new Subject<any>();
  onEmoInAppPopup = this.emo_in_app_popup.asObservable();
  popupTimeout: any = null;
  private emoEventTracker(eventName: string, param?: any) {
    this.eConfig.forEach((emoEvt) => {
      if (emoEvt.version >= 2.0) {
        let options = {
          'event': eventName,
          'checkpoint': emoEvt,
          'user': {
            'param': param
          }
        }
        this.emoEventEvaluator2(options);
      } else {
        if (emoEvt.event.indexOf(eventName) > -1) {
          emoEvt.messages.forEach((evtMsg) => {
            if (evtMsg.node.indexOf(param.node) > -1) {
              evtMsg.score.forEach((evtScr) => {
                if (eventName == "EVENT_can_show_trial") {
                  let popup_data = {
                    event: "EVENT_can_show_trial",
                    id: evtMsg.id,
                    node: param.node,
                    content: evtScr,
                    target: evtScr.user_type,
                    frequency: evtScr.max_freq,
                    recorder: evtScr.emouser_popup_recorder
                  }
                  this.emo_in_app_popup.next(popup_data);
                } else if (eventName == "EVENT_level_view") {
                  let index = evtMsg.node.indexOf(param.node);
                  if (param.totalTestPassed >= evtScr['test_passed'][index].min && param.totalTestPassed <= evtScr['test_passed'][index].max) {
                    let popup_data = {
                      id: evtMsg.id,
                      node: param.node,
                      score: param.totalTestPassed,
                      content: evtScr,
                      target: evtScr.user_type,
                      frequency: evtScr.max_freq,
                      recorder: evtScr.emouser_popup_recorder
                    }
                    clearTimeout(this.popupTimeout);
                    this.popupTimeout = setTimeout((popup_data) => {
                      this.emo_in_app_popup.next(popup_data);
                    }, 3000, popup_data)
                  }
                } else {
                  if (param.score >= evtScr.min && param.score <= evtScr.max) {
                    let popup_data = {
                      id: evtMsg.id,
                      node: param.node,
                      score: param.score,
                      content: evtScr,
                      target: evtScr.user_type,
                      frequency: evtScr.max_freq,
                      recorder: evtScr.emouser_popup_recorder
                    }
                    clearTimeout(this.popupTimeout);
                    this.popupTimeout = setTimeout((popup_data) => {
                      this.emo_in_app_popup.next(popup_data);
                    }, 3000, popup_data)
                  }
                }
              });
            }
          });
        }
      }
    });
  }


  emoEventEvaluator2(options: any) {
    if (options.checkpoint.event.indexOf(options.event) > -1) {
      options.checkpoint.messages.forEach((msg) => {
        this.userFlags.setProperty('TRIAL_LEFT_IN_SEC', this.sharedDataService.appData.trialLeftInSec);
        this.userFlags.setProperty('PROGRESS', this.sharedDataService.appData.progress);
        let ex = this.userFlags.evalExp(msg.expression.trim());
        if (ex) {
          let op = {'promptId': '1', 'survey': (msg.survey ? msg.survey : null)};
          this.appRate.showRatingPrompt(op);
        }
      });
    }

  }

  onInappPopupClose(popup_data) {
    if (!popup_data.recorder) return;
    let campaign_data = {
      token: popup_data.jwt,
      data: {
        id: popup_data.id ? popup_data.id.toString() : "",
        node: popup_data.node,
        action: popup_data.action
      }
    }
    this.webservice.post(popup_data.recorder, campaign_data, response => {
      // console.log("campaign record responce >>> ", response);
    });
  }
}

