import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebserviceService } from '../../utils/webservice.service';
import { UserApiService } from '../../utils/user-api.service';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { EventManagerService } from '../../utils/common/event-manager.service';

@Component({
  selector: 'app-cancel-receive-mail-popup',
  templateUrl: './cancel-receive-mail-popup.page.html',
  styleUrls: ['./cancel-receive-mail-popup.page.scss'],
})
export class CancelReceiveMailPopupPage implements OnInit {
  @Input() options: any;
  
  popupStatusCode:number = 0;
  showLoader: boolean = false;
  bodyText: any = {};
  private eventListeners: any = {};
  constructor(
    private webservice: WebserviceService,
    private modalCtrl: ModalController,
    private firebaseEvents:FirebaseEventsService,
    private userApiService: UserApiService,
    private interfaceText: InterfaceTextService,
    private eventManager: EventManagerService,
  ) { }

  ngOnInit() {
    console.log(this.options);
    let screenObj = {
      name: "SCREEN_cancel_newsletter"
    }
    this.firebaseEvents.setScreen(screenObj);
    if(this.eventListeners.onLanguageReady) this.eventListeners.onLanguageReady.unsubscribe();
    this.eventListeners.onLanguageReady = this.eventManager.bind('language.ready').subscribe(async (fl: any) => {
      this.renderText();
    });
  }
  ionViewWillEnter() {
    this.renderText();
  }

  renderText() {
    let screen_name = "cancel_newsletter";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }
  cancelEmailNotification(e:Event){
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.showLoader = true;
    this.webservice.get("cancel-email?code="+this.options.code, false, response => {
      if(response.ok) {
        this.showLoader = false;
        this.popupStatusCode = 1;
        let screenObj = {
          name: "SCREEN_cancel_newsletter_success"
        }
        this.firebaseEvents.setScreen(screenObj);

        this.userApiService.getUser().then((user: any) => {
          let options = {
            jwt: user.jwt
          }
          this.userApiService.setUser(options);
        }).catch(()=>{});
        
      } else {
        this.popupStatusCode = 2;
        let screenObj = {
          name: "SCREEN_cancel_newsletter_error"
        }
        this.firebaseEvents.setScreen(screenObj);
      }
    }, (error:any) => {
      this.popupStatusCode = 2;
      let screenObj = {
        name: "SCREEN_cancel_newsletter_error"
      }
      this.firebaseEvents.setScreen(screenObj);
    });
  }

  closePopup(e:Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.modalCtrl.dismiss();
  }
}
