import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { WebserviceService } from '../../utils/webservice.service';
import { UserApiService } from '../../utils/user-api.service';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { DomSanitizer } from '@angular/platform-browser';

import { SharedDataService } from '../../utils/common/shared-data.service';
import { InterfaceTextService } from '../../utils/interface-text.service';

import { BuyPage } from '../../popups/buy/buy.page';

declare let $: any;

@Component({
  selector: 'app-trial-popup',
  templateUrl: './trial-popup.page.html',
  styleUrls: ['./trial-popup.page.scss'],
})
export class TrialPopupPage implements OnInit {
  @Input() options: any;
  showLoader:boolean = false;
  tabIndex:any;

  constructor(
    private webservice: WebserviceService,
    private modalCtrl: ModalController,
    private firebaseEvents:FirebaseEventsService,
    private userApiService: UserApiService,
    private navCtrl : NavController,
    private sanitizer: DomSanitizer,
    private sharedService: SharedDataService,
    private interfaceText: InterfaceTextService
  ) { }
  user:any = {};
  bodyText: any = {};
  template: any = "<p>Cargando...</p>";
  ngOnInit() {

    console.log(this.options);


    // if(this.options.content && this.options.content.contentHTML) {
    //   this.template = this.sanitizer.bypassSecurityTrustHtml(this.options.content.contentHTML);
    // }

    if(this.options.trialStart) {
      this.firebaseEvents.logEvents("free_trial_start");
      // let screenObj = {
      //   name: "POPUP_trial_start"
      // }
      // this.firebaseEvents.setScreen(screenObj);
    } else {
      let screenObj = {
        name: "POPUP_trial_end"
      }
      this.firebaseEvents.setScreen(screenObj);

      // this.userApiService.getUser().then((user:any)=>{
      //   this.user = user;
      //   let providerCode = this.user.provider == "email" ? 0 : this.user.provider == "google" ? 1 : this.user.provider == "facebook" ? 2: 3;
      //   this.webservice.get("getjwt?email=" + this.user.email + "&password=" + this.user.password + "&accesstoken="+this.user.accesstoken + "&fbt="+this.user.fbt+"&provider="+providerCode, {}, response => {
      //     if (response.jwt) {
      //       this.onSuccess(response);
      //     } else { }
      //   }, error => {
      //     this.onError(error);
      //   });
      // }).catch((error)=>{
      //   this.onError(error);
      // });
    }
    

  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    let Up_Arrow_Key = event.key === "ArrowUp";
    let Down_Arrow_Key = event.key === "ArrowDown";

    if (window.innerWidth > 991) {
      switch (this.tabIndex) {
        case 82: {
          this.setFocusTo("closeModalButton");
          // if (Down_Arrow_Key) {
          //     this.setFocusTo("acceptButton");
          // }
          break;
        }
        case 83: {
          this.setFocusTo("acceptButton");
          if (Up_Arrow_Key) {
            this.setFocusTo("closeModalButton");
          }
          break;
        }
      }
    }
  }

  
  @HostListener('window:click', ['$event'])
  clickout(event) {
    if (window.innerWidth > 991) {
      switch (this.tabIndex) {
        case 82: {
          this.setFocusTo("closeModalButton");
          break;
        }
        case 83: {
          this.setFocusTo("acceptButton");
          break;
        }
      }
    }
  }

  ionViewWillEnter() {
    setTimeout(() => {
      if (window.innerWidth > 991) {
        this.setFocusTo("closeModalButton");
      }
    }, 400)
    this.renderText();
  }

  renderText() {
    let screen_name = "trial_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }


  accepted() {
    this.showLoader = true;
    
    this.userApiService.getUser().then((user:any)=>{

      if(user && user.jwt) {
        let reqOption = {
          token: user.jwt,
          data: {}
        }
        this.webservice.post("trialaccepted", reqOption, async (response) => {
          if(response.ok) {

            let opt = {
              jwt: response.token
            }
            this.userApiService.setUser(opt).then((re)=>{
              this.showLoader = false;
              this.modalCtrl.dismiss({action:true, jwt: this.userApiService.user.jwt});
            }).catch((err)=>{
              console.log(response);
              this.showLoader = false;
            });
            
          } else {
            this.showLoader = false;
          }
        }, error => {
          console.log(error);
          this.showLoader = false;
        });
      }

    });

    this.firebaseEvents.logEvents("trial_popup_accept");
  }

  setFocusTo(htmlElement){

    //focus html elements
    let closeModalButton = $('.tabIndex82'); //82
    let acceptButton = $('.tabIndex83'); //83

    switch(htmlElement) {
        case "closeModalButton": {
          closeModalButton.focus();
          this.tabIndex = 82;
           break;
        }
        case "acceptButton": {
          acceptButton.focus();
          this.tabIndex = 83;
           break;
        } 
    } 
  }

  popupCancelled() {
    if(this.options.onCancelClick) {
      this.options.onCancelClick();
    } else {
      this.modalCtrl.dismiss({});
    }
    this.firebaseEvents.logEvents("trial_popup_dismiss");
  }

  goToSubscribe() {
    this.modalCtrl.dismiss({}).then(()=>{
      // this.navCtrl.navigateForward(['tabs/tab3']);
      this.showBuyPopup();
    });
  }

  buyPagePopup:any = null;
  async showBuyPopup() {
    this.buyPagePopup = await this.modalCtrl.create({
      component: BuyPage,
      cssClass: 'buy-popup-css',
      componentProps: {
        options: {}
      }
    });
    this.buyPagePopup.onDidDismiss().then(async (res: any) => {
      if(res.data && res.data.navigation) {
        this.sharedService.navFromSubscribeModal();
      }
    });
    await this.buyPagePopup.present();
  }

  async onSuccess(response:any) {
    let successRes:any = {};
    successRes.jwt = response.jwt;
    successRes.email = this.user.email;
    this.userApiService.onLoginSuccess(successRes).then(()=>{
      let data = {
        jwt : successRes.jwt
      }
      this.webservice.processPendingRequest(data);
    });
  }

  async onError(error:any) {
    console.log(error);
  }
}
