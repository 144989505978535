import { Component, OnInit, Input, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DevDataService } from '../../utils/dev-data.service';
import { UserApiService } from '../../utils/user-api.service';
import { GlServiceService } from './services/gl-service.service';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { TutorialService } from '../../utils/tutorial.service';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { WebElementfocusService } from '../../utils/common/web-elementfocus.service';
import { FacebookEventsHandlerService } from '../../utils/analytics/facebook-events-handler.service';
declare let $: any;

@Component({
  selector: 'app-grammar',
  templateUrl: './grammar.page.html',
  styleUrls: ['./grammar.page.scss'],
})
export class GrammarPage implements OnInit, OnDestroy {
  @Input() options: any;

  lessonTitle:string;
  glCount:number;
  currentGlIndex:number = 0;
  glConfig:Array<any> = [];
  buttonStatus:any = {
    left : {active : false},
    right : {active : false}
  }
  slideChangeListener: Subscription;
  slideOpts: any = {
    initialSlide: 0
  };
  UICreated: boolean = false;
  tutorialListener:Subscription;
  glProgressTimeout: any = null;
  bodyText:any = {};

  constructor(
    private modalCtrl: ModalController,
    private appDataService: DevDataService,
    private userApiService: UserApiService,
    private firebaseEvents: FirebaseEventsService,
    private glServiceService: GlServiceService,
    private tutorial: TutorialService,
    private interfaceText: InterfaceTextService,
    public webElementfocusService: WebElementfocusService,
    private facebookEventsHandler:FacebookEventsHandlerService
  ) { }
  
  gLessonSlides: any;
  @ViewChild('g_slides', {static: false}) set slides(slides: any) {
    this.gLessonSlides = slides;
    if (this.slideChangeListener) this.slideChangeListener.unsubscribe();
    this.slideChangeListener = this.gLessonSlides.ionSlideDidChange.subscribe((e: Event) => {
      this.gLessonSlides.getActiveIndex().then((index:number) => {
        if(this.UICreated) this.onSliderIndexReceive(index);
      }).catch((err:any) => {
        console.log("err > ", err);
      });
    });
    this.gLessonSlides.getActiveIndex().then((index:number) => {
      if(this.UICreated) this.onSliderIndexReceive(index);
    }).catch((err:any) => {
      console.log("err > ", err);
    });
  }

  ngOnInit() {
    this.createUI();
   
    let screenObj = {
      name: "SCREEN_grammar_lesson"
    }
    this.firebaseEvents.setScreen(screenObj);

    if (this.tutorialListener) this.tutorialListener.unsubscribe();
    this.tutorialListener = this.tutorial.onTriggerTutorial.subscribe((re: any) => {
      // this.showTutorial(re);
      // if (this.webElementfocusService.isPWA && re.canDisplay) {
      //   setTimeout(() => {
      //     this.webElementfocusService.setFocusTo("closeTutorial");
      //   }, 100)
      // }
    });
  }

  ionViewWillEnter() {
    if (this.webElementfocusService.isPWA) {
      setTimeout(() => {
        this.webElementfocusService.setFocusTo("grammarCloseX");
     }, 500)
    }
    this.renderText();
  }

  renderText() {
    let screen_name = "grammar_lesson";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.webElementfocusService.isPWA) {
      switch (this.webElementfocusService.tabIndex) {
        case "grammarCloseX": {
          if (event.key==="ArrowLeft") {
            this.showPrevSlide();
          }
          if (event.key==="ArrowRight") {
            this.showNextSlide()
          }
          break;
        }
      }
    }
  }

  @HostListener('window:click', ['$event'])
  clickout(event) {
    if (this.webElementfocusService.isPWA) {
      this.webElementfocusService.setFocusTo(this.webElementfocusService.tabIndex);
    }
  }

  ngOnDestroy() {
    if (this.slideChangeListener) this.slideChangeListener.unsubscribe();
  }

  private createUI() {
    this.glCount = this.options.glIds.length;
    for(let i = 0; i < this.options.glIds.length; i++) {
      let gl:any = {};
      gl.data = this.appDataService.getNode(this.options.glIds[i]);
      gl.index = i;
      this.glConfig.push(gl);
    }
    this.slideOpts.initialSlide = this.options.initialIndex || 0;
    this.onSliderIndexReceive(this.slideOpts.initialSlide);
    this.UICreated = true;
  }
  
  private onSliderIndexReceive(index:any) {
    this.currentGlIndex = index;
    this.lessonTitle = this.glConfig[this.currentGlIndex].data.content.title;
    
    if(this.currentGlIndex <= 0) {
      this.buttonStatus.left.active = false;
    } else {
      this.buttonStatus.left.active = true;
    }
    if((this.currentGlIndex+1) >= this.options.glIds.length) {
      this.buttonStatus.right.active = false;
    } else {
      this.buttonStatus.right.active = true;
    }

    if(this.glProgressTimeout) clearTimeout(this.glProgressTimeout);
    this.glProgressTimeout = setTimeout(()=>{
      this.facebookEventsHandler.logEvent({event: 'fb_emo_viewed_content', params: {content_id: this.glConfig[this.currentGlIndex].data.id, content_type: 'grammar_lesson'}});
      this.appDataService.passGrammar(this.glConfig[this.currentGlIndex].data.id);
      this.userApiService.saveProgress([this.glConfig[this.currentGlIndex].data.id]).then(()=>{
        this.userApiService.saveInServer().then(()=>{}).catch(()=>{});
      }).catch(()=>{});
    }, 2000);
    this.glServiceService.scrollupSlide(this.glConfig[this.currentGlIndex].data.id);
    
  }

  showPrevSlide(){
    this.gLessonSlides.slidePrev();
  }

  showNextSlide(){
    this.gLessonSlides.slideNext();
  }

  closeGrammarLesson(e:Event){
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.firebaseEvents.setScreen({name: "SCREEN_"+this.options.origin});
    this.modalCtrl.dismiss();
  }


  tutorialDisplayOption:any = {};
  currentTutorialName:string = "";
  showTutorial(options) {
    $(document).ready(()=>{
      this.tutorialDisplayOption = options;
      if(options.tutorialName == 'tutorial-13') {
        this.tutorialDisplayOption.style = {'top': '50%', 'transform': 'translateY(-50%)'};
      }

      if(this.tutorialDisplayOption.canDisplay) {
        this.currentTutorialName = this.tutorialDisplayOption.tutorialName;
      }
    });

  }

  closeTutorial(e?: Event) {
    if(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
    if (this.webElementfocusService.isPWA) {
      this.webElementfocusService.setFocusTo("grammarCloseX");
    }
    this.currentTutorialName = "";
    this.tutorialDisplayOption.canDisplay = false;
    this.tutorial.triggerTutorial({
      canDisplay: false
    });
  }
}
