
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './utils/route-guards/auth-guard.service';


const routes: Routes = [
  { path: '', redirectTo: 'launch', pathMatch: 'full' },
  { path: 'launch', loadChildren: './pages/launch/launch.module#LaunchPageModule' },
  { path: 'authentication', loadChildren: './pages/authentication/authentication.module#AuthenticationPageModule' },
  { path: 'change-method', loadChildren: './pages/authentication/components/change-method/change-method.module#ChangeMethodPageModule' },
  { path: 'authentication/enterwithmail', loadChildren: './pages/authentication/components/auth-email/auth-email.module#AuthEmailPageModule' },
  { path: 'authentication/email-login', loadChildren: './pages/authentication/components/auth-password/auth-password.module#AuthPasswordPageModule' },
  { path: 'authentication/recovery-password', loadChildren: './pages/authentication/components/recovery-password/recovery-password.module#RecoveryPasswordPageModule' },
  { path: 'authentication/email-register', loadChildren: './pages/authentication/components/auth-register/auth-register.module#AuthRegisterPageModule' },
  { path: 'authentication/email-register-password', loadChildren: './pages/authentication/components/auth-register-password/auth-register-password.module#AuthRegisterPasswordPageModule' },
  { path: 'auth-methods-popup', loadChildren: './pages/authentication/components/auth-methods-popup/auth-methods-popup.module#AuthMethodsPopupPageModule' },

  { path: 'verify-account', canActivate: [AuthGuardService], loadChildren: './pages/verify-account/verify-account.module#VerifyAccountPageModule' },

  { path: 'user-account', canActivate: [AuthGuardService], loadChildren: './pages/user-account/user-account.module#UserAccountPageModule' },
  { path: 'exercise/:skill', canActivate: [AuthGuardService], loadChildren: './pages/exercise/exercise.module#ExercisePageModule' },
  { path: 'subscription', canActivate: [AuthGuardService], loadChildren: './pages/subscription/subscription.module#SubscriptionPageModule' },
  { path: 'tabs', canActivate: [AuthGuardService], loadChildren: './pages/tabs/tabs.module#TabsPageModule' },
  { path: 'lesson-menu/:id', canActivate: [AuthGuardService], loadChildren: './pages/lesson-menu/lesson-menu.module#LessonMenuPageModule'},
  { path: 'repaso', canActivate: [AuthGuardService], loadChildren: './pages/repaso/repaso.module#RepasoPageModule' },
  { path: 'video/:id', canActivate: [AuthGuardService], loadChildren: './pages/video/video.module#VideoPageModule' },
  { path: 'result/:id', canActivate: [AuthGuardService], loadChildren: './pages/result/result.module#ResultPageModule' },
  { path: 'act-exit-popup', loadChildren: './pages/exercise/components/act-exit-popup/act-exit-popup.module#ActExitPopupPageModule' },
  { path: 'privacy-policy', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'faq', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/faq/faq.module#FaqPageModule' },
  { path: 'languages', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/languages/languages.module#LanguagesPageModule' },
  { path: 'profile', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/profile/profile.module#ProfilePageModule' },
  { path: 'certificates', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/certificates/certificates.module#CertificatesPageModule' },
  { path: 'change-pass', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/change-pass/change-pass.module#ChangePassPageModule' },
  { path: 'payment-success', canActivate: [AuthGuardService], loadChildren: './pages/subscription/components/payment-success/payment-success.module#PaymentSuccessPageModule' },
  { path: 'tutorial-slides', canActivate: [AuthGuardService], loadChildren: './pages/user-account/components/tutorial-slides/tutorial-slides.module#TutorialSlidesPageModule' },
  
  { path: 'popup-data-update', loadChildren: './pages/user-account/components/popup-data-update/popup-data-update.module#PopupDataUpdatePageModule' },
  { path: 'popup-logout', loadChildren: './pages/user-account/components/popup-logout/popup-logout.module#PopupLogoutPageModule' },
  { path: 'popup-choose-image', loadChildren: './pages/user-account/components/popup-choose-image/popup-choose-image.module#PopupChooseImagePageModule' },
  { path: 'login-alert', loadChildren: './popups/login-alert/login-alert.module#LoginAlertPageModule' },
  { path: 'verify-alert', loadChildren: './popups/verify-alert/verify-alert.module#VerifyAlertPageModule' },
  { path: 'multi-device-alert', loadChildren: './popups/multi-device-alert/multi-device-alert.module#MultiDeviceAlertPageModule' },
  { path: 'legal-docs', loadChildren: './popups/legal-docs/legal-docs.module#LegalDocsPageModule' },
  { path: 'reset-password-form', loadChildren: './popups/reset-password-form/reset-password-form.module#ResetPasswordFormPageModule' },
  { path: 'in-app-popup', loadChildren: './popups/in-app-popup/in-app-popup.module#InAppPopupPageModule' },
  { path: 'trial-popup', loadChildren: './popups/trial-popup/trial-popup.module#TrialPopupPageModule' },
  { path: 'social-sharing', loadChildren: './popups/social-sharing/social-sharing.module#SocialSharingPageModule' },
  { path: 'enable-tutorial', loadChildren: './popups/enable-tutorial/enable-tutorial.module#EnableTutorialPageModule' },
  { path: 'settings', loadChildren: './pages/exercise/components/settings/settings.module#SettingsPageModule' },
  { path: 'buy', loadChildren: './popups/buy/buy.module#BuyPageModule' },
  { path: 'popups', loadChildren: './popups/buy/popups/popups.module#PopupsPageModule' },
  { path: 'app-rate-popup', loadChildren: './popups/app-rate-popup/app-rate-popup.module#AppRatePopupPageModule' },
  { path: 'plan-list', loadChildren: './popups/plan-list/plan-list.module#PlanListPageModule' },
  {
    path: 'tutor-selection',
    loadChildren: () => import('./popups/tutor-selection/tutor-selection.module').then( m => m.TutorSelectionPageModule)
  },
  {
    path: 'app-update-popup',
    loadChildren: () => import('./popups/app-update-popup/app-update-popup.module').then( m => m.AppUpdatePopupPageModule)
  },
  {
    path: 'app-debug-mode',
    loadChildren: () => import('./popups/app-debug-mode/app-debug-mode.module').then( m => m.AppDebugModePageModule)
  },
  {
    path: 'delete-account-confirm',
    loadChildren: () => import('./popups/delete-account-confirm/delete-account-confirm.module').then( m => m.DeleteAccountConfirmPageModule)
  },
  {
    path: 'redeem',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/subscription/components/redeem/redeem.module').then( m => m.RedeemPageModule)
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
