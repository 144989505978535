import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InterfaceTextService } from '../../utils/interface-text.service';

@Component({
  selector: 'app-offline-alert',
  templateUrl: './offline-alert.page.html',
  styleUrls: ['./offline-alert.page.scss'],
})
export class OfflineAlertPage implements OnInit {
  @Input() options: any;

  constructor(
    private modalCtrl: ModalController,
    private interfaceText: InterfaceTextService
  ) { }

  commonBodyText: any = {};
  ngOnInit() {
    this.commonBodyText = this.interfaceText.getBodyText('common');
  }

  closePopup(e:Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.modalCtrl.dismiss().then(()=>{});
  }


}
