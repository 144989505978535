import { Component, OnInit, NgZone, Input } from '@angular/core';
import { WebserviceService } from '../../../utils/webservice.service';
import { ContentDownloadService } from '../../../utils/content-download.service';
import { Subscription } from 'rxjs';
import { GlServiceService } from '../services/gl-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { InterfaceTextService } from '../../../utils/interface-text.service';
declare let $: any;

@Component({
  selector: 'app-gl-card',
  templateUrl: './gl-card.component.html',
  styleUrls: ['./gl-card.component.scss'],
})
export class GlCardComponent implements OnInit {
  @Input() options: any;
  grammarLessonBody:any = "Cargando...";
  slideVisible:Subscription;
  constructor(
    private webservice: WebserviceService,
    private downloadService: ContentDownloadService,
    private glServiceService: GlServiceService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private interfaceText: InterfaceTextService
  ) { }

  ngOnInit() {
    let commonInterfaceText = this.interfaceText.getBodyText("common");
    this.grammarLessonBody = commonInterfaceText["loading"];
    this.setGLData();
    if(this.slideVisible) this.slideVisible.unsubscribe();
    this.slideVisible = this.glServiceService.onSlideVisible.subscribe((id)=>{
      if(id == this.options.data.id) {
        $(".gl_"+this.options.data.id).animate({
          scrollTop: 0
        }, 100);
      }
    }); 

    this.ngZone.run( () => {
      $(window).on('keydown', (e:any)=>{
        let code = parseInt(e.keyCode);
        if(code == 38) { 
            let s_top = $(".gl_"+this.options.data.id).scrollTop();
            $(".gl_"+this.options.data.id).animate({
              scrollTop: (s_top-120)
            }, 50);
          } else if(code == 40) { 
            let s_top = $(".gl_"+this.options.data.id).scrollTop();
            $(".gl_"+this.options.data.id).animate({
              scrollTop: (s_top+120)
            }, 50);
          }
      });
    });
    
  }

  setGLData(fallback?:string) {
    


    this.downloadService.getContent(this.options.data.id, this.options.data.content.file+".html").then((data)=>{
      let reader:any = new FileReader();
      reader.onload = ()=>{
        if(reader.result) {
          this.grammarLessonBody = reader.result;
        }
      }
      reader.readAsText(data);
    }).catch((err)=>{
      let getGLOption: object = {
        token: false,
        data: false,
        bucket: true,
        responseType : "text"
      }
      let contentDir = !fallback ? environment.production ? "content.prod" : "content.internal" : "content";
      this.webservice.get(
        environment.glDir + environment.language + "/" + contentDir + "/" + this.options.data.content.file + ".html?v="+ new Date().getTime(),
        getGLOption,
        (data:any) => {
          this.grammarLessonBody = this.sanitizer.bypassSecurityTrustHtml(data);
        },
        (error:any) => {
          console.log(error);
          if(contentDir != "content") {
            this.setGLData(contentDir);
          } else {
            let d = "<h4 style='text-align:center'>This content is not available in your language yet!</h4>";
            this.grammarLessonBody = this.sanitizer.bypassSecurityTrustHtml(d);
          }
        }
      );
    });
    
  }

}
