import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { AppStorageService } from './app-storage.service';
import { environment } from '../../environments/environment';
import { WebserviceService } from './webservice.service';
import { FirebaseEventsService } from './firebase-events.service';
import { EventManagerService } from './common/event-manager.service';
import { ContentDownloadService } from './content-download.service';
import { SharedDataService } from './common/shared-data.service';
import { WebElementfocusService } from './common/web-elementfocus.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  messageConfig: any = {};
  version: any = "v1";
  private eventListeners: any = {};
  constructor(
    private domSanitizer: DomSanitizer,
    private storage: AppStorageService,
    private webservice: WebserviceService,
    private firebase: FirebaseEventsService,
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    private downloadService: ContentDownloadService,
    private webElementfocusService: WebElementfocusService
  ) {
    if(this.eventListeners.onLanguageChange) this.eventListeners.onLanguageChange.unsubscribe();
    this.eventListeners.onLanguageChange = this.eventManager.bind('language.updated').subscribe(async (fl: any) => {
      this.preload();
    });
  }

  preload() {
    return new Promise((resolve, reject) => {
      this.webservice.get(environment.guidedTourConfig +'.'+environment.language +'.json', { bucket: true, local: false }, response => {
        this.messageConfig = response;
        this.cacheSlideImages();
        resolve(null);
        this.eventManager.trigger('guidedtour.ready');
      }, err => {
        reject(err);
      });

      // this.webservice.get("course/" + environment.guidedTourConfig, { bucket: false, local: true }, response => {
      //   this.messageConfig = response;
      //   resolve();
      // }, err => {
      //   reject(err);
      // });
    });
  }


  cacheSlideImages() {
    this.messageConfig[this.version].slides.forEach(element => {
      let imageName:any = element.split("/");
      imageName = "guided_tour_"+imageName[(imageName.length-1)];
      fetch(element).then(response=>{
        return response.blob();
      }).then((blob)=>{
        this.downloadService.saveFile(blob, imageName);
      });
    });
  }


  getTutorialSlides() {
    return new Promise((resolve, reject) => {
      if (this.messageConfig[this.version]) {
        if (!this.messageConfig[this.version].active) {
          resolve([]);
        } else if (this.messageConfig[this.version].slides) {
          resolve(this.messageConfig[this.version].slides);
        }
      } else {
        resolve([]);
      }
    });
  }

  getTutorialVideo() {
    return false;
    // if (!this.messageConfig[this.version].active) {
    //   return false;
    // } else {
    //   // return (this.messageConfig[this.version].video || "424183229");
    //   return this.messageConfig[this.version].video;
    // }
  }

  private on_trigger_tutorial_popup = new Subject<any>();
  onTriggerTutorialPopup = this.on_trigger_tutorial_popup.asObservable();
  async triggerTutorialPopup() {
    if(this.sharedDataService.appData.appDebug && this.sharedDataService.appData.appDebug.disableTutorial) return;
    if (!this.messageConfig[this.version].active) return;
    let tutorial: any = await this.storage.getWithPromise("tutorial").catch((e: any) => { console.log("tutorial not found"); });
    if (!tutorial) tutorial = {};
    if (!tutorial['main-menu'] || (tutorial['main-menu'] && !tutorial['main-menu']['tutorial-1'])) {
      let texts = this.messageConfig[this.version].texts['confirmPopup'];
      this.on_trigger_tutorial_popup.next(texts);
    }
  }

  private on_trigger_tutorial = new Subject<any>();
  onTriggerTutorial = this.on_trigger_tutorial.asObservable();


  sleep(ms) {
    return new Promise((resolve, reject) => { 
      if(this.messageConfig[this.version]) {
        setTimeout(() => { resolve(null); }, ms); 
      } else {
        if(this.eventListeners.onGuidedTourReady) this.eventListeners.onGuidedTourReady.unsubscribe();
        this.eventListeners.onGuidedTourReady = this.eventManager.bind('guidedtour.ready').subscribe(async (fl: any) => {
          resolve(null);
        });
      }
    });
  }
  async triggerTutorial(options: any) {
    if(this.sharedDataService.appData.appDebug && this.sharedDataService.appData.appDebug.disableTutorial) return;
    await this.sleep(300);
    if (!this.messageConfig[this.version].active) return;
    if (!options.screenName || !options.tutorialName) { this.on_trigger_tutorial.next({ canDisplay: false }); return; }
    let displayOption: any = { screenName: options.screenName, tutorialName: options.tutorialName, message: "", canDisplay: options.canDisplay !== undefined ? options.canDisplay : false };
    let tutorial: any = await this.storage.getWithPromise("tutorial").catch((e: any) => { console.log("tutorial not found"); });
    if (!tutorial) tutorial = {};
    if (!tutorial[options.screenName]) tutorial[options.screenName] = {};
    if (!tutorial[options.screenName][options.tutorialName]) {
      tutorial[options.screenName][options.tutorialName] = true;
      displayOption.canDisplay = true;
    }
    displayOption.message = this.messageConfig[this.version].texts[options.tutorialName] ? this.domSanitizer.bypassSecurityTrustHtml(this.messageConfig[this.version].texts[options.tutorialName]) : "";
    // do not show any tutorial if 1st tutorial is not displayed
    // if (!(options.tutorialName == 'tutorial-8' || options.tutorialName == 'tutorial-9' || options.tutorialName == 'tutorial-10' || options.tutorialName == 'tutorial-11' || options.tutorialName == 'tutorial-16')) {
    //   if (options.tutorialName != 'tutorial-1' && (!tutorial['main-menu'] || (tutorial['main-menu'] && !tutorial['main-menu']['tutorial-1']))) {
    //     // displayOption.message = "";
    //     displayOption.canDisplay = false;
    //   }
    // }

    if (options.tutorialName == 'tutorial-8' || options.tutorialName == 'tutorial-16') {
      let relatedTutorial = options.tutorialName == 'tutorial-8' ? 'tutorial-16' : 'tutorial-8';
      if (tutorial[options.screenName][relatedTutorial]) {
        displayOption.canDisplay = false;
      }
    }
    
    await this.storage.setWithPromise("tutorial", tutorial);
    if (displayOption.canDisplay) {
      let param = {
        screenName: displayOption.screenName,
        tutorialName: displayOption.tutorialName
      }
      this.firebase.logEvents("guided_tour_popup_displayed", param);
    }
    // displayOption.canDisplay = true;
    this.on_trigger_tutorial.next(displayOption);
    if(displayOption.canDisplay) {
      setTimeout(()=>{
        this.webElementfocusService.setManualFocusTo("closeTutorial", "close-tutorial", 81, "class");
      }, 2000);
    }
   
  }


}
