import { Component, OnInit, HostListener } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseEventsService } from '../../../../utils/firebase-events.service';
import { InterfaceTextService } from '../../../../utils/interface-text.service';
import { SocialLoginService } from '../../services/social-login.service';

import { AppleSigninService } from '../../services/apple-signin.service';
import { WebElementfocusService } from '../../../../utils/common/web-elementfocus.service';

declare let $: any;

@Component({
  selector: 'app-auth-methods-popup',
  templateUrl: './auth-methods-popup.page.html',
  styleUrls: ['./auth-methods-popup.page.scss'],
})
export class AuthMethodsPopupPage implements OnInit {

  constructor(
    private platform: Platform,
    private device: Device,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private firebaseEvents: FirebaseEventsService,
    private socialLogin: SocialLoginService,
    private appleSignin: AppleSigninService,
    private interfaceText: InterfaceTextService,
    public webElementfocusService: WebElementfocusService
  ) { }

  showAppleLogin: boolean = true;
  iOSApp: boolean = true;
  bodyText:any = {};

  ngOnInit() {
    if(this.platform.is('android') && this.platform.is('cordova')) {
      this.showAppleLogin = false;
    } else if (this.platform.is('cordova') && parseInt(this.device.version.split(".")[0]) < 13) {
      this.showAppleLogin = false;
    }

    if(this.platform.is('cordova') && (this.device.platform.toLowerCase() == "ios")) {
      this.iOSApp = true;
    } else {
      this.iOSApp = false;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    let RIGHT_ARROW = event.key === this.webElementfocusService.KEY_CODE.RIGHT_ARROW;
    let LEFT_ARROW = event.key === this.webElementfocusService.KEY_CODE.LEFT_ARROW;
    let UP_ARROW = event.key === this.webElementfocusService.KEY_CODE.UP_ARROW;
    let DOWN_ARROW = event.key === this.webElementfocusService.KEY_CODE.DOWN_ARROW;
    if (this.webElementfocusService.isPWA) {
      switch (this.webElementfocusService.tabIndex) {
        case "authenticationMethodsEmail": {
          if (DOWN_ARROW) {
            this.webElementfocusService.setPositionsFocus("DOWN_ARROW");
          }
          if (RIGHT_ARROW) {
            this.webElementfocusService.setPositionsFocus("RIGHT_ARROW");
          }
          break;
        }
        case "authenticationMethodsFacebook": {
          if (DOWN_ARROW) {
            this.webElementfocusService.setPositionsFocus("DOWN_ARROW");
          }
          if (LEFT_ARROW) {
            this.webElementfocusService.setPositionsFocus("LEFT_ARROW");
          }
          break;
        }
        case "authenticationMethodsGoogle": {
          if (RIGHT_ARROW) {
            this.webElementfocusService.setPositionsFocus("RIGHT_ARROW");
          }
          if (UP_ARROW) {
            this.webElementfocusService.setPositionsFocus("UP_ARROW");
          }
          break;
        }
        case "authenticationMethodsApple": {
          if (UP_ARROW) {
            this.webElementfocusService.setPositionsFocus("UP_ARROW");
          }
          if (LEFT_ARROW) {
            this.webElementfocusService.setPositionsFocus("LEFT_ARROW");
          }
          break;
        }
      }
    }
  }

  @HostListener('window:click', ['$event'])
  clickout(event) {
    if (this.webElementfocusService.isPWA) {
      this.webElementfocusService.setFocusTo(this.webElementfocusService.tabIndex);
    }
  }

  ionViewWillEnter() {
    if (this.webElementfocusService.isPWA) {
      setTimeout(() => {
        this.webElementfocusService.setFocusTo("authenticationMethodsEmail")
      }, 500)
    }
    this.renderText();
  }

  exit() {
    this.modalCtrl.dismiss();
  }

  renderText() {
    let screen_name = "auth_methods_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  onMailSigninClick() {
    this.modalCtrl.dismiss().then(() => {
      this.navCtrl.navigateForward(["authentication/enterwithmail"]);
    });
  }

  onFacebookSigninClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.modalCtrl.dismiss();

    this.socialLogin.initFacebookSignIn();
    this.modalCtrl.dismiss();
  }

  onGoogleSigninClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.socialLogin.initGoogleSignIn();
    this.modalCtrl.dismiss();
  }

  onAppleSigninClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.appleSignin.init();
    this.modalCtrl.dismiss();
  }

  skipLogin() {
    this.modalCtrl.dismiss().then(() => {
      this.navCtrl.navigateForward(["tabs/tab1"]);
    });
    this.firebaseEvents.logEvents("skip_onboarding");
  }

}
