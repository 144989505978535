import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { DomSanitizer } from '@angular/platform-browser';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { EventManagerService } from '../../utils/common/event-manager.service';

@Component({
  selector: 'app-tutor-selection',
  templateUrl: './tutor-selection.page.html',
  styleUrls: ['./tutor-selection.page.scss'],
})
export class TutorSelectionPage implements OnInit {
  @Input() getCaller: any;
  @Input() updateCallerText: any;
  constructor(
    private modalCtrl: ModalController,
    // private domSanitizer:DomSanitizer,
    private interfaceText: InterfaceTextService,
    private eventManager: EventManagerService,
  ) { }
  bodyText:any={};
    tutorLangConfig: any = [];
  ngOnInit() {
    console.log(this.interfaceText.tutorLangList);

    this.interfaceText.tutorLangList.forEach((item)=>{
      if(item.available) {
        this.tutorLangConfig.push(item);
      }
    })
    this.renderText();

  }

  renderText() {
    let screen_name = "tutor_selection_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  async selectLanguage(e:Event, item:any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.tutorLangConfig.forEach(element => {
      if(element.code == item.code) element.selected = true;
      else element.selected = false;
    });
    await this.interfaceText.setLanguage(item.code);
    this.eventManager.trigger('language.updated');
    this.renderText();
    this.updateCallerText();
  }

  continueToOnboarding(e:Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.getCaller().dismiss();
  }
}
