import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TrialPopupPage } from './trial-popup.page';

import { BuyPage } from '../../popups/buy/buy.page';
import { BuyPageModule } from '../../popups/buy/buy.module';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
  entryComponents:[
    BuyPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BuyPageModule,
    ComponentsModule
  ],
  declarations: [TrialPopupPage]
})
export class TrialPopupPageModule {}
