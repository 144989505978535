import { Component, OnInit, Input } from '@angular/core';
import { Market } from '@ionic-native/market/ngx';
import { Platform } from '@ionic/angular';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { SharedDataService } from '../../utils/common/shared-data.service';
import { InterfaceTextService } from '../../utils/interface-text.service';

@Component({
  selector: 'app-app-update-popup',
  templateUrl: './app-update-popup.page.html',
  styleUrls: ['./app-update-popup.page.scss'],
})
export class AppUpdatePopupPage implements OnInit {
  @Input() options: any;
  private eventListeners: any = {};
  bodyText: any = {};
  showLoader:boolean = false;
  constructor(
    private firebaseEvents:FirebaseEventsService,
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    private interfaceText: InterfaceTextService,
    private platform: Platform,
    private market: Market
  ) { }

  ngOnInit() {
    this.firebaseEvents.logEvents('app_update_popup_display');
  }

  ionViewWillEnter() {
    this.renderText();
  }

  renderText() {
    let screen_name = "app_update_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  async accepted() {
    this.showLoader = true;
    this.platform.is('android') ? await this.market.open('com.digixl.digienglishmobile') : await this.market.open('com.dmp.englishmobile');
    this.showLoader = false;
    if(this.options.optional) this.sharedDataService.appData.appUpdatePopup.dismiss();
  }

  closeAlert() {
    this.firebaseEvents.logEvents('app_update_popup_closed_by_user');
    this.sharedDataService.appData.appUpdatePopup.dismiss();
  }

}
