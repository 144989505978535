import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BuyPage } from './buy.page';

import { PopupsPage } from './popups/popups.page';
import { PopupsPageModule } from './popups/popups.module';

@NgModule({
  entryComponents:[
    PopupsPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PopupsPageModule
  ],
  declarations: [BuyPage]
})
export class BuyPageModule {}
