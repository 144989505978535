import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncodeService {

  constructor() { }

  encode(data:string) {
    let hash = CryptoJS.SHA256(data).toString();
    hash = CryptoJS.MD5(hash).toString();
    hash = CryptoJS.SHA512(hash).toString();

    return hash;
  }
}
