import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

const localUrl:string = "/assets/";
const baseUrl:string = environment.webserviceEndpoint;
const stripeHost:string = environment.webserviceEndpoint;
const paypalHost:string = environment.webserviceEndpoint;

const s3Url:string = "https://englishmobile.s3.eu-central-1.amazonaws.com/";

//const speechAPIUrl:string = "http://192.168.6.106:9090/";//"https://englishmobile.s3.eu-central-1.amazonaws.com/";

@Injectable({
  providedIn: 'root'
})
export class WebserviceService {
  pendingRequest: any = {};
  constructor(
    private http: HttpClient
  ) { }

  async get(endpoint: string, options: any, onSuccess?: (re: any) => any, onError?: (re: any) => any) {
    let httpOptions:any = {
      headers: options.token ? new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + options.token
      }) : {}
    };
    if(options.responseType) httpOptions.responseType = options.responseType;

    
    let url:string;
    if(options.noPrefix) {
      url = endpoint;
    } else {
      if(options.local) {
        url = (localUrl + endpoint);
      } else if(options.bucket) {
        url = (s3Url + endpoint);
      } else {
        url = (baseUrl + endpoint);
      }
    }
    
    if(endpoint == "country") {
      url = "https://ip2c.org/s";
    } else if(endpoint.includes("stripe")){
      url = stripeHost+endpoint;
    }
    
    this.http.get(url, httpOptions).subscribe(async (data:any) => {
      let blobData:any = await this.checkForErrorInBlob(data);
      if(data.error == "device" || data.err == "iodevice" || data.err == "expired") {
        this.pendingRequest.method = "GET";
        this.pendingRequest.endpoint = endpoint;
        this.pendingRequest.options = options;
        this.pendingRequest.onSuccess = onSuccess ? onSuccess : false;
        this.pendingRequest.onError = onError ? onError : false;
        if(data.err == "iodevice") {
          if(this.reTryCount < 7) {
            this.processPendingRequest();
          } else {
            this.reTryCount = 0;
          }
        }
        if(blobData.error || blobData.err) {
          this.ws_response.next(blobData);
          onError(blobData);
        } else {
          this.ws_response.next(data);
          onError(data);
        }
      } else {
        if(onSuccess) onSuccess(data);
      }

    }, error => {
      console.log(error);
      if(onError) onError(error);
    });


  }

  private ws_response = new Subject<any>();
  onWsResponse = this.ws_response.asObservable();

  async post(endpoint: string, options: any, onSuccess?: (re: any) => any, onError?: (re: any) => any) {
    let httpOptions = {
      headers: options.token ? new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + options.token,
      }) : {}
    };
    let url = (baseUrl + endpoint);
    //let url = endpoint == "speech" ? (speechAPIUrl + endpoint) : (baseUrl + endpoint);

    if(endpoint.includes("stripe")){
      url = stripeHost+endpoint;
    } else if(endpoint.includes("paypal")) {
      url = paypalHost+endpoint;
    }
    
    this.http.post(url, options.data, httpOptions).subscribe((data:any) => {
      if(data.error == "device" || data.err == "iodevice" || data.err == "expired") {
        this.ws_response.next(data);
        this.pendingRequest.method = "POST";
        this.pendingRequest.endpoint = endpoint;
        this.pendingRequest.options = options;
        this.pendingRequest.onSuccess = onSuccess ? onSuccess : false;
        this.pendingRequest.onError = onError ? onError : false;
        if(data.err == "iodevice") {
          if(this.reTryCount < 7) {
            this.processPendingRequest();
          } else {
            this.reTryCount = 0;
          }
        }
        onError(data);
      } else {
        if(onSuccess) onSuccess(data);
      }
    }, error => {
      console.log(error);
      if(onError) onError(error);
    });
  }
  
  reTryCount = 0;
  async processPendingRequest(data?:any) {
    if(data){
      this.pendingRequest.options.token = data.jwt;
    } else {
      this.reTryCount++;
    }
    if(this.pendingRequest.method == "GET") {
      await this.get(this.pendingRequest.endpoint, this.pendingRequest.options, this.pendingRequest.onSuccess, this.pendingRequest.onError);
    } else if (this.pendingRequest.method == "POST") {
      await this.post(this.pendingRequest.endpoint, this.pendingRequest.options, this.pendingRequest.onSuccess, this.pendingRequest.onError);
    }

  }


  checkForErrorInBlob(data){
    let promise = new Promise((resolve, reject)=>{
      if(data instanceof Blob && data.type == 'application/json') {
        let reader: any = new FileReader();
        reader.onload = () => {
          try{
            let json = JSON.parse(reader.result);
            resolve(json);
          } catch(e) {
            resolve({});
          }
        }
        reader.readAsText(data);
      } else {
        resolve({});
      }
    });
    return promise;
  }
}

