import { Animation, createAnimation } from '@ionic/core';

export function myLeaveNoAnimation(baseEl: HTMLElement): Animation {

    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    const wrapperAnimation = createAnimation();
    const wrapperEl = baseEl.querySelector('.modal-wrapper');
    wrapperAnimation.addElement(wrapperEl);

    wrapperAnimation
      .fromTo('transform', 'scaleX(1) scaleY(1)', 'scaleX(1) scaleY(1)')
      .fromTo('opacity', 1, 0);

    return baseAnimation
      .addElement(baseEl)
      .easing('ease-in')
      .duration(0)
      .addAnimation([wrapperAnimation]);

}