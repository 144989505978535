import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlServiceService {

  constructor() { }

  private slide_visible = new Subject<any>();
  onSlideVisible = this.slide_visible.asObservable();

  scrollupSlide(id:any){
    this.slide_visible.next(id);
  }
}
