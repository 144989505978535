import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UserApiService } from '../../utils/user-api.service';
import { WebserviceService } from '../../utils/webservice.service';
import { InterfaceTextService } from '../../utils/interface-text.service';

declare let $: any;

@Component({
  selector: 'app-multi-device-alert',
  templateUrl: './multi-device-alert.page.html',
  styleUrls: ['./multi-device-alert.page.scss'],
})
export class MultiDeviceAlertPage implements OnInit {
  @Input() options: any;
  emailId:any;
  user:any;
  errorMakingActive:boolean=false;
  showLoader:boolean=false;
  bodyText: any = {};
  tabIndex:any;
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private userApiService: UserApiService,
    private webservice: WebserviceService,
    private interfaceText: InterfaceTextService
  ) { }

  ngOnInit() { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    let Up_Arrow_Key = event.key === "ArrowUp";
    let Down_Arrow_Key = event.key === "ArrowDown";

    if (window.innerWidth > 991) {
      switch (this.tabIndex) {
        case 68: {
          this.setFocusTo("acceptButton");
          if (Down_Arrow_Key) {
              this.setFocusTo("cancelButton");
          }
          break;
        }
        case 69: {
          this.setFocusTo("cancelButton");
          if (Up_Arrow_Key) {
            this.setFocusTo("acceptButton");
          }
          break;
        }
        case 70: {
          this.setFocusTo("loginAgainButton");
          break;
        }
      }
    }
  }

  
  @HostListener('window:click', ['$event'])
  clickout(event) {
    if (window.innerWidth > 991) {
      switch (this.tabIndex) {
        case 68: {
          this.setFocusTo("acceptButton");
          break;
        }
        case 69: {
          this.setFocusTo("cancelButton");
          break;
        }
        case 70: {
          this.setFocusTo("loginAgainButton");
          break;
        }

      }
    }
  }

  ionViewWillEnter() {
    setTimeout(() => {
      if (window.innerWidth > 991) {
        this.setFocusTo("acceptButton");
      }
    }, 450)
    this.renderText();
  }

  renderText() {
    let screen_name = "multi_device_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  setFocusTo(htmlElement) {

    //focus html elements
    let acceptButton = $('.tabIndex68'); //68
    let cancelButton = $('.tabIndex69'); //69
    let loginAgainButton = $('.tabIndex70'); //70

    if (window.innerWidth > 991) {
      switch (htmlElement) {
        case "acceptButton": {
          acceptButton.focus();
          this.tabIndex = 68;
          break;
        }
        case "cancelButton": {
          cancelButton.focus();
          this.tabIndex = 69;
          break;
        }
        case "loginAgainButton": {
          loginAgainButton.focus();
          this.tabIndex = 70;
          break;
        }
      }
    }
  }

  accepted() {
    setTimeout(() => {
      this.setFocusTo("loginAgainButton");
    }, 500)
    this.showLoader = true;
    this.userApiService.getUser().then((user:any)=>{
      this.user = user;
      let providerCode = this.user.provider == "email" ? 0 : this.user.provider == "google" ? 1 : this.user.provider == "facebook" ? 2 : 3;
      this.webservice.get("getjwt?email=" + this.user.email + "&password=" + this.user.password + "&accesstoken="+this.user.accesstoken+ "&fbt="+this.user.fbt+"&provider="+ providerCode, {}, response => {
        if (response.jwt) {
          this.showLoader = false;
          this.onSuccess(response);
        } else {
          this.showLoader = false;
          this.errorMakingActive = true;
        }
      }, error => {
          this.onError(error);
      });
    }).catch((error)=>{
      this.onError(error);
    })
  }

  cancelled() {
    this.userApiService.logoutUser().then(()=>{
      this.modalCtrl.dismiss().then(()=>{
        this.navCtrl.navigateRoot(["authentication/"]);
      });
    }).catch((err)=>{
      console.log(err)
    });
  }

  loginAgain(){
    this.showLoader = true;
    this.userApiService.getUser().then((user:any)=>{
        if(user.email) {
          this.userApiService.logoutUser().then(()=>{
            if(this.user.provider == "email") {
              this.navCtrl.navigateRoot(["authentication/enterwithmail", { email: user.email }]);
            } else {
              this.navCtrl.navigateRoot(["authentication/"]);
            }
            setTimeout(()=>{
              this.showLoader = false;
              this.modalCtrl.dismiss().then(()=>{});
            }, 1000);
          });
        }
    });
  }


  async onSuccess(response:any) {

    this.modalCtrl.dismiss().then(()=>{
      let successRes:any = {};
      successRes.jwt = response.jwt;
      successRes.email = this.user.email;
      let isPremium = this.user.premium;
      this.userApiService.onLoginSuccess(successRes).then(()=>{
        let data = {
          jwt : successRes.jwt
        }
        this.webservice.processPendingRequest(data);
        this.userApiService.getUser().then((user:any)=>{
          if(isPremium && !user.premium) {
            this.navCtrl.navigateRoot(["/tabs/tab1"]);
          }
        });
      });
    });
  }

  async onError(error:any) {
    console.log(error);
  }
}
