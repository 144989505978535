import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import { FirebaseEventsService } from './firebase-events.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(
    private appStorageService: AppStorageService,
    private firebase: FirebaseEventsService
  ) {
    // let res={data:{campaign_id:100}}
    // this.setCampaignTracker(res);

    // this.setPWAPurchaseEvent({method:'stripe'});
  }


  async setCampaignTracker(res:any) {
    let campaign_id = res.data.campaign_id;
    
    let campaigns:any = {
      'id' : campaign_id,
      'created': this.getRelativeTime(),
      'expires': this.getRelativeTime(365),
      'path': 'pwa.englishmobile.com',
      'purchase_channel': null
    }
    this.appStorageService.setWithPromise("emo_pwa_campaigns", campaigns);
  }


  async setPWAPurchaseEvent(param:any) {
    let _param: any = {};
    _param.affiliation = param.method;
    _param.currency = "EUR";
    _param.value = param.product.amount;
    this.firebase.logEvents('purchase', _param);
  }

  private getRelativeTime(delta?:number){
    if(!delta) delta = 0;
    let d = new Date();
    d.setTime(d.getTime() + (delta*24*60*60*1000));
    return d.getTime();
  }
}
