import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WebserviceService } from '../webservice.service';
import { EventManagerService } from './event-manager.service';

@Injectable({
  providedIn: 'root'
})
export class UserFlagsService {

  flagMap: any;

  private eventListeners: any = {};
  constructor(
    private eventManager: EventManagerService,
    private webservice: WebserviceService
  ) {
    this.eventListeners.setCurrentFlag = this.eventManager.bind('user.setCurrentFlag').subscribe(async (fl: any) => {
      this._flagExpand();
      this.setProperty('userFlag', (fl == '-' ? 0 : fl));
      this._flagExpand();
    });
  }

  preload() {
    return new Promise((resolve, reject) => {
      this.webservice.get(environment.flagMap, { bucket: true, local: false }, response => {
        this._flagExpand();
        this.flagMap = response;
        this._flagExpand();
        resolve({ok:true});
      }, err => {
        reject(err);
      });
      // this.webservice.get("course/" + environment.flagMap, { bucket: false, local: true }, response => {
      //   this.flagMap = response;
      //   resolve();
      // }, err =>{
      //   reject(err);
      // });
    });
  }



  private shared = {
    'userFlag': 0,
    '+': (a: any) => a[0] + a[1], '-': (a: any) => a[0] - a[1], '*': (a: any) => a[0] * a[1],
    '/': (a: any) => a[0] / a[1], '&': (a: any) => a[0] && a[1], '|': (a: any) => (a[0] !== undefined && a[1] !== undefined) ? (a[0] || a[1]) : undefined,
    '>': (a: any) => a[0] > a[1], '<': (a: any) => a[0] < a[1], '=': (a: any) => a[0] == a[1],
    '!': (a: any) => a[0] === undefined ? undefined : !a[0] //! is the only unary operation in this test
  }
  private _setFlag(flag) { this.shared['userFlag'] = this.shared['userFlag'] | flag; }
  private _getFlag(uF, flag) { return ((uF & flag) != 0); }
  private _flagExpand() {
    for (let key in this.flagMap) this.shared[key] = this._getFlag(this.shared['userFlag'], this.flagMap[key]); //Init all flags
    return this.shared;
  }

  setProperty(property: string, value: any) {
    this._flagExpand();
    this.shared[property] = value;
    this._flagExpand();
  }

  setFlag(flag: string) {
    this._flagExpand();
    this._setFlag(this.flagMap[flag]);
    this.eventManager.trigger('user.saveFlag', this.shared['userFlag']);
    this._flagExpand();
  }
  
  getFlag(property?: string) {
    let flags = this._flagExpand();
    if (property) return flags[property];
    else return flags;
  }

  evalExp(ex: any) {
    let S: any = this.shared;
    let st = ex.split(' '); for (let i in st) st[i] = st[i].match(/^-?\d+$/) ? parseInt(st[i]) : S[st[i]]; //creates stack
    do {
      for (let i in st) {
        if (typeof st[i] === "function") {
          let tmpval = st[i](st[i].name == '!' ? [st[parseInt(i) - 1]] : [st[parseInt(i) - 2], st[parseInt(i) - 1]]); //calculates one operation
          st.splice(st[i].name == '!' ? parseInt(i) - 1 : parseInt(i) - 2, st[i].name == '!' ? 2 : 3, tmpval) //replaces the operation with its result
          break;
        }
      }
    } while (st.length > 1);
    return st[0]
  }



}
