import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './desktop/menu/menu.component';
import { TabbingClickDirective } from './tabbing-click.directive';


@NgModule({
  declarations: [
    MenuComponent,
    TabbingClickDirective
  ],
  exports: [
    MenuComponent,
    TabbingClickDirective
  ],
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ComponentsModule {}
