import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  constructor(
    private storage: Storage
  ) { }

  async set( data: object, callback?: (re: any) => any ) {
    this.storage.get("appData").then(val => {
      if(val){
        let d = JSON.parse(val);
        for(var key in data) {
          d[key] = data[key]
        }
        this.storage.set("appData", JSON.stringify(d)).then(v => {
          if(callback) callback(v);
        });
      } else {
        let d = data;
        this.storage.set("appData", JSON.stringify(d)).then(v => {
          if(callback) callback(v);
        });
      }
    });
  }

  async getData( callback: (re: any) => any) {
    this.storage.get("appData").then(val => {
      callback(JSON.parse(val));
    });
  }

  async get(key:string) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.storage.get("appData").then(val => {
          (async ()=>{
            if(!val) {
              await this.storage.set("appData", '{}');
              val = await this.storage.get("appData");
            }
            try {
              let value = JSON.parse(val)[key];
              resolve(value);
            } catch(e){
              let value = val[key];
              resolve(value);
            }
          })();
        });
      } catch(e){
        reject(e);
      }
    });
    return promise;
  }


  async setWithPromise(key:string, value:string) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.storage.set(key, value).then(()=>{ resolve(true);})
        .catch((e)=>{reject(e);});
      } catch(e){
        reject(e);
      }
    });
    return promise;
  }

  async getWithPromise(key:string) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.storage.get(key).then((val)=>{ resolve(val);})
        .catch((e)=>{reject(e);});
      } catch(e){
        reject(e);
      }
    });
    return promise;
  }

  async removeWithPromise(key:string) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.storage.remove(key).then((val)=>{ resolve(val);})
        .catch((e)=>{reject(e);});
      } catch(e){
        reject(e);
      }
    });
    return promise;
  }


  async canShowInAppPopup(popup, user) {
    return new Promise(async (resolve, reject) => {
      // if(!environment.production) {
      //   resolve(true);
      // } else {
        let popupData = await this.storage.get("in_app_popup");
        if(!popupData) popupData = {};
        let now = new Date().getTime();
        if(!popupData[popup.id]) popupData[popup.id] = {};
        if(!popupData[popup.id][popup.node]) popupData[popup.id][popup.node] = {};
        
        if(user && user.email) {
          if(popupData[popup.id][popup.node][user.email]) {
            let frequency = parseInt(popup.frequency);
            let diffDays = Math.floor((now - Number(popupData[popup.id][popup.node][user.email])) / (1000 * 60 * 60 * 24)); 
            if(diffDays >= frequency) {
              popupData[popup.id][popup.node][user.email] = now;
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            popupData[popup.id][popup.node][user.email] = now;
            resolve(true);
          }
        } else {
          popupData[popup.id][popup.node]["non_user"] = now;
          resolve(true);
        }
        this.storage.set("in_app_popup", popupData);
        console.log("popupData ", popupData)
      // }
      
    });
  }


  async reset() {
    await this.storage.clear();
  }

}
