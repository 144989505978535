import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { SignInWithApple, ASAuthorizationAppleIDRequest, AppleSignInResponse } from '@ionic-native/sign-in-with-apple';
import { WebserviceService } from '../../../utils/webservice.service';
import { AppStorageService } from '../../../utils/app-storage.service';
import { UserApiService } from '../../../utils/user-api.service';
import { FirebaseEventsService } from '../../../utils/firebase-events.service';
import { NetworkService } from '../../../utils/network.service';
import { FacebookEventsHandlerService } from '../../../utils/analytics/facebook-events-handler.service';
import { environment } from '../../../../environments/environment';

import { Subject } from 'rxjs';


interface Win extends Window {
  AppleID: any;
}

@Injectable({
  providedIn: 'root'
})
export class AppleSigninService {

  constructor(
    private platform: Platform,
    private device: Device,
    private webservice: WebserviceService,
    private appStorageService: AppStorageService,
    private userApiService: UserApiService,
    private firebaseEvents: FirebaseEventsService,
    private networkService: NetworkService,
    private facebookEventsHandler: FacebookEventsHandlerService
  ) {

  }

  private login_start = new Subject<any>();
  onLoginStart = this.login_start.asObservable();

  private login_end = new Subject<any>();
  onLoginEnd = this.login_end.asObservable();

  async init() {
    this.login_start.next();

    this.networkService.getNetworkStatus(async (re: any) => {
      if (re.isOnline) {
        /**
         * Apple sign in for iOS
         */
        if (this.platform.is('cordova') && this.device.platform.toLowerCase() == "ios") {

          SignInWithApple.signin({
            requestedScopes: [
              ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
              ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
            ]
          }).then(async (signin_res: AppleSignInResponse) => {
            if (signin_res) {
              await this.saveUserInfo(signin_res, 'ios');
              this.validateToken(signin_res, 'ios');
            } else {
              this.onError({ ok: false });
            }
          }).catch(() => {
            this.onError({ ok: false });
          });

        } else {
          //if (!this.platform.is('cordova')) {
            /**
             * Apple sign in for web
             */

            this.loadAppleAuthScript().then(() => {
             
              const { AppleID }: Win = <any>window;
              if (AppleID) {
                AppleID.auth.init({
                  clientId: 'com.englishmobile.universal',
                  scope: 'name email',
                  redirectURI: environment.appleSigninRedirect,
                  state: 'apple login',
                  usePopup: true
                });

                AppleID.auth.signIn().then(async (signin_res: any) => {
                  await this.saveUserInfo(signin_res, 'universal');
                  this.validateToken(signin_res.authorization, "universal");
                }).catch((error) => {
                  console.log("error response > ", error);
                  this.onError(error);
                });

                this.appleSigninResponseHandler();
              }
              
            });
          //}
        }
      } else {
        this.onError({ error: "offline" });
      }
    })

  }

  appleSigninResponseHandler() {
    //Listen for authorization success
    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      //handle successful response

      console.log("apple ios signin success", data);
    });
    //Listen for authorization failures
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      //handle error.
      console.log("apple ios signin error", error);
    });
  }


  // temp code
  async validateToken(signin_res:any, _platform:any) {
    console.log(signin_res);
    let userCatche = await this.appStorageService.getWithPromise("apple_signin_user") || {};
    

    let postData: any = {};
    postData.data = {};
    // postData.data.user = signin_res.user;
    // postData.data.identityToken = signin_res.identityToken;
    postData.data.code = _platform == "ios" ? signin_res.authorizationCode : signin_res.code;

    this.webservice.post("verifyapplesignin/"+ _platform, postData, (verified_info) => {
      if (verified_info.ok) {
        this.webservice.get("isuser?email=" + verified_info.user, {}, async acc_info => {
          let options:any = {};
          options.acc_info = acc_info;
          options.verified_info = verified_info;
          options.userCatche = userCatche;
          options.platform = _platform;

          if (acc_info.ok) {
            this.signinToEmouser(options);
          } else {
            this.createNewUser(options);
          }
        });
      } else {

      }
    }, (err) => {
      console.log(err);
    });
  }

  private saveUserInfo(appleCredential: any, platform:string) {
    return new Promise(async (resolve, reject) => {

      let userId = 'appleid';
      let _user: any = await this.appStorageService.getWithPromise("apple_signin_user");
      if (!_user) _user = {};
      if (!_user[userId]) _user[userId] = {};
      if (!_user[userId].email) _user[userId].email = "";
      if (!_user[userId].fullName) _user[userId].fullName = {};
      if (!_user[userId].state) _user[userId].state = "";

      if (appleCredential.email) _user[userId].email = appleCredential.email;
      if (appleCredential.state) _user[userId].state = appleCredential.state;
      if (appleCredential.fullName) {
        _user[userId].firstName = appleCredential.fullName.givenName;
        _user[userId].lastName = appleCredential.fullName.familyName;
      }

      if(appleCredential.user && appleCredential.user.name) {
        _user[userId].firstName = appleCredential.user.name.firstName;
        _user[userId].lastName = appleCredential.user.name.lastName;
      }

      this.appStorageService.setWithPromise("apple_signin_user", _user).then(() => {
        resolve(null);
      }).catch((e) => {
        reject();
      });

    });
  }

  private loadAppleAuthScript() {
    return new Promise((resolve, reject) => {
      if (!window.document.getElementById('apple-auth-api')) {
        var s = window.document.createElement("script");
        s.id = "apple-auth-api";
        s.type = "text/javascript";
        s.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
        s.onload = () => { resolve(null); }
        s.onerror = (err) => { reject(err); }
        window.document.body.appendChild(s);
      } else {
        resolve(null);
      }
    });
  }

  async signinToEmouser(options:any){
    if (options.acc_info.code == 3 && options.acc_info.language == environment.language) {
      // if user already exist get jwt
      let fbtoken = await this.userApiService.getDeviceFBT();
      this.webservice.get("getjwt?email=" + options.acc_info.email + "&password=&accesstoken=" + options.verified_info.access_token + "&fbt=" + fbtoken + "&provider=" + options.acc_info.code + "&platform="+options.platform, {}, re => {
        this.firebaseEvents.logEvents("login", { method: 'apple' });
        const responseData: any = {};
        responseData.jwt = re.jwt;
        responseData.photoUrl = "";
        this.onSuccess(responseData);
      }, err => {
        this.onError(err);
      });
    } else {
      let end_options: any = {
        ok: false,
        change_method: {
          email: options.verified_info.email,
          name: (options.userCatche['appleid'] && options.userCatche['appleid'].firstName) ? options.userCatche['appleid'].firstName : '',
          attempt: '3'
        }
      }
      if(options.acc_info.language != environment.language) {
        end_options.change_method.language = options.acc_info.language;
        end_options.change_method.change_language = true;
      } else {
        end_options.change_method.code = options.acc_info.code;
      }
      this.login_end.next(end_options);
    }
  }

  async createNewUser(options:any) {
    
    let regOptions: any = {
      token: false,
      data: {
        username: options.verified_info.email,
        password: "",
        accesstoken: options.verified_info.access_token,
        jwt: "",
        fbt: await this.userApiService.getDeviceFBT(),
        name: options.userCatche['appleid'] ? options.userCatche['appleid'].firstName : "",
        surname: options.userCatche['appleid'] ? options.userCatche['appleid'].lastName : "",
        email: options.verified_info.email,
        gender: 'm',
        provider: 'apple',
        active: true,
        country: await this.getCountry(),
        language: environment.language,
        created_at: "",
        deleted_at: "",
        last_access: "",
        premium: false,
        receive_mail: true
      }
    }

    this.webservice.post("create?user="+options.verified_info.user, regOptions, response => {
      if(response.ok) {
        this.firebaseEvents.logEvents("sign_up", { method: 'apple' });
        let skipGetUser = true;
        const reg_data = regOptions.data;
        reg_data.jwt = response.jwt;
        reg_data.photoUrl = "";
        this.onSuccess(reg_data, skipGetUser);
        this.facebookEventsHandler.logEvent({event: 'fb_emo_complete_registration', params: {method: 'apple',  currency: '', value: ''}});
      } else {
        this.onError(response.err);
      }
    }, error => {
      this.onError(error);
    });


  }


  async onSuccess(response: any, skipGetUser?: any) {
    await this.userApiService.onLoginSuccess(response, skipGetUser);
    this.login_end.next({ ok: true });
  }

  onError(error: any, method?: any) {
    console.log("error now...");
    this.login_end.next(error);
  }

  getCountry() {
    let promise = new Promise((resolve, reject) => {
      let options = {
        responseType: 'text/plain'
      }
      this.webservice.get("country", options, (res) => {
        let countryCode = res.split(";")[1];
        resolve(countryCode);
      }, (err) => {
        reject("es");
      });
    });
    return promise;

  }
}
