import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { InterfaceTextService } from '../../../utils/interface-text.service';
import { EventManagerService } from '../../../utils/common/event-manager.service';

@Component({
  selector: 'app-desktop-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Output() tabClicked = new EventEmitter<boolean>();
  bodyText:any = {};
  private eventListeners: any = {};
  constructor(
    private router: Router,
    private interfaceText: InterfaceTextService,
    private eventManager: EventManagerService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    if(this.eventListeners.onLanguageChange) this.eventListeners.onLanguageChange.unsubscribe();
    this.eventListeners.onLanguageChange = this.eventManager.bind('language.updated').subscribe(async (fl: any) => {
      this.renderText();
    });
    if(this.eventListeners.onLanguageReady) this.eventListeners.onLanguageReady.unsubscribe();
    this.eventListeners.onLanguageReady = this.eventManager.bind('language.ready').subscribe(async (fl: any) => {
      this.renderText();
    });
    this.renderText();
  }

  renderText() {
    let screen_name = "tabs";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  goPage(page) {
    this.navCtrl.navigateRoot(['/tabs/'+page]);
  }

  isTabClicked(page) {
    this.tabClicked.emit(true);
    this.navCtrl.navigateRoot(['/tabs/'+page]);
}
}
