import { Animation, createAnimation } from '@ionic/angular';

export function myEnterNoAnimation(baseEl: HTMLElement): Animation {

    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .fromTo('transform', 'scaleX(0.95) scaleY(0.95)', 'translateX(0%) scaleX(1) scaleY(1)')
        .fromTo('opacity', 0, 1);

    backdropAnimation.fromTo('opacity', 0.01, 0.5);

    return baseAnimation
        .addElement(baseEl)
        .easing('ease-out')
        .duration(100)
        .beforeAddClass('show-modal')
        .addAnimation([backdropAnimation, wrapperAnimation])

}