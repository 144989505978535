import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserApiService } from '../user-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private userApi: UserApiService,
    private navCtrl: NavController
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if(window.location.href.includes('redeem.bbcfollowme.com') || window.location.href.includes('redeendev.bbcfollowme.com')) {
        if(route.routeConfig.path != "redeem") {
          this.navCtrl.navigateRoot(['/redeem?status=cancel']);
          observer.next(false);
          return;
        }
      }

      if(route.routeConfig.path == 'redeem') {
        if(!(window.location.href.includes('redeem.bbcfollowme.com') || window.location.href.includes('redeendev.bbcfollowme.com') || window.location.href.includes('localhost:4300'))) {
          this.navCtrl.navigateRoot(['/authentication']);
          observer.next(false);
          return;
        } else {
          observer.next(true);
          return;
        }
      }
      
      this.userApi.getUser().then((user: any) => {
        if (user && user.jwt) {
          if(user.active) {
            if(route.routeConfig.path == "verify-account") {
              this.navCtrl.navigateRoot(['/tabs/tab1']);
              observer.next(false);
            } else {
              observer.next(true);
            }
          } else {
            if(route.routeConfig.path == "verify-account") {
              observer.next(true);
            } else {
              this.navCtrl.navigateRoot(['/verify-account']);
              observer.next(false);
            }
          }
        } else {
          this.navCtrl.navigateRoot(['/authentication']);
          observer.next(false);
        }
        observer.complete();
      }).catch(() => {
        this.navCtrl.navigateRoot(['/authentication']);
        observer.next(false);
        observer.complete();
      });
    });
  }
}
