import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Subscription, Observable } from 'rxjs';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OfflineAlertPage } from '../../popups/offline-alert/offline-alert.page';
import { PurchaseService } from '../../utils/purchase.service';
import { NetworkService } from '../../utils/network.service';
import { UserApiService } from '../../utils/user-api.service';
import { WebserviceService } from '../../utils/webservice.service';
import { PopupsPage } from './popups/popups.page';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { InterfaceTextService } from '../../utils/interface-text.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.page.html',
  styleUrls: ['./buy.page.scss'],
})
export class BuyPage implements OnInit {
  storeProvider: any;
  productList: any = [];
  isPremiumUser: boolean = false;
  subscriptionButtonText: any = "";
  validationStart: Subscription;
  purchaseEnd: Subscription;
  networkChangeListener: Subscription;
  tutorialListener: Subscription;
  isLoading: boolean = true;
  isProcessing: boolean = false;
  networkStatus: any = {};
  private eventListeners: any = {};
  bodyText: any = {};
  constructor(
    private modalCtrl: ModalController,
    private purchaseService: PurchaseService,
    private navCtrl: NavController,
    private networkService: NetworkService,
    private userApiService: UserApiService,
    private webservice: WebserviceService,
    private ngZone: NgZone,
    private appVersion: AppVersion,
    private sanitizer: DomSanitizer,
    private eventManager: EventManagerService,
    private interfaceText: InterfaceTextService,
    private platform: Platform
  ) { }

  planBenifitConfig: Array<any> = null;
  createUI() {
    this.planBenifitConfig = [];
    let tempArr = [];

    Object.keys(this.purchaseService.benifitList).forEach((i:any)=>{
      i = Number(i);
      let item = this.purchaseService.benifitList[i];
      let tmp: any = {};
      tmp.heading = item.heading;
      tmp.desc = item.desc;
      tmp.seq_id = i + 1;
      tmp.expanded = false;
      tempArr.push(tmp);
      if (!((i+1) % 5)) {
        this.planBenifitConfig.push(tempArr);
        tempArr = [];
      } else if (i == this.purchaseService.benifitList.length - 1) {
        this.planBenifitConfig.push(tempArr);
      }
    });

    // for (let i = 0; i < this.purchaseService.benifitList.length; i++) {
    //   let item = this.purchaseService.benifitList[i];
    //   let tmp: any = {};
    //   tmp.heading = item.heading;
    //   tmp.desc = item.desc;
    //   tmp.seq_id = i + 1;
    //   tmp.expanded = false;
    //   tempArr.push(tmp);
    //   if (i !== 0 && !(i % 5)) {
    //     this.planBenifitConfig.push(tempArr);
    //     tempArr = [];
    //   } else if (i == this.purchaseService.benifitList.length - 1) {
    //     this.planBenifitConfig.push(tempArr);
    //   }
    // }
  }

  toggleDetails(e: Event, item: any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    item.expanded = !item.expanded;
    // if(item.expanded) item.expanded = !item.expanded;
    // else this.planBenifitConfig.forEach(_sets=>{
    //   _sets.forEach(_item=>{
    //     if(_item.seq_id == item.seq_id) _item.expanded = true;
    //     else _item.expanded = false;
    //   });
    // });
  }

  activeButton: any = {};
  purchaseButtonConfig: Array<any> = [];
  async createPurchaseButtons(orderIndex?: any) {
    this.activeButton = {};
    let tmpIndex = 0;

    for (let i = 0; i < this.productList.length; i++) {
      if (!this.isProductPurchased(this.userApiService.user, { id: this.productList[i].code })) {
        if (!this.productList[i].available) continue;
        if (this.productList[i].app_version) {
          let min = this.productList[i].app_version[0] ? this.productList[i].app_version[0] : "0.0.0";
          let max = this.productList[i].app_version[1];
          let app_version = await this.appVersion.getVersionNumber();
          if (this.compareVersion(app_version, min) != -1) {
            if (max && this.compareVersion(app_version, max) == 1) {
              continue;
            }
          } else {
            continue;
          }
        }
      }

      let button: any = {};
      button.id = this.productList[i].code;
      button.index = tmpIndex;
      button.original_index = i;
      button.duration = this.productList[i].code;
      button.processing = orderIndex == button.original_index ? true : false;
      button.given_index = this.productList[i].index ? this.productList[i].index : 0;
      button.focus = this.productList[i].focus ? this.productList[i].focus : '';

      if (!this.purchaseButtonConfig[button.index] || (this.purchaseButtonConfig[button.index] && !this.purchaseButtonConfig[button.index].text)) {
        button.text = "Cargando...";
        this.purchaseButtonConfig[button.index] = button;
      }

      this.getProductButtonText(button).subscribe((button: any) => {
        this.ngZone.run(() => {
          this.purchaseButtonConfig[button.index] = button;
          if (button.subscribed || (!(this.activeButton && this.activeButton.subscribed) && button.focus)) this.activeButton = button;
        });
      });

      tmpIndex++;

    }
  }

  compareVersion(a, b) {
    function getNumberArray(a, b) {
      return [
        a.split("."), b.split(".")
      ]
    }
    let val = 0;
    let numarr = getNumberArray(a, b);
    for (let i = 0; i < numarr[0].length; i++) {
      if (parseInt(numarr[0][i]) > parseInt(numarr[1][i])) {
        val = 1;
        break;
      } else if (parseInt(numarr[0][i]) < parseInt(numarr[1][i])) {
        val = -1;
        break;
      }
    }
    return val;
  }

  isProductPurchased(paymentInfo: any, button: any) {
    if (paymentInfo.premium && (paymentInfo.suscription && paymentInfo.suscription.duration == button.id)) {
      return true;
    } else if (paymentInfo.premium && paymentInfo.suscription) {
      let purchased_prod_details: any = null;
      let similar_prod: any = [];
      let product_not_found = true;
      let _product = null;
      this.productList.forEach(element => {
        if(element.code == button.id) _product = element;
        if (parseInt(element.code) == parseInt(paymentInfo.suscription.duration.toString())) {
          purchased_prod_details = element;
          product_not_found = false;
        }
      });
      if (product_not_found) {
        let all_product_lst = this.purchaseService.getAllProducts();
        let prod_list = all_product_lst ? all_product_lst[paymentInfo.suscription.method] : null;
        prod_list = prod_list ? Object.entries(prod_list) : [];
        prod_list.forEach(element => {
          if (parseInt(element[1].code) == parseInt(paymentInfo.suscription.duration)) purchased_prod_details = element[1];
        });
      } else {
        if (purchased_prod_details.access_level === _product.access_level && purchased_prod_details.duration_level === _product.duration_level){_product.available = false; return false;} 
        else return false;
      }
      this.productList.forEach(element => {
        if (purchased_prod_details.access_level === element.access_level && purchased_prod_details.duration_level === element.duration_level) {
          similar_prod.push(parseInt(element.code));
        }
      });
      if (similar_prod.indexOf(parseInt(button.id)) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getProductButtonText(button: any) {
    return new Observable((observer) => {
      this.purchaseService.isPremiumUser().then((paymentInfo: any) => {

        if (this.isProductPurchased(paymentInfo, button)) {
          // let duration = paymentInfo.suscription.duration == "2" ? "anual" : "mensual";
          button.subscribed = true;
          this.getProductPrice(button).subscribe((button: any) => {
            observer.next(button);
            observer.complete();
          });
        } else if (button.processing) {
          button.text = "Procesando...";
          observer.next(button);
          observer.complete();
        } else {
          this.getProductPrice(button).subscribe((button: any) => {
            observer.next(button);
            observer.complete();
          });
        }
      }).catch((err) => {
        console.log("err ", err);
        this.getProductPrice(button).subscribe((button: any) => {
          if (button.processing) {
            button.text = "Procesando...";
          }
          observer.next(button);
          observer.complete();
        });
      });
    });
  }
  sanitizeText(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
  getProductPrice(button: any) {
    return new Observable((observer) => {
      this.purchaseService.getPrice(button.original_index).then((price: any) => {
        let text: string;
        if (button.subscribed) text = this.productList[button.original_index].button_text[1];
        else text = this.productList[button.original_index].button_text[0];
        let tmp: any = price.amount / price.duration.interval;
        tmp = tmp.toFixed(2);
        let ppu = (price.price.indexOf(price.currency) == 0 ? (price.currency + ' ' + tmp) : (tmp + price.currency)) + "/" + price.duration.unit_keywords[0];
        text = text.replace("{{amount/interval}}", ppu);
        text = text.replace("{{interval}}", price.duration.interval + " " + price.duration.unit_keywords[0]);
        button.text = this.sanitizeText(text);
        button.ppu = ppu;
        button.amount = price.amount;
        button.duration_info = price.duration;
        button.currency = price.currency;
        button.total_price_text = this.sanitizeText(this.productList[button.original_index].button_text[2].replace("{{amount}}", price.price));
        observer.next(button);
        observer.complete();
      });
    });
  }

  openPlanListPopup(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.eventManager.trigger('purchase.requestPlanList', this.purchaseButtonConfig);
    this.modalCtrl.dismiss();
  }

  userInfo: any = {}
  async subscribe(productConfig: any) {

    this.networkService.getNetworkStatus(async (re: any) => {


      if (!re.isOnline) {
        let popupText = this.interfaceText.getBodyText("no_internet_popup");
        this.openNoInternetPopup(popupText['message_3']);
      } else {
        let user: any = await this.userApiService.getUser();
        if (this.storeProvider == 'ios' || this.storeProvider == 'android') {
          if (user.premium && (user.suscription && user.suscription.method) && user.suscription.method.indexOf(this.storeProvider) == -1) {
            this.userInfo = user;
            if (user.suscription.method == "paypal") {
              //this.updatePaypalSubscription(productConfig);
              let err: any = {};
              err.methodMismatch = true;
              err.originalMethod = this.getPaymentMethodName(user.suscription.method);
              this.openPopup(err);
            } else if (user.suscription.method == "stripe") {
              this.updateStripeSubscription(productConfig);
            } else {
              let err: any = {};
              err.methodMismatch = true;
              err.originalMethod = this.getPaymentMethodName(user.suscription.method);
              this.openPopup(err);
            }
          } else {
            this.purchaseService.canPurchase().then(() => {
              this.purchaseService.orderProduct(productConfig.original_index);
            }).catch((err) => {
              this.openPopup(err);
            });
          }
        } else {
          if (user && user.premium) {
            this.userInfo = user;
            if (user.suscription && user.suscription.method) {
              if (!(user.suscription.method == "paypal" || user.suscription.method == "stripe")) {
                let err: any = {};
                err.methodMismatch = true;
                err.originalMethod = this.getPaymentMethodName(user.suscription.method);
                this.openPopup(err);
              } else if (user.suscription.method == "paypal") {
                this.updatePaypalSubscription(productConfig);
              } else if (user.suscription.method == "stripe") {
                this.updateStripeSubscription(productConfig);
              }
            }
          } else {
            await this.modalCtrl.dismiss({ "navigation": true });
            this.purchaseService.canPurchase().then(() => {
              this.navCtrl.navigateForward(['/tabs/tab3/payment/' + productConfig.duration]);
            }).catch((err) => {
              this.openPopup(err);
            });
          }
        }
      }
    });
  }



  updateStripeSubscription(productConfig) {
    let postData: object = {
      token: this.userInfo.jwt,
      data: {
        duration: productConfig.duration
      }
    }
    this.createPurchaseButtons(productConfig.original_index);
    this.webservice.post("stripe/update", postData, async (response) => {
      await this.userApiService.setUser(this.userApiService.user);
      this.createPurchaseButtons();
    }, async (error) => {
      console.log("error updating");
      await this.userApiService.setUser(this.userApiService.user);
      this.createPurchaseButtons();
    });
  }

  updatePaypalSubscription(productConfig) {
    let postData: object = {
      token: this.userInfo.jwt,
      data: {
        duration: productConfig.duration
      }
    }
    this.createPurchaseButtons(productConfig.original_index);
    this.webservice.post("paypal/update", postData, response => {
      console.log(response)
      if (response.statusCode == 200) {
        response.body.links.forEach((link: any) => {
          if (link.rel == "approve") {
            window.open(link.href, "_self");
          }
        });
      } else {
        let err: any = {};
        err.generalError = true;
        this.openPopup(err);
        this.createPurchaseButtons();
      }
    }, error => {
      console.log("error updating", error);
      let err: any = {};
      err.generalError = true;
      this.openPopup(err);
      this.createPurchaseButtons();
    });
  }

  storeRefreshTimeout: any = null;
  storeRefreshTimer: any = null;
  ionViewWillEnter() {
    this.purchaseService.loadAdditionalData();
    this.renderText();
    this.createPurchaseButtons();
    this.createUI();
  }

  renderText() {
    let screen_name = "plans";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  ionViewWillLeave() {
    if (this.eventListeners.subscriptionBtnListener) this.eventListeners.subscriptionBtnListener.unsubscribe();
  }

  isAndroid:boolean = false;
  async ngOnInit() {
    this.isAndroid = this.platform.is('android');
    this.storeProvider = this.purchaseService.storeProvider;
    this.productList = await this.purchaseService.getProductList();
    this.createPurchaseButtons();

    this.purchaseEnd = this.purchaseService.onPurchaseEnd.subscribe((data: any) => {

      if (data.error == "not_unique") {
        let err: any = {};
        err.notUniquePayment = true;
        this.openPopup(err);
      }

      if (data.error == "skip") {
        this.createPurchaseButtons();
      } else {
        this.createPurchaseButtons();
        // if(this.storeRefreshTimeout) {
        //   clearTimeout(this.storeRefreshTimeout);
        //   this.storeRefreshTimeout = null;
        // } 
        // this.storeRefreshTimeout = setTimeout(()=>{
        //   this.createPurchaseButtons();
        // }, 1000);
      }


      if (data.ok) this.userApiService.resetJWT(true);
    });

    this.validationStart = this.purchaseService.onValidationStart.subscribe((orderIndex) => {
      this.createPurchaseButtons(orderIndex);
    });

    this.networkService.getNetworkStatus((re: any) => {
      this.networkStatus.isOnline = re.isOnline;
      this.networkStatus.type = re.type;
    });

    if (this.networkChangeListener) this.networkChangeListener.unsubscribe();
    this.networkChangeListener = this.networkService.onNetworkChange.subscribe((re: any) => {
      this.networkStatus.isOnline = re.isOnline;
      this.networkStatus.type = re.type;
    });

    if (this.eventListeners.subscriptionBtnListener) this.eventListeners.subscriptionBtnListener.unsubscribe();
    this.eventListeners.subscriptionBtnListener = this.eventManager.bind('purchase.subscribeClicked').subscribe(async (config: any) => {
      this.subscribe(config);
    });
  }

  getPaymentMethodName(method) {
    let store;
    if (method == 'ios-appstore') {
      store = "Apple Store"
    } else if (method == "android-playstore") {
      store = "Google Play";
    } else if (method == "paypal") {
      store = "Paypal";
    } else if (method == "stripe") {
      store = "Tarjeta de Crédito o Débito";
    }

    return store;
  }

  async openTerms() {
    await this.modalCtrl.dismiss({ "navigation": true });
    this.navCtrl.navigateForward(['/tabs/tab3/terms']);
  }

  restorePurchase(e?: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
    this.purchaseService.storeRefresh();
  }


  async openNoInternetPopup(msg: any) {
    const modal = await this.modalCtrl.create({
      component: OfflineAlertPage,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        options: {
          message: msg
        }
      }
    });
    await modal.present();
  }

  subscriptionPageModal: any = null;
  async openPopup(error: any) {
    if (this.subscriptionPageModal) return;
    this.subscriptionPageModal = await this.modalCtrl.create({
      component: PopupsPage,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        options: error
      }
    });
    this.subscriptionPageModal.onDidDismiss().then((re: any) => {
      this.subscriptionPageModal = null;
    });
    await this.subscriptionPageModal.present();
  }

  closeBuyPopup(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.modalCtrl.dismiss();
  }
}
