import { Injectable } from '@angular/core';
import { WebserviceService } from './webservice.service';
import { ZipService } from './zip.service';
import { AppStorageService } from './app-storage.service';
import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NetworkService } from './network.service';
import { environment } from '../../environments/environment';
import * as FileSaver from 'file-saver';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class ContentDownloadService {
  downloadingList: Array<any> = [];
  downloadedList: Array<any> = [];
  downloadPending: Array<any> = [];

  constructor(
    private webservice: WebserviceService,
    private zipService: ZipService,
    private appStorageService: AppStorageService,
    private platform: Platform,
    private device: Device,
    private file: File,
    private fileOpener: FileOpener,
    private networkService: NetworkService
  ) {
    this.getDownloadInfo();
  }

  private save_complete: any = {};
  private onSaveComplete: any = {};

  async download(id: any) {
    let promise = new Promise((resolve, reject) => {

      this.getDownloadSettings().then((settings: any) => {
        if (settings && settings.wifi && this.networkService.type != 'wifi') {
          let error = {
            message: "You are not connected to wifi.",
            code: 5,
            id: id
          }
          reject(error);
        } else {
          if (this.downloadedList.length >= 5) {
            let error = {
              message: "Reached maximum download limit.",
              code: 1,
              id: id
            }
            reject(error);
          } else if ((this.downloadedList.length + this.downloadingList.length + this.downloadPending.length) >= 5) {
            let error = {
              message: "Too many download request.",
              code: 2,
              id: id
            }
            reject(error);
          } else {
            this.downloadPending.push({ 'id': id });
            this.save_complete[id] = new Subject<any>();
            this.onSaveComplete[id] = this.save_complete[id].asObservable();

            this.webservice.get(environment.contentZipPath + environment.language + (environment.production ? '' : '/dev') + "/" + id + ".zip", { responseType: 'blob', bucket: true }, response => {
              this.downloadPending.forEach(element => {
                if (element.id == id) {
                  element.response = response;
                }
              });
              if (this.downloadingList.length == 0) this.checkForDownload();
              this.onSaveComplete[id].subscribe((id, error) => {
                if (error) {
                  let err = {
                    message: "Unzip error.",
                    error: error,
                    code: 3,
                    id: id
                  }
                  reject(err);
                  if (this.downloadPending[0]) {
                    this.checkForDownload();
                  }
                  return;
                }

                if (this.downloadedList.indexOf(id) == -1) {
                  this.downloadedList.push(id);
                }
                resolve(id);
                if (this.downloadPending[0]) {
                  this.checkForDownload();
                } else {
                  this.downloadingList = [];
                }
              });
            }, error => {
              let err = {
                message: "Download error.",
                error: error,
                code: 4,
                id: id
              }
              reject(err);
              this.downloadPending.forEach((el, index) => {
                if (id == el.id) {
                  this.downloadPending.splice(index, 1);
                }
              });
            });
          }
        }
      });
    });
    return promise;
  }

  async getDownloadSettings() {
    let promise = new Promise((resolve, reject) => {
      this.appStorageService.get("downloadSettings").then((settings: any) => {
        resolve(settings);
      }).catch(() => {
        resolve({ "wifi": false });
      });
    });
    return promise;
  }

  async checkForDownload() {
    this.downloadingList = [];
    if (this.downloadPending[0]) {
      this.downloadingList.push(this.downloadPending.shift());
      if (!this.downloadPending[0]) this.downloadPending = [];
    } else {
      return;
    }

    this.saveToDB(this.downloadingList[0].id, this.downloadingList[0].response).then(() => {
      this.save_complete[this.downloadingList[0].id].next(this.downloadingList[0].id);
    }).catch((err) => {
      this.save_complete[this.downloadingList[0].id].next(this.downloadingList[0].id, err);
    });

  }

  async saveToDB(id, response: any) {
    var self = this;
    let promise = new Promise((resolve, reject) => {
      try {
        this.zipService.getEntries(response).subscribe((entries: any) => {
          console.log(entries);
          let count = 0;
          let lessonData = {};
          let name: any;
          (async function saveEntry(count) {
            if (count < entries.length) {
              self.zipService.getData(entries[count]).data.subscribe((data: any) => {
                name = entries[count].filename;
                if (name.indexOf("/") > -1) {
                  name = name.split("/");
                  name = name[(name.length - 1)];
                }
                if (self.platform.is('cordova')) {
                  self.saveToFileSystem(id.toString(), name.toString(), data).then(() => {
                    saveEntry(++count);
                  }).catch(() => {
                    saveEntry(++count);
                  });
                } else {
                  lessonData[name] = data;
                  setTimeout(() => {
                    saveEntry(++count);
                  }, 100);
                }
              });
            } else {
              self.appStorageService.getWithPromise('downloads').then((downloads: any) => {
                if (!downloads) downloads = {};
                if (self.platform.is('cordova')) {
                  downloads[id] = { downloaded: true };
                } else {
                  downloads[id] = lessonData;
                }
                self.appStorageService.setWithPromise('downloads', downloads).then(() => {

                  resolve(null);
                });
              }).catch((err) => {
                resolve(null);
              });
            }
          })(count);
        });
      } catch (e) {
        reject(e);
      }
    });
    return promise;
  }

  saveToFileSystem(dirName: string, filename: string, file: Blob) {
    let filePath = this.getNativeLocalFileDir();
    console.log(filePath)
    let promise = new Promise((resolve, reject) => {
      this.file.checkDir(filePath, dirName).then(() => {
        this.file.writeFile((filePath + dirName), filename, file, { replace: true }).then((fileEntry: FileEntry) => {
          resolve(null);
        }).catch((err) => {
          reject();
        });
      }).catch(() => {

        this.file.createDir(filePath, dirName, true).then(() => {
          this.file.writeFile((filePath + dirName), filename, file, { replace: true }).then((fileEntry: FileEntry) => {
            resolve(null);
          }).catch((err) => {
            reject();
          });
        }).catch(() => {
          reject();
        });
      });
    });
    return promise;
  }


  getNativeLocalFileDir() {
    if (this.device.platform.toLowerCase() == "ios") {
      // return this.file.tempDirectory;
      return this.file.dataDirectory;
    } else if (this.platform.is('android')) {
      return this.file.cacheDirectory;
    }
  }


  isDownloading(id: any) {
    let isDownloading = false;
    this.downloadingList.forEach(el => {
      if (el.id == id) {
        isDownloading = true;
        return isDownloading;
      }
    });
    this.downloadPending.forEach(el => {
      if (el.id == id) {
        isDownloading = true;
        return isDownloading;
      }
    });
    return isDownloading;

  }

  isDownloaded(id: any) {
    if (this.downloadedList.indexOf(id) > -1 || this.downloadedList.indexOf(id.toString()) > -1) {
      return true;
    } else {
      return false;
    }
  }


  private download_info_update = new Subject<any>();
  onDownloadInfoUpdated = this.download_info_update.asObservable();
  getDownloadInfo() {
    return new Promise((resolve, reject) => {
      this.appStorageService.getWithPromise('downloads').then((downloads: any) => {
        //console.log(downloads)
        if (downloads) {
          for (let id in downloads) {
            if (this.downloadedList.indexOf(id) == -1) {
              this.downloadedList.push(id);
            }
          }
        }
        resolve(this.downloadedList)
        this.download_info_update.next(this.downloadedList);
      }).catch((err) => {
        reject(err)
      });
    });
  }

  getContent(id, name) {
    console.log("here in get data");
    let promise = new Promise((resolve, reject) => {
      this.appStorageService.getWithPromise('downloads').then((downloads: any) => {
        //console.log("1");
        try {
          //console.log("2 ", downloads);
          if (downloads) {
            //console.log(downloads);
            let lessonId: any = 0;
            for (let i in downloads) {
              if (i > id) {
                //console.log(lessonId);
              } else {
                console.log(i);
                lessonId = i;
              }
            }

            if (this.platform.is('cordova')) {
              if (downloads[lessonId]) {
                let filePath = this.getNativeLocalFileDir();
                this.file.readAsArrayBuffer((filePath + lessonId), name).then((buffer) => {
                  if (name.includes(".mp3")) {
                    let file = new Blob([buffer], { type: 'audio/mpeg' });
                    resolve(file);
                  } else {
                    let file = new Blob([buffer]);
                    resolve(file);
                  }

                }).catch(() => {
                  reject();
                });
              } else {
                reject();
              }
            } else {
              if (downloads[lessonId] && downloads[lessonId][name]) {
                resolve(downloads[lessonId][name]);
              } else {
                reject();
              }
            }
          } else {
            reject();
          }
        } catch (e) {
          reject();
        }
      });
    });
    return promise;
  }

  remove(lessonId?: any) {
    let promise = new Promise((resolve, reject) => {
      this.appStorageService.getWithPromise('downloads').then((downloads: any) => {
        try {
          if (downloads) {

            if (lessonId) {


              if (downloads[lessonId]) {
                delete downloads[lessonId];
                var index = this.downloadedList.indexOf(lessonId);
                if (index == -1) index = this.downloadedList.indexOf(lessonId.toString());
                //console.log(index);
                //console.log(this.downloadedList);
                //console.log(lessonId.toString());
                if (index > -1) {
                  this.downloadedList.splice(index, 1);
                  //console.log(this.downloadedList);
                }
                this.appStorageService.setWithPromise('downloads', downloads).then(() => {
                  this.download_info_update.next(this.downloadedList);
                  resolve(null);
                });
              } else {
                reject();
              }
            } else {
              this.downloadedList = [];
              this.appStorageService.setWithPromise('downloads', null).then(() => {
                this.download_info_update.next(this.downloadedList);
                resolve(null);
              });
            }
          } else {
            downloads = {}
            this.downloadedList = [];

            this.appStorageService.setWithPromise('downloads', downloads).then(() => {
              resolve(null);
            });
          }
        } catch (e) {
          reject();
        }
      });
    });
    return promise;
  }


  downloadCertificate(blob: Blob, filename: any) {
    if (this.platform.is("cordova")) {
      this.file.writeFile((this.file.dataDirectory), (filename + ".pdf"), blob, { replace: true }).then((fileEntry: FileEntry) => {
        this.fileOpener.open(this.file.dataDirectory + filename + '.pdf', 'application/pdf').then(() => { }).catch(e => { });
      }).catch((err) => {
        console.log("Not downloaded > ", err)
      });
    } else {
      FileSaver.saveAs(blob, (filename + ".pdf"));
    }
  }


  savePicture(imgFile: Blob, filename: any) {
    let promise = new Promise((resolve, reject) => {
      if (this.platform.is("cordova")) {
        this.file.writeFile((this.file.dataDirectory), filename + ".jpeg", imgFile, { replace: true }).then((fileEntry: FileEntry) => {
          resolve(null);
        }).catch((err) => {
          console.log("Not downloaded > ", err)
        });
      } else {
        this.appStorageService.getWithPromise('profile_pic').then(async (profilepic: any) => {
          profilepic = {};
          profilepic[filename] = await this.fileToBase64(imgFile);
          this.appStorageService.setWithPromise('profile_pic', profilepic).then(() => {
            resolve(null);
          });
        }).catch((err) => {
          resolve(null);
        });
      }
    });
    return promise;
  }

  getPicture(fileName: any) {
    let promise = new Promise((resolve, reject) => {
      if (this.platform.is("cordova")) {
        this.file.readAsDataURL(this.file.dataDirectory, fileName + ".jpeg").then((re) => {
          resolve(re);
        }).catch(() => {
          reject();
        });
      } else {
        this.appStorageService.getWithPromise('profile_pic').then((profilepic: any) => {
          resolve(profilepic[fileName]);
        }).catch((err) => {
          resolve(null);
        });
      }
    });
    return promise;
  }

  fileToBase64(file) {
    let promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    return promise;
  }

  saveFile(file: Blob, filename: any) {
    return new Promise((resolve, reject) => {
      if (this.platform.is("cordova")) {
        this.file.writeFile((this.file.dataDirectory), filename, file, { replace: true }).then((fileEntry: FileEntry) => {
          resolve(null);
        }).catch((err) => {
          console.log("Not saved > ", err)
        });
      }
    });
  }

  getFile(fileName: any) {
    return new Promise((resolve, reject) => {
      if (this.platform.is("cordova")) {
        this.file.readAsDataURL(this.file.dataDirectory, fileName).then((re) => {
          resolve(re);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }

}
