import { Injectable } from '@angular/core';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { AuthenticationPage } from '../pages/authentication/authentication.page';
import { UserApiService } from '../utils/user-api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkHandlerService {
  
  constructor(
    private deeplinks: Deeplinks,
    private platform: Platform,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private userApiService: UserApiService
  ) { }

  canResume: boolean = true;
  private _isDeeplink: boolean = null;
  private on_deeplink_resolve = new Subject<any>();
  onDeeplinkResolve = this.on_deeplink_resolve.asObservable();

  handleRouts() {
    this.canResume = true;
    this.platform.ready().then((re) => {
      
      if (this.platform.is("cordova")) {
        this.deeplinks.route({
          '/': AuthenticationPage,
        }).subscribe(match => {


          console.log("DEEPLINK URLLLL:::::  ", match);

          let urlParams: any = this.getUrlVars(match.$link.fragment);
          if (urlParams.id == "confirm") {
            this._isDeeplink = true;
            let option = {
              code: urlParams.code,
              email: urlParams.email
            }
            this.userApiService.verifyUser(option).then((_optn: any) => {
              let res = {
                id: "confirm",
                data: _optn.user,
                error: false,
                isUnique: _optn.isUnique
              }
              this.on_deeplink_resolve.next(res);
            }).catch((err) => {
              let res = {
                id: "confirm",
                data: null,
                error: err
              }
              this.on_deeplink_resolve.next(res);
            });
          } else if (urlParams.id == "resetPass") {
            this._isDeeplink = true;
            this.userApiService.getUser().then((user: any) => {
              if (!user.email) {
                let res = {
                  id: "resetPass",
                  data: urlParams,
                  error: false
                }
                this.on_deeplink_resolve.next(res);
              }
            }).catch((err) => {
              let res = {
                id: "resetPass",
                data: urlParams,
                error: false
              }
              this.on_deeplink_resolve.next(res);
            });

          } else if (urlParams.id == "cancel-email") {
            this._isDeeplink = true;
            let res = {
              id: "cancel-email",
              data: {
                code: urlParams.code,
                email: urlParams.email
              },
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if (urlParams.id == "content") {
            this._isDeeplink = true;
            this.canResume = false;

            if(match.$link.fragment.includes('tabs/tab1')) {
              let res = {
                id: "content",
                data: {
                  path: "tabs/tab1",
                  node: urlParams.node,
                  open_gl: urlParams.gl ? true : false
                },
                error: false
              }
              this.on_deeplink_resolve.next(res);
            } else if(
              match.$link.fragment.includes('/video') || 
              match.$link.fragment.includes('/tabs/tab2') ||
              match.$link.fragment.includes('/tabs/tab3') || 
              match.$link.fragment.includes('/tabs/tab4')
            ) {
              let res = {
                id: "content",
                data: {
                  path: match.$link.fragment.split("?")[0]
                },
                error: false
              }
              this.on_deeplink_resolve.next(res);
            }
          } else if(urlParams.id == "email_link_click") {
            if(urlParams.resume != 'true') this._isDeeplink = true;
            console.log('match.$link.fragment.split("?") ', match.$link.fragment.split("?"));
            let res = {
              id: urlParams.id,
              data: urlParams,
              path: match.$link.fragment.split("?")[0],
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if(urlParams.id == "immidiate_deletion") {
            let res = {
              id: urlParams.id,
              data: urlParams,
              path: match.$link.fragment.split("?")[0],
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if(urlParams.id == "cancel_deletion") {
            let res = {
              id: urlParams.id,
              data: urlParams,
              path: match.$link.fragment.split("?")[0],
              error: false
            }
            this.on_deeplink_resolve.next(res);
          }
        }, nomatch => {
          this._isDeeplink = false;
          console.log("here in subscribe nomatch", nomatch);
        });
      } else {
        this.route.queryParams.subscribe(param => {
          if (param.id == "confirm") {
            this._isDeeplink = true;
            let option = {
              code: param.code,
              email: param.email
            }
            this.userApiService.verifyUser(option).then((_optn: any) => {
              let res = {
                id: "confirm",
                data: _optn.user,
                error: false,
                isUnique: _optn.isUnique
              }
              this.on_deeplink_resolve.next(res);
            }).catch((err) => {
              let res = {
                id: "confirm",
                data: false,
                error: err
              }
              this.on_deeplink_resolve.next(res);
            });
          } else if (param.id == "resetPass") {
            this._isDeeplink = true;
            this.userApiService.getUser().then((user: any) => {
              if (!user || !user.email) {
                let res = {
                  id: "resetPass",
                  data: param,
                  error: false
                }
                this.on_deeplink_resolve.next(res);
              } else {
                this.navCtrl.navigateRoot(["tabs/tab1"]);
              }
            }).catch((err) => {
              let res = {
                id: "resetPass",
                data: param,
                error: false
              }
              this.on_deeplink_resolve.next(res);
            });
          } else if (param.id == "cancel-email") {
            this._isDeeplink = true;
            let res = {
              id: "cancel-email",
              data: {
                code: param.code,
                email: param.email
              },
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if (param.id == "content") {
            this._isDeeplink = true;
            this.canResume = false;
            console.log(this.router.url)
            if(this.router.url.includes('tabs/tab1')) {
              let res = {
                id: "content",
                data: {
                  path: "tabs/tab1",
                  node: param.node,
                  open_gl: param.gl ? true : false
                },
                error: false
              }
              this.on_deeplink_resolve.next(res);
            } else if(this.router.url.includes('/video')) {
              // redirects to video autometically
            } else if(this.router.url.includes('/tabs/tab2')) {
              // redirects to repasso autometically
            } else if(this.router.url.includes('/tabs/tab3')) {
              // redirects to plans autometically
            } else if(this.router.url.includes('/tabs/tab4')) {
              // redirects to accounts autometically
            }
          } else if(param.id == "email_link_click") {
            if(param.resume != 'true') this._isDeeplink = true;
            let res = {
              id: param.id,
              data: param,
              path: this.router.url.split("?")[0],
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if(param.id == "campaign_link"){
            let res = {
              id: param.id,
              data: param,
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if(param.id == "immidiate_deletion") {
            let res = {
              id: param.id,
              data: param,
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else if(param.id == "cancel_deletion") {
            let res = {
              id: param.id,
              data: param,
              error: false
            }
            this.on_deeplink_resolve.next(res);
          } else {
            this._isDeeplink = false;
          }
        });
      }
    });
  }

  canResumeCourse(){
    return (!this._isDeeplink);
  }

  isDeeplink(){
    let urlParams: any = this.getUrlVars(window.location.href);
    if (
      urlParams.id == "confirm" || 
      urlParams.id == "resetPass" ||
      urlParams.id == "cancel-email" ||
      urlParams.id == "content" ||
      urlParams.id == "email_link_click"
    ) {
      this._isDeeplink = true;
    } else {
      this._isDeeplink = false;
    }
    return this._isDeeplink;
  }

  private getUrlVars(url) {
    let vars = {};
    let parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value;
    });
    return vars;
  }
}
