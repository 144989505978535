import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { SocialLoginService } from '../../services/social-login.service';
import { AppleSigninService } from '../../services/apple-signin.service';
import { InterfaceTextService } from '../../../../utils/interface-text.service';

@Component({
  selector: 'app-change-method',
  templateUrl: './change-method.page.html',
  styleUrls: ['./change-method.page.scss'],
})
export class ChangeMethodPage implements OnInit {
  @Input() options: any;
  method: any;
  originalLangName: any = null;
  bodyText: any = { "message": [], "change_lang_message": [] };

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private socialLogin: SocialLoginService,
    private appleSigninService: AppleSigninService,
    private interfaceText: InterfaceTextService
  ) { }

  ngOnInit() {
    this.renderText();
    if(this.options.change_language) {
      this.originalLangName = this.interfaceText.getLanguageName(this.options.language);
      console.log(this.originalLangName)
    } else {
      if (this.options.code == '1') {
        this.method = this.bodyText['googleLogin'];
      } else if (this.options.code == '2') {
        this.method = this.bodyText['facebookLogin'];
      } else if (this.options.code == '3') {
        this.method = this.bodyText['appleLogin'];
      } else {
        this.method = this.bodyText['userEmail'];
      }
    }
   
  }
  ionViewWillEnter() {
    this.renderText();
  }
  renderText() {
    let screen_name = "change_method_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }
  async onContinue(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.modalCtrl.dismiss(this.options);

    if(this.options.change_language) {
      await this.interfaceText.langSelectionLaunched(false);
      this.navCtrl.navigateRoot(["authentication"]);
    } else {
      if (this.options.code == '1') {
        this.socialLogin.signOut(this.options.attempt);
        this.socialLogin.initGoogleSignIn();
      } else if (this.options.code == '2') {
        this.socialLogin.signOut(this.options.attempt);
        this.socialLogin.initFacebookSignIn();
      } else if (this.options.code == '3') {
        this.appleSigninService.init();
        this.socialLogin.signOut(this.options.attempt);
      } else {
        this.socialLogin.signOut(this.options.attempt);
        this.navCtrl.navigateForward(["authentication/email-login", { email: this.options.email, name: this.options.name }]);
      }
    }
  }
}
