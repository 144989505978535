import { NgModule, Injectable } from '@angular/core';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule  } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Media } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Deeplinks  } from '@ionic-native/deeplinks/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { Device } from '@ionic-native/device/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Market } from '@ionic-native/market/ngx';
import * as Hammer from 'hammerjs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { LoginAlertPage } from './popups/login-alert/login-alert.page';
import { LoginAlertPageModule } from './popups/login-alert/login-alert.module';

import { VerifyAlertPage } from './popups/verify-alert/verify-alert.page';
import { VerifyAlertPageModule } from './popups/verify-alert/verify-alert.module';

import { MultiDeviceAlertPage } from './popups/multi-device-alert/multi-device-alert.page';
import { MultiDeviceAlertPageModule } from './popups/multi-device-alert/multi-device-alert.module';

import { ResetPasswordFormPageModule } from './popups/reset-password-form/reset-password-form.module';
import { ResetPasswordFormPage } from './popups/reset-password-form/reset-password-form.page';

import { CancelReceiveMailPopupPageModule } from './popups/cancel-receive-mail-popup/cancel-receive-mail-popup.module';
import { CancelReceiveMailPopupPage } from './popups/cancel-receive-mail-popup/cancel-receive-mail-popup.page';

import { GrammarPage } from './pages/grammar/grammar.page';
import { GrammarPageModule } from './pages/grammar/grammar.module';

import { InAppPopupPageModule } from './popups/in-app-popup/in-app-popup.module';
import { InAppPopupPage } from './popups/in-app-popup/in-app-popup.page';

import { TrialPopupPage } from './popups/trial-popup/trial-popup.page';
import { TrialPopupPageModule } from './popups/trial-popup/trial-popup.module';

import { AppRatePopupPage } from './popups/app-rate-popup/app-rate-popup.page';
import { AppRatePopupPageModule } from './popups/app-rate-popup/app-rate-popup.module';


import { PlanListPage } from './popups/plan-list/plan-list.page';
import { PlanListPageModule } from './popups/plan-list/plan-list.module';

import { AppUpdatePopupPage } from './popups/app-update-popup/app-update-popup.page';
import { AppUpdatePopupPageModule } from './popups/app-update-popup/app-update-popup.module';

import { AppDebugModePage } from './popups/app-debug-mode/app-debug-mode.page';
import { AppDebugModePageModule } from './popups/app-debug-mode/app-debug-mode.module';

import { DeleteAccountConfirmPage } from './popups/delete-account-confirm/delete-account-confirm.page';
import { DeleteAccountConfirmPageModule } from './popups/delete-account-confirm/delete-account-confirm.module';


import { CampaignsService } from './utils/campaigns.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

let socialLoginConfig = {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.googleLoginProvider)
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.facebookLoginProvider)
      }
    ]
};
// export function provideSocialLoginConfig() {
//   return socialLoginConfig;
// }

// @Injectable()

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [TrialPopupPage, LoginAlertPage, VerifyAlertPage, MultiDeviceAlertPage, ResetPasswordFormPage, CancelReceiveMailPopupPage, GrammarPage, InAppPopupPage, AppRatePopupPage, PlanListPage, AppUpdatePopupPage, AppDebugModePage, DeleteAccountConfirmPage],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      scrollAssist: true
    }),
    IonicStorageModule.forRoot({
      driverOrder: ['indexeddb', 'websql', 'sqlite']
    }), 
    AppRoutingModule, 
    HttpClientModule, 
    SocialLoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    LoginAlertPageModule,
    VerifyAlertPageModule,
    MultiDeviceAlertPageModule,
    ResetPasswordFormPageModule,
    CancelReceiveMailPopupPageModule,
    GrammarPageModule,
    InAppPopupPageModule,
    TrialPopupPageModule,
    AppRatePopupPageModule,
    PlanListPageModule,
    AppUpdatePopupPageModule,
    AppDebugModePageModule,
    DeleteAccountConfirmPageModule,
    HammerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NavigationBar,
    Network,
    Media,
    File,
    Facebook,
    GooglePlus,
    ScreenOrientation,
    Camera,
    FirebaseX,
    Deeplinks,
    MobileAccessibility,
    InAppPurchase2,
    FileOpener,
    AppVersion,
    Keyboard,
    InAppBrowser,
    SocialSharing,
    LaunchReview,
    Device,
    CampaignsService,
    LocalNotifications,
    SpeechRecognition,
    TextToSpeech,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'SocialAuthServiceConfig', useValue: socialLoginConfig as SocialAuthServiceConfig },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig, },
    Market
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
