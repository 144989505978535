import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { WebserviceService } from './webservice.service';
import { ZipService } from './zip.service';
import { AppStorageService } from './app-storage.service';
import { NetworkService } from './network.service';
import { environment } from '../../environments/environment'

var noDict = {}; //nodict is an id dictionary to provide direct access to the nodes by id
var curNode;
var lastID: any = 0;
const ANYVALUE = "ANYVALUE";
const BASE = "BASE";
const PROGRESS = "PROGRESS";
const storageType = "object"; // options: object, localstorage 

@Injectable({
  providedIn: 'root'
})
export class DevDataService {
  devDataStorage: any = {};
  storageType: any = storageType;
  isReady: any;

  //Converts a number of seconds to hours, minutes and seconds
  secs2duration(secs) {
    let hours = Math.floor(secs / 3600);
    let remain = secs % 3600;
    let minutes = Math.floor(remain / 60);
    let seconds = remain % 60;
    return { "hours": hours, "minutes": minutes, "seconds": seconds }
  }

  //Creates a UTC timestamp of the very moment of the call
  nowTimestamp() {
    let ts = new Date();
    let month = ("0" + (ts.getUTCMonth() + 1)).slice(-2);
    let day = ("0" + ts.getUTCDate()).slice(-2);
    let hour = ("0" + ts.getUTCHours()).slice(-2);
    let minutes = ("0" + ts.getUTCMinutes()).slice(-2);
    let seconds = ("0" + ts.getUTCSeconds()).slice(-2);
    return `${ts.getUTCFullYear()}${month}${day}${hour}${minutes}.${seconds}`;
  }

  //sets the progress attribute in the grammar node and
  //updates the ancestor containers' progress accordingly
  setVideoPlaytime(nodeId, secs, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    if (node.subtype !== 'video') return false;
    if (secs == 0 || secs > node.progress.duration) return false;  // Maybe in a resetVideoPlaytime()
    let diff = secs - node.progress.played;
    if (diff <= 0) return false;
    this.setAttribute('played', secs, PROGRESS, true);
    this.setAttribute('ts', ts, PROGRESS, true);

    while (node.fatherid !== 0) {
      node = this.getNode(node.fatherid);
      this.setAttribute('vidsplayedsecs', node.progress.vidsplayedsecs + diff, PROGRESS, true);
    }
    return true;
  }


  //Change a video progress to played = 0
  //This function makes all the lineaje adjustments needed
  resetVideo(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    if (node.subtype !== 'video') return false;
    let secs = node.progress.played;
    if (secs == 0) return true;
    this.setAttribute('played', 0, PROGRESS, true);
    this.setAttribute('ts', ts, PROGRESS, true);

    while (node.fatherid !== 0) {
      node = this.getNode(node.fatherid);
      this.setAttribute('vidsplayedsecs', node.progress.vidsplayedsecs - secs, PROGRESS, true);
    }
    return true;
  }

  //Change a grammar lesson progress to not passed
  //This function makes all the lineaje adjustments needed
  resetGrammar(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    let propagate = false; //propagate progress to ancestor lineage
    if (node.subtype !== 'grammar') return false;
    if ("passed" in node.progress) {
      if (node.progress.passed) {
        delete node.progress.passed;
        this.setAttribute('ts', ts, PROGRESS, true); //Only if passed was true...
        let tmp = JSON.stringify(node);
        if (storageType == "object") {
          this.devDataStorage[nodeId] = tmp;
        } else {
          localStorage.setItem(nodeId, tmp);
        }
        propagate = true;
      }
    }
    if (propagate) { //false if the exercise was already passed:true
      while (node.fatherid !== 0) {
        node = this.getNode(node.fatherid);
        this.setAttribute('grammarspassed', node.progress.grammarspassed - 1, PROGRESS, true);
      }
    }
    return true;
  }


  //There is an option to reset all the exercises in a test
  //This function makes all the lineaje adjustments needed
  resetExercise(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    let propagate = false; //propagate progress to ancestor lineage
    if (node.subtype !== 'exercise') return false;
    if ("passed" in node.progress) {
      let tmpPassed = node.progress.passed;
      delete node.progress.passed;
      let tmp = JSON.stringify(node);
      if (storageType == "object") {
        this.devDataStorage[nodeId] = tmp;
      } else {
        localStorage.setItem(nodeId, tmp);
      }
      this.setAttribute('ts', ts, PROGRESS, true); //Only if passed was true...
      if(tmpPassed) propagate = true;
    }
    if (propagate) { //false if the exercise was already passed:true
      while (node.fatherid !== 0) {
        node = this.getNode(node.fatherid);
        this.setAttribute('numpassed', node.progress.numpassed - 1, PROGRESS, true);
      }
    }
    return true;
  }


  //You cannot fail an exercise that was previously passed.
  //An attempt to do so will produce no effect. There is nothing
  //To propagate in any case. Just reflect the fact for later filtering
  //and-or accounting and eventually (in later versions) take further actions
  //related to the "review" functionality.
  failExercise(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    if (node.subtype !== 'exercise') return false;
    if ("passed" in node.progress) {
      if (node.progress.passed) return false;
      this.setAttribute('passed', false, PROGRESS, true);
      this.setAttribute('ts', ts, PROGRESS, true);
    } else { //
      this.setAttribute('passed', false, PROGRESS, true);
      this.setAttribute('ts', ts, PROGRESS, true);
    }
    return true;
  }


  //A BASE attribute will mark skipped exercises. Once the exercise
  //is passed or failed, the attribute is removed (if present)
  /*skipExercise(nodeId) {
    let node = this.getNode(nodeId);
    let propagate = false; //propagate progress to ancestor lineage
    if (node.subtype !== 'exercise') return false;
    this.setAttribute('skipped', true, BASE, true);
    return true;
  }*/
  skipExercise(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    if (node.subtype !== 'exercise') return false;
    if ("passed" in node.progress) {
      if (node.progress.passed) return false;
      this.setAttribute('passed', null, PROGRESS, true);
      this.setAttribute('ts', ts, PROGRESS, true);
    } else { //
      this.setAttribute('passed', null, PROGRESS, true);
      this.setAttribute('ts', ts, PROGRESS, true);
    }
    return true;
  }



  //sets the progress attribute in the exercise node and
  //updates the ancestor containers' progress accordingly
  passExercise(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    let propagate = false; //propagate progress to ancestor lineage
    if (node.subtype !== 'exercise') return false;
    if ("passed" in node.progress) {
      if (!node.progress.passed) { //The value was false or null. Propagate
        this.setAttribute('passed', true, PROGRESS, true);
        this.setAttribute('ts', ts, PROGRESS, true);
        propagate = true;
      }
    } else { //There was no value. Propagate
      this.setAttribute('passed', true, PROGRESS, true);
      this.setAttribute('ts', ts, PROGRESS, true);
      propagate = true;
    }
    if (propagate) { //false if the exercise was already passed:true
      while (node.fatherid !== 0) {
        node = this.getNode(node.fatherid);
        this.setAttribute('numpassed', node.progress.numpassed + 1, PROGRESS, true);
      }
    }
    return true;
  }


  //sets the progress attribute in the grammar node and
  //updates the ancestor containers' progress accordingly
  passGrammar(nodeId, ts = this.nowTimestamp()) {
    let node = this.getNode(nodeId);
    let propagate = false; //propagate progress to ancestor lineage
    if (node.subtype !== 'grammar') return false;
    if ("passed" in node.progress) {
      if (!node.progress.passed) { //The value was false. Propagate
        this.setAttribute('passed', true, PROGRESS, true);
        this.setAttribute('ts', ts, PROGRESS, true);
        propagate = true;
      }
    } else { //There was no value. Propagate
      this.setAttribute('passed', true, PROGRESS, true);
      this.setAttribute('ts', ts, PROGRESS, true);
      propagate = true;
    }
    if (propagate) { //false if the grammar was already passed:true
      while (node.fatherid !== 0) {
        node = this.getNode(node.fatherid);
        this.setAttribute('grammarspassed', node.progress.grammarspassed + 1, PROGRESS, true);
      }
    }
    return true;
  }


  getFilteredList(nodeId, includes, excludes?) {
    let self = this;
    var tmp = [];
    let count = 0;
    function filter(nodeId, includes, excludes) {
      count++;
      let node = self.getNode(nodeId);
      if (node.type == "generic") {
        node.content.forEach(function (elem) {
          filter(elem.id, includes, excludes);
        });
      }
      let addThisNode = false;
      includes.forEach(function (include) {
        if (include[1] == ANYVALUE) {
          if ((include[0] in node) || (include[0] in node.progress)) addThisNode = true;
        } else if ((node[include[0]] === include[1]) || (node['progress'][include[0]] === include[1])) {
          addThisNode = true;
        }
      });
      if (excludes) {
        excludes.forEach(function (exclude) {
          if (exclude[1] == ANYVALUE) {
            if ((exclude[0] in node) || (exclude[0] in node.progress)) addThisNode = false;
          } else if ((node[exclude[0]] === exclude[1]) || (node['progress'][exclude[0]] === exclude[1])) {
            addThisNode = false;
          }
        });
      }
      if (addThisNode) tmp.push(nodeId);
    }
    filter(nodeId, includes, excludes);
    return tmp;
  }


  //Non recursive classification of exercises within a lesson
  //by skill, with subtotals and list of pending exes per skill as well
  //This is specifically crafted to speed up the lesson menu of EMO2
  //Grouping in a single non-recursive call what otherwise requires
  //a bunch of recursive ones
  lessonExesClassify(lessonId) {
    var seed = this.getNode(lessonId);
    if (!(seed.subtype == "lesson" || seed.subtype == "test")) return false;
    var resp = { skipped: [], pending: [], incorrect: [], total: 0 }, id = 0;
    for (let i = 0; i < seed.content.length; i++) {
      if (seed.content[i].subtype == "exercises") { id = seed.content[i].id; break; }
    }

    if (id) {
      let container = this.getNode(id);
      container.content.forEach((exe) => {
        if (!(exe.skill in resp)) resp[exe.skill] = { "passed": 0, "total": 0, "pending": [], "failed": 0, "skipped": 0 };
        resp[exe.skill]['total']++;
        resp['total']++;
        if (exe.progress.passed === false) { resp[exe.skill]['failed']++; resp[exe.skill]["pending"].push(exe.id); resp["pending"].push(exe.id); resp["incorrect"].push(exe.id); }
        if (exe.progress.passed) resp[exe.skill]["passed"]++;
        if (exe.progress.passed === undefined) { resp[exe.skill]["pending"].push(exe.id); resp["pending"].push(exe.id); }
        if (exe.progress.passed === null) {
          resp[exe.skill]["skipped"]++;
          resp[exe.skill]["pending"].push(exe.id);
          resp["pending"].push(exe.id);
          resp["skipped"].push(exe.id);
        }
      });
    }
    return resp;
  }


  getNode(id) {
    var item;
    var self = this;
    noDict = {}; //This dictionary will be the cache, holding the nodes depending on their intended life span
    if (storageType == "object") {
      item = this.devDataStorage[id];
    } else {
      item = localStorage.getItem(id);
    }
    
    try {
      noDict[id] = JSON.parse(item);
    } catch(e) {
      if(!item) return;
    }
    
    if (noDict[id].type == 'generic') { //Bring the kids
      noDict[id].listids.forEach(function (child) {
        let tmp: any;
        if (storageType == "object") {
          tmp = self.devDataStorage[child];
        } else {
          tmp = localStorage.getItem(child);
        }
        noDict[id].content.push(JSON.parse(tmp)); //listids inside?
      });
      delete noDict[id].listids;
    }
    curNode = id;
    return noDict[id];

  }

  removeAttribute(attr, type, persist) {
    switch (type) {
      case BASE: //Base or non-progress, non-content attributes
        if (attr in noDict[curNode]) {
          delete noDict[curNode][attr];
        }
        break;
      case PROGRESS:
        if (attr in noDict[curNode]['progress']) {
          delete noDict[curNode]['progress'][attr];
        }
        break;
    }
    if (persist) {
      let tmp: any;
      if (storageType == "object") {
        tmp = this.devDataStorage[curNode];
      } else {
        tmp = localStorage.getItem(curNode);
      }
      tmp = JSON.parse(tmp);
      switch (type) {
        case BASE: //Base or non-progress, non-content attributes
          if (attr in tmp) {
            delete tmp[attr];
          }
          break;
        case PROGRESS:
          if (attr in noDict[curNode]['progress']) {
            delete tmp['progress'][attr];
          }
          break;
      }
      tmp = JSON.stringify(tmp);
      if (storageType == "object") {
        this.devDataStorage[curNode] = tmp;
      } else {
        localStorage.setItem(curNode, tmp);
      }
    }
  }

  //sets the atribute of the current node to value with a persist option
  setAttribute(attr, value, type, persist) {
    switch (type) {
      case BASE: //Base or non-progress, non-content attributes
        noDict[curNode][attr] = value;
        break;
      case PROGRESS:
        noDict[curNode]['progress'][attr] = value;
        break;
    }
    if (persist) {
      let tmp: any;
      if (storageType == "object") {
        tmp = this.devDataStorage[curNode];
      } else {
        tmp = localStorage.getItem(curNode);
      }
      tmp = JSON.parse(tmp);
      switch (type) {
        case BASE: //Base or non-progress, non-content attributes
          tmp[attr] = value;;
          break;
        case PROGRESS:
          tmp['progress'][attr] = value;
          break;
      }
      tmp = JSON.stringify(tmp);
      if (storageType == "object") {
        this.devDataStorage[curNode] = tmp;
      } else {
        localStorage.setItem(curNode, tmp);
      }
    }
  }


  bigAncestors(nodeId) {
    let node = this.getNode(nodeId);
    if (['video', 'grammar', 'exercise'].indexOf(node.subtype) == -1) return false;
    let ans = { lesson_test: null, level: null, };
    while (node.fatherid !== 0) {
      node = this.getNode(node.fatherid);
      if (node.subtype == 'lesson') ans.lesson_test = node.id;
      if (node.subtype == 'test') ans.lesson_test = node.id;
      if (node.subtype == 'level') ans.level = node.id;
    }
    return ans;
  }

  private course_ready = new Subject<any>();
  onReady = this.course_ready.asObservable();

  updateInitialContent: boolean = false;
  networkChangeListener: Subscription;
  constructor(
    private webservice: WebserviceService,
    private zipService: ZipService,
    private appStorageService: AppStorageService,
    private networkService: NetworkService
  ) {
    this.networkChangeListener = this.networkService.onNetworkChange.subscribe((re: any) => {
      if (!this.isReady && this.initialLoadingFailed && re.isOnline) {
        this.downloadContent();
      }
    });
  }


  private initialLoadingFailed:boolean = false;
  async loadCourse(update?: boolean, loadLocal?: boolean) {
    let updateVersion = localStorage.getItem("initialContentV") || "";
    updateVersion = updateVersion.indexOf('|') > -1 ? updateVersion.split('|')[0] : updateVersion;
    this.isReady = false;
    this.devDataStorage = null;
    this.devDataStorage = await this.appStorageService.getWithPromise("initialContent");
    let promise = new Promise((resolve, reject) => {
      try {
        if ((!this.devDataStorage || update) && !loadLocal) {
          this.devDataStorage = {};
          this.loadRemoteCourse(updateVersion).then((response:any)=>{
            this.unzipCourse(response.data).then((unzipRes:any)=>{
              if(unzipRes.ok) {
                this.createInitialContent(unzipRes.data).then(()=>{
                  this.appStorageService.setWithPromise("initialContent", this.devDataStorage).then(() => {
                    this.isReady = true;
                    resolve(null);
                  }).catch((e) => {
                    this.initialLoadingFailed = true;
                    reject();
                  });
                })
              } else {
                reject();
              }
            }).catch((err)=>{
              reject();
            })
          }).catch(()=>{
            this.loadLocalCourse().then(() => {
              this.isReady = true;
              resolve(null);
            }).catch(() => {
              this.initialLoadingFailed = true;
              reject();
            });
          })
        } else if (loadLocal) {
          this.loadLocalCourse().then(() => {
            this.isReady = true;
            resolve(null);
          }).catch(() => {
            this.initialLoadingFailed = true;
            reject();
          });
        } else {
          this.isReady = true;
          resolve(null);
        }
      } catch (e) {
        localStorage.setItem("initialContentV", "");
        this.loadLocalCourse().then(() => {
          this.isReady = true;
          resolve(null);
        }).catch(() => {
          this.initialLoadingFailed = true;
          reject();
        });
      }
    });
    return promise;
  }
  


  createInitialContent(data:any) {
    return new Promise((resolve, reject) => {
      let reader: FileReader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result == 'string') {
          let content = reader.result.split('\n');
          let count = 0;
          for (let i = 0; i < content.length; i++) {
            if (content[i].trim() != '') {
              this.devDataStorage[count] = content[i];
              count++;
            }
          }
          resolve({ok:true});
        } else {
          reject({ok:false});
        }
      }
      reader.onerror = ()=>{
        reject({ok:false});
      }
      reader.readAsText(data);
    });
  }

  unzipCourse(data:Blob) {
    return new Promise((resolve, reject) => {
      let unzip_timeout:any, getEntryObservable:Subscription, getDataObservable:Subscription;
      getEntryObservable = this.zipService.getEntries(data).subscribe((entry: any) => {
        getDataObservable = this.zipService.getData(entry[0]).data.subscribe((data: any) => {
          clearTimeout(unzip_timeout);
          resolve({ok: true, data: data});
        });
      });
      unzip_timeout = setTimeout(()=>{
        if(getEntryObservable) getEntryObservable.unsubscribe();
        if(getDataObservable) getDataObservable.unsubscribe();
        reject({ok: false});
      }, 60000);
    });
  }

  async loadRemoteCourse(version:any) {
    return new Promise((resolve, reject) => {
      let env = environment.production ? '' : '.dev';
      this.webservice.get(environment.contentTreePath + environment.language + '.' + version + env + ".zip", { responseType: 'blob', bucket: true }, response => {
        resolve({ok:true, data: response});
      }, error =>{
        reject({ok: false});
      });
    });
  }

  async loadLocalCourse() {
    return new Promise((resolve, reject) => {
      this.webservice.get("course/content.txt", { responseType: 'text/plain', local: true }, response => {
        this.devDataStorage = {};
        let content = response.split('\n');
        let count = 0;
        for (let i = 0; i < content.length; i++) {
          if (content[i].trim() != '') {
            this.devDataStorage[count] = content[i];
            count++;
          }
        }
        this.appStorageService.setWithPromise("initialContent", this.devDataStorage).then(() => {
          this.isReady = true;
          resolve({ok: true});
        }).catch((e) => {
          reject(e);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  loadLocalTree:boolean = false;
  downloadContent() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getRemoteContentVersion().catch(()=>{return false;}),
        this.getLocalContentVersion().catch(()=>{return false;})
      ]).then((versions) => {
        if(!versions[0]) {
          this.loadCourse(false, true).then(() => {
            this.course_ready.next();
            resolve({ok: true});
          });
        } else if(!versions[1] || versions[0] > versions[1]) {
          localStorage.setItem("initialContentV", versions[0].toString()+'|'+environment.language);
          this.loadCourse(true, this.loadLocalTree).then(() => {
            this.course_ready.next();
            resolve({ok: true});
          });
        } else {
          this.loadCourse(false, this.loadLocalTree).then(() => {
            this.course_ready.next();
            resolve({ok: true});
          });
        }
      }).catch((error) => {
        this.initialLoadingFailed = true;
        reject(error);
      });
    });

  }

  getRemoteContentVersion() {
    return new Promise((resolve, reject) => {
      let env = environment.production ? '' : '.dev';
      this.webservice.get(environment.contentVersionFile + environment.language + env + ".txt", { responseType: 'text', bucket: true }, response => {
        resolve(parseInt(response));
      }, error => {
        reject(error);
      });
    });
  }

  getLocalContentVersion() {
    return new Promise((resolve, reject) => {
      let v:any = localStorage.getItem("initialContentV");

      if(v) {
        if(v.indexOf('|') > -1) {
          let _v = v.split('|');
          if(_v[1] && _v[1] == environment.language) {
            resolve(parseInt(_v[0]));
          } else {
            reject(null);
          }
        } else {
          reject(null);
        }
      }
      else reject(v);
    });
  }

  

}
