import { Component, OnInit, HostListener } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { UserApiService } from './utils/user-api.service';
import { WebserviceService } from './utils/webservice.service';
// import { PurchaseService } from './utils/purchase.service';
import { SocialLoginService } from './pages/authentication/services/social-login.service';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { LoginAlertPage } from './popups/login-alert/login-alert.page';
import { VerifyAlertPage } from './popups/verify-alert/verify-alert.page';
import { CancelReceiveMailPopupPage } from './popups/cancel-receive-mail-popup/cancel-receive-mail-popup.page';
import { InterfaceTextService } from './utils/interface-text.service';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
// import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Subscription } from 'rxjs';
import { DevDataService } from './utils/dev-data.service';
import { MultiDeviceAlertPage } from './popups/multi-device-alert/multi-device-alert.page';
import { ResetPasswordFormPage } from './popups/reset-password-form/reset-password-form.page';
import { FirebaseEventsService } from './utils/firebase-events.service';
import { NetworkService } from './utils/network.service';
import { DeeplinkHandlerService } from './utils/deeplink-handler.service';

import { PreloadService } from './utils/preload.service';

import { myEnterNoAnimation } from './animations/no-anim-in';
import { myLeaveNoAnimation } from './animations/no-anim-out';

import { AppStorageService } from './utils/app-storage.service';
import { CampaignsService } from './utils/campaigns.service';

import { GrammarPage } from './pages/grammar/grammar.page';
import { InAppPopupPage } from './popups/in-app-popup/in-app-popup.page';

import { TrialPopupPage } from './popups/trial-popup/trial-popup.page';

import { AppRatePopupPage } from './popups/app-rate-popup/app-rate-popup.page';

import { PlanListPage } from './popups/plan-list/plan-list.page';
import { AppUpdatePopupPage } from './popups/app-update-popup/app-update-popup.page';
import { AppDebugModePage } from './popups/app-debug-mode/app-debug-mode.page';
import { TutorialService } from './utils/tutorial.service';
import { SharedDataService } from './utils/common/shared-data.service';
import { DeleteAccountConfirmPage } from './popups/delete-account-confirm/delete-account-confirm.page';
import { environment } from '../environments/environment';


// reviewed code 
import { EventManagerService } from './utils/common/event-manager.service';
//
declare let window: any; 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  registrationAlert: any;
  loginAlert: any;
  verificationAlert: any;
  // multiDeviceAlert: any;
  wsResponseListener: Subscription;
  veryfyPromptListener: Subscription;
  noEmailLoginListener: Subscription;
  deeplinkRouteListener: Subscription;
  inAppPopupEvent: Subscription;
  onTrialExpire: Subscription;
  trialStartEvent: Subscription;


  globalModalVisible: boolean = false;

  pauseTime: any;
  resumeTime: any;



  private eventListeners: any = {};

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navigationBar: NavigationBar,
    private screenOrientation: ScreenOrientation,
    private userApiService: UserApiService,
    private router: Router,
    // private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private interfaceTextService: InterfaceTextService,
    private mobileAccessibility: MobileAccessibility,
    // private deeplinks: Deeplinks,
    private devDataService: DevDataService,
    private webserviceService: WebserviceService,
    // private purchaseService: PurchaseService,
    private firebaseEvents: FirebaseEventsService,
    private socialLoginService: SocialLoginService,
    private networkService: NetworkService,
    private deeplinkHandler: DeeplinkHandlerService,
    private appStorageService: AppStorageService,

    private preloader: PreloadService,
    private campaignService: CampaignsService,
    private tutorial: TutorialService,
    public sharedDataService: SharedDataService,
    private eventManager: EventManagerService
  ) { }
  
  // debugClickCount = 0;
  // debugClickTimeout:any = null;
  // @HostListener('document:click', ['$event'])
  // onDebugInputClick(event: Event) {
  //   if(this.debugClickTimeout) clearTimeout(this.debugClickTimeout);
  //   this.debugClickTimeout = setTimeout(()=>{ this.debugClickCount = 0; },1000);
  //   this.debugClickCount++;
  //   if(this.debugClickCount == 8) {
  //     this.debugClickCount = 0;
  //     this.appDebugPopup();
  //   }
  // }
    // debugPlatformIOS:boolean = false;
    // debugText:string = "debug_off";
    // onDebugInputUpdate() {
    //   if(this.debugText.trim() == "") this.appDebugPopup();
    // }
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.key === "Tab") {
      event.preventDefault();
      // return false;
    }
  };
    debugEnablePressTimer: any = null;
    debugButtonLongPress(e:Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
      if(this.debugEnablePressTimer) clearTimeout(this.debugEnablePressTimer);
      this.debugEnablePressTimer = setTimeout(()=>{
        this.appDebugPopup();
      },5000);
    }

    debugButtonLongPressEnd(e:Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
      if(this.debugEnablePressTimer) clearTimeout(this.debugEnablePressTimer);
    }


  ngOnInit() {

    if (environment.production && !window.location.href.includes("mode=loud")) console.log = () => { };

    this.initializeApp();

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.sharedDataService.appData.currentPath = event.url;
      }
      if (event instanceof NavigationEnd) {
        let screenObj = {
          url: event.urlAfterRedirects
        }
        this.firebaseEvents.setScreen(screenObj);
        this.sharedDataService.appData.currentPath = event.urlAfterRedirects;
      }
    });
  }

  initializeApp() {

    this.platform.ready().then((re) => {
      if(this.platform.is("ipad") || this.platform.is('ios')) {
        this.askTrackingPermission();
        this.readTrackingPermission();
      }
      this.preloader.initialize().then(() => {
        this.userApiService.getUser().then((user: any) => {
          this.userApiService.setUser({ jwt: user.jwt }).then((re) => { });
          this.userApiService.forceSync = true;
          this.userApiService.syncCourse();
        }).catch(() => { });
      }).catch((e) => {
        console.log('not preloaded ', e);
      });

      if (this.platform.is("cordova")) {
        this.firebaseEvents.onMessageReceived();
        this.statusBar.hide();
        this.splashScreen.hide();
        // this.navigationBar.setUp(true);
        (window as any).navigationbar.setUp(true);
        this.mobileAccessibility.usePreferredTextZoom(false);

        // if (!(this.platform.is("ipad") || this.platform.is("tablet"))) this.screenOrientation.lock('portrait-primary');
        this.screenOrientation.lock('portrait-primary');

        this.platform.resume.subscribe((e) => {
          this.resumeTime = Math.round(new Date().getTime() / 1000);
          let pausedDuration = (this.resumeTime - this.pauseTime);
          if (this.pauseTime && pausedDuration > (60 * 60 * 4)) {

            this.userApiService.getUser().then(async (user: any) => {
              this.userApiService.setUser({ jwt: user.jwt }).then((re) => { });
              if (user && user.jwt && !user.active) {
                this.devDataService.loadCourse().then(() => {
                  this.navCtrl.navigateRoot(["tabs/tab1"]);
                });
              } else {
                if (!user || !user.jwt) {
                  this.navCtrl.navigateRoot(["authentication/"]);
                }
              }
            }).catch(() => {
              this.navCtrl.navigateRoot(["authentication/"]);
            });
          }
        });

        this.platform.pause.subscribe((e) => {
          this.pauseTime = Math.round(new Date().getTime() / 1000);
          this.userApiService.immediateSync = true;
          this.userApiService.syncCourse();
        });

      }

    });


    this.deeplinkHandler.handleRouts();
    if (this.deeplinkRouteListener) this.deeplinkRouteListener.unsubscribe();
    this.deeplinkRouteListener = this.deeplinkHandler.onDeeplinkResolve.subscribe((res: any) => {
      this.sharedDataService.appData.isDeeplink = true;
      if (res.id == "confirm") {
        if (!res.error) {
          // if (!this.platform.is("cordova")) this.navCtrl.navigateRoot(["tabs/tab1"]);
          // this.showEmailVarifiedNotification(false, res.data);
          if (res.data.isUnique) {
            this.firebaseEvents.logEvents("email_confirmed", res.data);
          }
        } else {
          if (!this.platform.is("cordova")) this.navCtrl.navigateRoot(["authentication/"]);
          this.showEmailVarifiedNotification(res.error);
        }
      } else if (res.id == "resetPass") {
        this.showResetPassForm(res.data);
        this.firebaseEvents.logEvents("reset_password_opened", res.data);
      } else if (res.id == "cancel-email") {
        this.cancelEmailNotificationPopup(res.data);
      } else if (res.id == "content") {
        if (!res.error) {
          if (res.data.path == 'tabs/tab1') {
            if (res.data.open_gl) {
              this.navCtrl.navigateRoot(["tabs/tab1"]);
              this.openGrammarLessonModal(res.data.node);
            } else {
              this.sharedDataService.appData.currentLesson = res.data.node;
              this.navCtrl.navigateRoot(["tabs/tab1/"]);
            }
          } else if (
            res.data.path.includes('/video') ||
            res.data.path.includes('/tabs/tab2') ||
            res.data.path.includes('/tabs/tab3') ||
            res.data.path.includes('/tabs/tab4')
          ) {
            this.navCtrl.navigateRoot([res.data.path]);
          }
        } else { }
      } else if (res.id == "email_link_click") {
        if (res.path.includes('tabs/tab1') && res.data.node) {
          this.deeplinkHandler.canResume = false;
          this.sharedDataService.appData.currentLesson = res.data.node;
          this.navCtrl.navigateRoot(['tabs/tab1']);
        } else if (res.path == "") {
          this.navCtrl.navigateRoot(['tabs/tab1']);
        } else {
          this.navCtrl.navigateRoot([res.path]);
        }
        this.firebaseEvents.logEvents(res.id, res.data);
      } else if (res.id == "campaign_link") {
        if (!this.platform.is("cordova")) {
          this.campaignService.setCampaignTracker(res);
        }
      } else if(res.id == "immidiate_deletion"){
        this.navCtrl.navigateRoot(["tabs/tab4"]);
        this.openDeletionControlPage({delete: true});
      } else if(res.id == "cancel_deletion"){
        this.navCtrl.navigateRoot(["tabs/tab4"]);
        this.openDeletionControlPage({cancel_delete: true});
      } else {
        console.log("??")
      }
    });

    if (this.veryfyPromptListener) this.veryfyPromptListener.unsubscribe();
    this.veryfyPromptListener = this.userApiService.onVeryfyPrompt.subscribe((user: any) => {
      this.networkService.getNetworkStatus((re: any) => {
        if (!re.isOnline) return;
        if (!user) {
          if (this.router.url != "/" && !this.router.url.includes("/launch") && !this.router.url.includes("/authentication")) {
            this.showLoginPopup();
          }
        } else {
          this.showEmailVarificationPopup(user);
        }
      });
    });

    if (this.noEmailLoginListener) this.noEmailLoginListener.unsubscribe();
    this.noEmailLoginListener = this.socialLoginService.onNoEmailFacebook.subscribe((error) => {
      this.showLoginPopup(error);
    });

    if (this.wsResponseListener) this.wsResponseListener.unsubscribe();
    this.wsResponseListener = this.webserviceService.onWsResponse.subscribe((data: any) => {
      console.log("onWsResponse >> ", data);
      if (data.error == "device") {
        this.showMultiDeviceAlert();
      } else if (data.err == "expired") {
        this.userApiService.getUser().then((user: any) => {
          this.userApiService.resetJWT();
          this.openTrialPopup({}, user);
        }).catch(() => { });
      }
    });


    if (this.inAppPopupEvent) this.inAppPopupEvent.unsubscribe();
    this.inAppPopupEvent = this.firebaseEvents.onEmoInAppPopup.subscribe((popupData) => {
      if(this.sharedDataService.appData.appDebug && this.sharedDataService.appData.appDebug.disableInAppPopup) return;
      this.userApiService.getUser().then((user: any) => {
        if (popupData.event == "EVENT_can_show_trial") {
          popupData.trialStart = true;
          this.openTrialPopup(popupData, user);
        } else {
          this.showInAppPopup(popupData, user);
        }
      }).catch(() => {
        this.showInAppPopup(popupData);
      });
    });


    if (this.trialStartEvent) this.trialStartEvent.unsubscribe();
    this.trialStartEvent = this.userApiService.onTrialStart.subscribe(() => {
      if (this.verificationAlert) this.verificationAlert.dismiss();
    });

    if (this.eventListeners.appRatePromptListener) this.eventListeners.appRatePromptListener.unsubscribe();
    this.eventListeners.appRatePromptListener = this.eventManager.bind('rating.onAppRatePrompt').subscribe(async (promptOptn: any) => {
      await this.showAppRatePrompt(promptOptn);
    });

    if (this.eventListeners.requestPlanListListener) this.eventListeners.requestPlanListListener.unsubscribe();
    this.eventListeners.requestPlanListListener = this.eventManager.bind('purchase.requestPlanList').subscribe(async (purchaseButtonConfig: any) => {
      await this.showPlanList(purchaseButtonConfig);
    });

    if (this.eventListeners.versionListener) this.eventListeners.versionListener.unsubscribe();
    this.eventListeners.versionListener = this.eventManager.bind('version.updateAvailable').subscribe(async (info: any) => {
      this.appUpdatePopup(info);
    });
  }











  // popups

  async openDeletionControlPage(options:any) {
    this.sharedDataService.popups.deleteAccountConfirmAlert = await this.modalCtrl.create({
      component: DeleteAccountConfirmPage,
      cssClass: 'delete-account-popup-modal',
      componentProps: { options: options }
    });
    this.sharedDataService.popups.deleteAccountConfirmAlert.onDidDismiss().then((re: any) => {
      
    });
    await this.sharedDataService.popups.deleteAccountConfirmAlert.present();
  }

  async appDebugPopup() {
    if(this.sharedDataService.appData.appDebugPopup) return;
    this.sharedDataService.appData.appDebugPopup = await this.modalCtrl.create({
      component: AppDebugModePage,
      cssClass: 'app-update-popup'
    });

    this.sharedDataService.appData.appDebugPopup.onDidDismiss().then((re: any) => {
      this.sharedDataService.appData.appDebugPopup = null;
      // this.debugText = "debug_off";
    });
    await this.sharedDataService.appData.appDebugPopup.present();
    
  }

  async appUpdatePopup(info:any) {
    if(this.sharedDataService.appData.appUpdatePopup) return;
    this.sharedDataService.appData.appUpdatePopup = await this.modalCtrl.create({
      component: AppUpdatePopupPage,
      cssClass: 'app-update-popup',
      componentProps: {
        options: info
      }
    });

    this.sharedDataService.appData.appUpdatePopup.onDidDismiss().then((re: any) => {
      this.sharedDataService.appData.appUpdatePopup = null;
    });
    await this.sharedDataService.appData.appUpdatePopup.present();
  }


  async showPlanList(purchaseButtonConfig) {
    if (this.sharedDataService.appData.planListPopup) {
      return;
    }
    this.sharedDataService.appData.PopupPrinted = true;
    this.sharedDataService.appData.planListPopup = true;
    this.sharedDataService.appData.planListPopup = await this.modalCtrl.create({
      component: PlanListPage,
      cssClass: 'plan-list-popup',
      componentProps: {
        options: purchaseButtonConfig
      }
    });
    this.sharedDataService.appData.planListPopup.onDidDismiss().then((re: any) => {
      this.sharedDataService.appData.planListPopup = null;
      this.sharedDataService.appData.PopupPrinted = false;
    });
    await this.sharedDataService.appData.planListPopup.present();
  }

  async showAppRatePrompt(promptOptn: any) {
    if (this.sharedDataService.appData.appRatePopup) {
      return;
    }
    this.sharedDataService.appData.PopupPrinted = true;
    this.sharedDataService.appData.appRatePopup = true;
    this.sharedDataService.appData.appRatePopup = await this.modalCtrl.create({
      component: AppRatePopupPage,
      cssClass: 'app-rate-popup',
      componentProps: {
        options: promptOptn
      }
    });
    this.sharedDataService.appData.appRatePopup.onDidDismiss().then((re: any) => {
      this.sharedDataService.appData.appRatePopup = null;
      this.sharedDataService.appData.PopupPrinted = false;
    });
    await this.sharedDataService.appData.appRatePopup.present();
  }

  cancelEmailPopup: any = null;
  async cancelEmailNotificationPopup(options: any) {
    if (this.cancelEmailPopup) {
      this.cancelEmailPopup.dismiss();
      this.sharedDataService.appData.PopupPrinted = false;
    }

    this.sharedDataService.appData.PopupPrinted = true;
    this.cancelEmailPopup = await this.modalCtrl.create({
      component: CancelReceiveMailPopupPage,
      cssClass: 'reset-pass-form',
      componentProps: {
        enterAnimation: myEnterNoAnimation,
        leaveAnimation: myLeaveNoAnimation,
        options: options
      }
    });
    this.cancelEmailPopup.onDidDismiss().then((re: any) => {
      this.cancelEmailPopup = null;
      this.sharedDataService.appData.PopupPrinted = false;
    });
    await this.cancelEmailPopup.present();
  }




  inAppPopup: any;

  async openTrialPopup(popupData: any, user: any) {
    if (user.premium) return;
    this.appStorageService.canShowInAppPopup(popupData, user).then(async (canShow) => {
      // if(!canShow) return;
      if (this.globalModalVisible) return;
      this.globalModalVisible = true;
      if (this.inAppPopup) {
        this.inAppPopup.dismiss();
        this.sharedDataService.appData.PopupPrinted = false;
      }


      this.sharedDataService.appData.PopupPrinted = true;
      this.inAppPopup = await this.modalCtrl.create({
        component: TrialPopupPage,
        cssClass: 'free-trial-alert',
        componentProps: {
          options: popupData
        }
      });

      this.inAppPopup.onDidDismiss().then((re: any) => {
        let campaign_data = {
          id: popupData.id,
          node: popupData.node,
          action: re.data ? re.data.action : false,
          recorder: popupData.recorder,
          email: user ? user.email ? user.email : false : false,
          jwt: re.data.jwt ? re.data.jwt : user ? user.jwt ? user.jwt : false : false
        }
        this.firebaseEvents.onInappPopupClose(campaign_data);
        this.inAppPopup = null;
        this.sharedDataService.appData.PopupPrinted = false;
        this.globalModalVisible = false;


        // this.tutorial.triggerTutorialPopup();

      });

      await this.inAppPopup.present();
      this.globalModalVisible = true;
    });


  }

  async showInAppPopup(popupData: any, user?: any) {
    if (this.globalModalVisible) return;
    this.globalModalVisible = true;
    if (this.inAppPopup) {
      this.inAppPopup.dismiss();
      this.sharedDataService.appData.PopupPrinted = false;
    }

    let targetUser = [];
    if (user && user.premium) {
      targetUser.push("premium");
    } else if (user && user.email) {
      targetUser.push("free");
      //if(user.active) targetUser.push("active");
    } else {
      targetUser.push("non_user");
    }

    if (popupData.target.indexOf("all") > -1 || popupData.target.indexOf(targetUser[0]) > -1) {
      this.appStorageService.canShowInAppPopup(popupData, user).then(async (canShow) => {
        if (!canShow) {
          this.globalModalVisible = false;
          return;
        }

        this.sharedDataService.appData.PopupPrinted = true;
        this.inAppPopup = await this.modalCtrl.create({
          component: InAppPopupPage,
          cssClass: 'in-app-popup-modal',
          componentProps: {
            options: popupData
          }
        });
        this.inAppPopup.onDidDismiss().then((re: any) => {
          let campaign_data = {
            id: popupData.id,
            node: popupData.node,
            action: re.data ? re.data.action : false,
            recorder: popupData.recorder,
            email: user ? user.email ? user.email : false : false,
            jwt: user ? user.jwt ? user.jwt : false : false,
          }
          this.firebaseEvents.onInappPopupClose(campaign_data);
          this.inAppPopup = null;
          this.sharedDataService.appData.PopupPrinted = false;
          this.globalModalVisible = false;
        });
        await this.inAppPopup.present();
        this.globalModalVisible = true;
      });
    } else {
      console.log("skip popup");
      this.globalModalVisible = false;
    }

  }


  async showLoginPopup(error?: any) {
    if (this.globalModalVisible) return;
    this.globalModalVisible = true;
    if (this.loginAlert) {
      this.loginAlert.dismiss();
      this.sharedDataService.appData.PopupPrinted = false;
    }
    this.sharedDataService.appData.PopupPrinted = true;
    this.loginAlert = await this.modalCtrl.create({
      component: LoginAlertPage,
      cssClass: 'login-popup-modal',
      componentProps: {
        options: error
      }
    });
    this.loginAlert.onDidDismiss().then((re: any) => {
      this.loginAlert = null;
      this.sharedDataService.appData.PopupPrinted = false;
      this.globalModalVisible = false;
    });
    await this.loginAlert.present();
    this.globalModalVisible = true;
  }


  async showEmailVarificationPopup(user: any) {
    if (user.active && this.verificationAlert) {
      this.verificationAlert.dismiss();
      this.sharedDataService.appData.PopupPrinted = false;
      this.globalModalVisible = false;
    }

    if (this.globalModalVisible) return;
    this.globalModalVisible = true;
    let options = {
      user: user
    };
    this.sharedDataService.appData.PopupPrinted = true;
    this.verificationAlert = await this.modalCtrl.create({
      component: VerifyAlertPage,
      cssClass: 'verify-popup-modal',
      componentProps: {
        options: options
      }
    });
    this.verificationAlert.onDidDismiss().then((re: any) => {
      this.verificationAlert = null;
      this.sharedDataService.appData.PopupPrinted = false;
      this.globalModalVisible = false;
    });
    await this.verificationAlert.present();
    this.globalModalVisible = true;
  }

  async trialStartedPopup() {
    this.sharedDataService.appData.PopupPrinted = true;
    const modal = await this.modalCtrl.create({
      component: TrialPopupPage,
      cssClass: 'free-trial-alert',
      componentProps: {
        options: {
          trialStart: true,
          onCancelClick: () => {
            modal.dismiss();
            this.sharedDataService.appData.PopupPrinted = false;
          }
        }
      }
    });

    modal.onDidDismiss().then((re: any) => {
      this.tutorial.triggerTutorialPopup();
      this.sharedDataService.appData.PopupPrinted = false;
    });
    await modal.present();
  }



  async showEmailVarifiedNotification(err: any, user?: any) {

    this.globalModalVisible = true;
    if (this.verificationAlert) {
      this.verificationAlert.dismiss();
      this.sharedDataService.appData.PopupPrinted = false;
    }
    let options = {
      user: user,
      error: err,
      confirmation: true
    };
    this.sharedDataService.appData.PopupPrinted = true;
    this.verificationAlert = await this.modalCtrl.create({
      component: VerifyAlertPage,
      cssClass: 'verify-popup-modal',
      componentProps: {
        options: options
      }
    });
    this.verificationAlert.onDidDismiss().then((re: any) => {
      this.verificationAlert = null;
      this.sharedDataService.appData.PopupPrinted = false;
      this.globalModalVisible = false;

      // this.userApiService.startTrialPeriod().then((user)=>{
      //   this.trialStartedPopup();
      // }).catch((err)=>{console.log(err);});

      // this.userApiService.getUser().then((user: any) => {
      //   // if (!user.premium && user.active && !user.trial_expiry_time) {
      //   //   let _param: any = { node: 0 };
      //   //   this.firebaseEvents.logEvents("EVENT_can_show_trial", _param);
      //   // }
      //   this.trialStartedPopup();
      //   this.tutorial.triggerTutorialPopup();
      // }).catch(()=>{});

    });
    await this.verificationAlert.present();

    this.globalModalVisible = true;
  }


  async showMultiDeviceAlert() {
    if (this.globalModalVisible) return;
    this.globalModalVisible = true;
    if (this.sharedDataService.appData.multiDeviceAlert) {
      this.sharedDataService.appData.multiDeviceAlert.dismiss();
      this.sharedDataService.appData.PopupPrinted = false;
    }
    let options = {};
    this.sharedDataService.appData.PopupPrinted = true;
    this.sharedDataService.appData.multiDeviceAlert = await this.modalCtrl.create({
      component: MultiDeviceAlertPage,
      cssClass: 'multi-device-alert',
      componentProps: {
        options: options
      }
    });
    this.sharedDataService.appData.multiDeviceAlert.onDidDismiss().then((re: any) => {
      this.sharedDataService.appData.multiDeviceAlert = null;
      this.sharedDataService.appData.PopupPrinted = false;
      this.globalModalVisible = false;
    });
    await this.sharedDataService.appData.multiDeviceAlert.present();

  }


  resetPassModal: any = null;
  async showResetPassForm(options) {
    this.sharedDataService.appData.PopupPrinted = true;
    this.resetPassModal = await this.modalCtrl.create({
      component: ResetPasswordFormPage,
      cssClass: 'reset-pass-form',
      enterAnimation: myEnterNoAnimation,
      leaveAnimation: myLeaveNoAnimation,
      componentProps: {
        options: options
      }
    });
    this.resetPassModal.onDidDismiss().then((re: any) => {
      this.resetPassModal = null;
      this.sharedDataService.appData.PopupPrinted = false;
    });
    await this.resetPassModal.present();
  }


  async openGrammarLessonModal(gl_nodes) {

    let glArr = gl_nodes.split(",");
    let options = {
      glIds: glArr,
      initialIndex: 0,
      origin: 'deep_link'
    };
    this.sharedDataService.appData.PopupPrinted = true;
    const glmodal = await this.modalCtrl.create({
      component: GrammarPage,
      cssClass: 'grammar-lesson-modal',
      componentProps: {
        options: options
      }
    });
    glmodal.onDidDismiss().then((re: any) => {
      this.sharedDataService.appData.PopupPrinted = false;
    });
    await glmodal.present();

  }


  askTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {

      if (window.cordova) {
        console.log('trying to request permission ');
        window.cordova.exec(win, fail, 'idfa', "requestPermission", []);
      }
    }

    function win(res) {
      console.log('success ' + JSON.stringify(res));
    }
    function fail(res) {
      console.log('fail ' + JSON.stringify(res));
    }
  }

  readTrackingPermission() {

    if (this.platform.is('cordova') && this.platform.is('ios')) {

      if (window.cordova) {
        window.cordova.exec(win, fail, 'idfa', "getInfo", []);
      }
    }

    function win(res) {
      console.log('success  ' + JSON.stringify(res));
    }
    function fail(res) {
      console.log('fail ' + JSON.stringify(res));
    }
  }
}
