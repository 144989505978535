import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {
  constructor() { }

  private _evt:any = {};
  events:any = {};

  bind(e) {
    if(!this._evt[e]) this._evt[e] = new Subject<any>();
    if(!this.events[e]) this.events[e] = this._evt[e].asObservable();
    return this.events[e];
  }

  trigger(e:string, d?:any) {
    // console.log(d)
    this._evt[e] ? this._evt[e].next(d) : ()=>{};
  }
  
}
