import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { WebserviceService } from '../../utils/webservice.service';
import { InterfaceTextService } from '../../utils/interface-text.service';

@Component({
  selector: 'app-verify-alert',
  templateUrl: './verify-alert.page.html',
  styleUrls: ['./verify-alert.page.scss'],
})
export class VerifyAlertPage implements OnInit {
  @Input() options: any;
  userEmail:string;
  showLoader: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private webservice: WebserviceService,
    private interfaceText: InterfaceTextService
  ) { }
  errorMessage:any={
    1: "Ha habido un error. Por favor, inténtalo de nuevo.",
    2: "Hay un problema con el servidor. Por favor, inténtalo de nuevo en unos minutos.",
    3: "Debes iniciar una sesión para poder verificar tu cuenta.",
    4: "Debes iniciar una sesión con la misma cuenta que intentas verificar.",
    5: "Inicia sesión para verificar tu cuenta."
  }  
  ngOnInit() {
    this.userEmail = this.options.user ? this.options.user.email: false;
  }
  bodyText: any = {};
  renderText() {
    let screen_name = "verify_alert";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
    this.errorMessage = this.bodyText.error ? this.bodyText.error : this.errorMessage;
  }
  close() {
    this.modalCtrl.dismiss();
  }

  resendEmailButton(e:Event){
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    
    let postData:any = {
      token: this.options.user.jwt,
      data: {}
    };
    this.showLoader = true;
    this.webservice.post("confirmail", postData, response => {
      this.modalCtrl.dismiss();
      this.showLoader = false;
    }, (error:any) => {
      console.log(error);
    });

  }

  changeEmailButton(e:Event){
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.modalCtrl.dismiss().then(()=>{
      this.navCtrl.navigateRoot(['/tabs/tab4/profile']);
    });
    
  }

}
