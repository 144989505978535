import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-login-alert',
  templateUrl: './login-alert.page.html',
  styleUrls: ['./login-alert.page.scss'],
})
export class LoginAlertPage implements OnInit {
  @Input() options:any;
  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  goToLogin(){
    let opt = {methods:true}
    this.navCtrl.navigateRoot(["authentication/", opt]);
    this.modalCtrl.dismiss();
  }

  close(){
    this.modalCtrl.dismiss();
  }
}
