import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { InterfaceTextService } from '../../../utils/interface-text.service';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.page.html',
  styleUrls: ['./popups.page.scss'],
})
export class PopupsPage implements OnInit {
  @Input() options: any;
  bodyText:any = {"method_mismatch_message":[]};
  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private interfaceText: InterfaceTextService
  ) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.renderText();
  }

  renderText() {
    let screen_name = "plans_page_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }
  close(){
    this.modalCtrl.dismiss();
  }
}
