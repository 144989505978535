import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { WebserviceService } from '../../utils/webservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BuyPage } from '../../popups/buy/buy.page';
import { InterfaceTextService } from '../../utils/interface-text.service';

declare let $: any;

@Component({
  selector: 'app-in-app-popup',
  templateUrl: './in-app-popup.page.html',
  styleUrls: ['./in-app-popup.page.scss'],
})
export class InAppPopupPage implements OnInit {
  @Input() options: any;
  popupContent: any = {};
  template: any = "<p>Cargando...</p>";
  tabIndex: any;
  commonBodyText: any = {};
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private webservice: WebserviceService,
    private sanitizer: DomSanitizer,
    private interfaceText: InterfaceTextService
  ) { }

  ngOnInit() {
    this.commonBodyText = this.interfaceText.getBodyText('common');
    this.template = "<p>" + this.commonBodyText.loading + "</p>";

    this.popupContent = this.options.content;
    if (window.innerWidth > 991) {
      setTimeout(() => {
        this.setFocusTo("inAppPopup0");
      }, 400)
    }
    if (this.popupContent.templateUrl) {
      this.webservice.get(("popups20/" + this.popupContent.templateUrl), { bucket: true, responseType: 'text/html' }, response => {
        this.template = this.sanitizer.bypassSecurityTrustHtml(response);
        $(document).ready(() => {
          this.popupContent.buttons.forEach((btn, index) => {
            if (btn.url) $("#in-app-popup").find("#button" + (index + 1)).addClass("action-button");
            $("#in-app-popup").find("#button" + (index + 1)).unbind("click").bind("click", (e: Event) => {
              this.onButtonClick(e, btn);
            });
          });
        });
      });
    }

    if (this.popupContent.contentHTML) {
      this.template = this.sanitizer.bypassSecurityTrustHtml(this.popupContent.contentHTML);
    }

  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    let Up_Arrow_Key = event.key === "ArrowUp";
    let Down_Arrow_Key = event.key === "ArrowDown";

    if (window.innerWidth > 991) {
      switch (this.tabIndex) {
        case 59: {
          this.setFocusTo("inAppPopup0");
          if (Down_Arrow_Key) {
            this.setFocusTo("inAppPopup1");
          }
          break;
        }
        case 60: {
          this.setFocusTo("inAppPopup1");
          if (Up_Arrow_Key) {
            this.setFocusTo("inAppPopup0");
          }
          break;
        }
      }
    }
  }

  @HostListener('window:click', ['$event'])
  clickout(event) {
    if (window.innerWidth > 991) {
      switch (this.tabIndex) {
        case 59: {
          this.setFocusTo("inAppPopup0");
          break;
        }
        case 60: {
          this.setFocusTo("inAppPopup1");
          break;
        }
      }
    }
  }


  onButtonClick(e: Event, button) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (button.url) {
      this.modalCtrl.dismiss({ action: true });
      if (button.url.includes("tab3")) {
        this.showBuyPopup();
      } else {
        this.navCtrl.navigateForward([button.url]);
      }
    } else {
      this.closePopup(e);
    }
  }

  buyPagePopup: any = null;
  async showBuyPopup() {
    this.buyPagePopup = await this.modalCtrl.create({
      component: BuyPage,
      cssClass: 'buy-popup-css',
      componentProps: {
        options: {}
      }
    });
    await this.buyPagePopup.present();
  }

  setFocusTo(htmlElement) {

    //focus html elements
    let inAppPopup0 = $('.inAppPopup0');//59
    let inAppPopup1 = $('.inAppPopup1');//60

    switch (htmlElement) {
      case "inAppPopup0": {
        inAppPopup0.focus();
        this.tabIndex = 59;
        break;
      }
      case "inAppPopup1": {
        inAppPopup1.focus();
        this.tabIndex = 60;
        break;
      }
      default: {
        inAppPopup0.focus();
        this.tabIndex = 59;
        break;
      }
    }
  }

  closePopup(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.modalCtrl.dismiss().then(() => { });
  }
}
