import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import { PurchaseService } from './purchase.service';
import { UserApiService } from './user-api.service';
import { DevDataService } from './dev-data.service';
import { InterfaceTextService } from './interface-text.service';
import { TutorialService } from './tutorial.service';
import { FirebaseEventsService } from './firebase-events.service';
import { WebserviceService } from './webservice.service';
import { UserFlagsService } from './common/user-flags.service';
import { EventManagerService } from './common/event-manager.service';
import { SharedDataService } from './common/shared-data.service';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PreloadService {

  constructor(
    private devDataService: DevDataService,
    private purchaseService: PurchaseService,
    private userApiService: UserApiService,
    private interfaceTextService: InterfaceTextService,
    private tutorialService: TutorialService,
    private userFlagsService: UserFlagsService,
    private firebaseEventsService: FirebaseEventsService,
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    private webservice: WebserviceService,
    private appVersion: AppVersion,
    private platform: Platform
  ) { }

  private preloadProgress: number = 0;

  

  private onUpdate() {
    this.sharedDataService.appData.preloadProgress = (++this.preloadProgress / 8)
  }

  initialize() {
    return new Promise(async (resolve, reject) => {
      await this.interfaceTextService.loadInterfaceText();
      let vInfo:any = await this.getVersionInfo();
      if(!vInfo.ok) {
        this.eventManager.trigger('version.updateAvailable', {version: vInfo.version, latest: vInfo.latestVersion, optional: false});
      } else if(vInfo.ok && vInfo.updateAvailable) this.eventManager.trigger('version.updateAvailable', {version: vInfo.version, latest: vInfo.latestVersion, optional: true});
      Promise.all([
        this.devDataService.downloadContent().then(() => this.onUpdate()).catch(()=>{return false;}),
        this.purchaseService.initialize().then(() => this.onUpdate()).catch(()=>{return false;}),
        this.userApiService.getDeviceFBT().then(() => this.onUpdate()).catch(()=>{return false;}),
        this.tutorialService.preload().then(() => this.onUpdate()).catch(()=>{return false;}),
        this.userFlagsService.preload().then(() => this.onUpdate()).catch(()=>{return false;}),
        this.firebaseEventsService.preload().then(() => this.onUpdate()).catch(()=>{return false;})
      ]).then((results) => {
        resolve({ok: true});
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getVersionInfo() {
    return new Promise(async (resolve)=>{
      if(this.platform.is('cordova')) {
        let config = await this.getVersionConfig();
        let _platform = this.platform.is('android') ? 'android' : 'ios';
        let vNumber = await this.appVersion.getVersionNumber();
        let vCode =  await this.appVersion.getVersionCode();
        if(vNumber && vCode) {
          let _minVCode = this.compareVersionCode(vCode, config[_platform].minVersionCode);
          let _vCode = this.compareVersionCode(vCode, config[_platform].latestVersionCode);
          let appVersion = vNumber+"("+vCode+")";
          let availableVersion = config[_platform].latestVersionNumber+"("+config[_platform].latestVersionCode+")";
          if(_minVCode == -1) {
            resolve({ok:false, updateAvailable: true, version: appVersion, latestVersion: availableVersion});
          } else if (_vCode == -1) {
            resolve({ok:true, updateAvailable: true, version: appVersion, latestVersion: availableVersion});
          } else {
            resolve({ok:true, version: appVersion, latestVersion: availableVersion});
          }
        } else {
          resolve({ok:true});
        }
      } else {
        resolve({ok:true});
      }
    });
  }

  getVersionConfig() {
    return new Promise((resolve, reject)=>{
      let getOptions: object = { token: false, data: false, bucket: true, local: false }
      this.webservice.get(environment.appVersionConfig, getOptions, async (data) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  compareVersionCode(c1:any, c2:any) {
    console.log(typeof c1)
    if(typeof c1 == 'string' && c1.indexOf('.') > -1) {
      let tmpArr1 = c1.split('.');
      let tmpArr2 = c2.split('.');
      let r = 0;
      (function loop(i) {
        if(Number(tmpArr1[i]) > Number(tmpArr2[i])) r = 1;
        else if(Number(tmpArr1[i]) < Number(tmpArr2[i])) r = -1;
        else {
          if(i < (tmpArr1.length - 1)) loop(++i);
          else r = 0;
        }
      })(0);
      return r;      
    } else {
      if(c1 > c2) return 1;
      else if(c1 == c2) return 0;
      else return -1;
    }
  }
}
