import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { WebserviceService } from './webservice.service';
import { AppStorageService } from './app-storage.service';
import { EventManagerService } from './common/event-manager.service';
import { DevDataService } from './dev-data.service';
import { SharedDataService } from './common/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class InterfaceTextService {
  LANG: string;
  textData: any = null;
  constructor(
    private webservice: WebserviceService,
    private sanitizer: DomSanitizer,
    private storage: AppStorageService,
    private devDataService: DevDataService,
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService
  ) { }

  tutorLangList = [{ "code": "es", "Text": "Español", "button_text": "Mi idioma es Español.", "available": true, "selected": true }, { "code": "zh", "Text": "中文", "button_text": "我的语言是简体中文", "available": true }, { "code": "fr", "Text": "Français", "available": false }, { "code": "it", "Text": "Italiano", "available": false }, { "code": "de", "Text": "Deutsch", "available": false }, { "code": "pt", "Text": "português", "available": false }];

  getLanguageName(code:any) {
    let lang = null;
    this.tutorLangList.forEach((item) => {
      if (item.code == code) lang = item.Text;
    });
    return lang;
  }


  getInterfaceLanguages() {
    return this.tutorLangList;
  }



  async setLanguage(lang: string) {
    this.tutorLangList.forEach((item) => {
      if (item.code != lang) item.selected = false;
      if (item.code == lang) item.selected = true;
    });

    this.LANG = lang;
    environment.language = lang;

    await this.storage.setWithPromise('LANG', lang);
    await this.loadInterfaceText();
  }


  async isLangSelectionLaunched() {
    let isSet: any = await this.storage.getWithPromise('lang_selection_launched');
    return isSet;
  }

  async langSelectionLaunched(value?: any) {
    await this.storage.setWithPromise('lang_selection_launched', value !== undefined ? value : true);
  }

  async changeLanguage(lang: string) {
    await this.setLanguage(lang);
  }

  async loadContentTree() {
    await this.devDataService.downloadContent();
  }

  async loadProductConfig() {
    await this.devDataService.downloadContent();
  }

  loadInterfaceText(local?: boolean) {
    return new Promise(async (resolve, reject) => {
      let lang: any = await this.storage.getWithPromise('LANG');
      this.LANG = this.sharedDataService.settings.multilingual ? lang ? lang : environment.language : environment.language;
      this.tutorLangList.forEach((item) => {
        if (item.code != this.LANG) item.selected = false;
        if (item.code == this.LANG) item.selected = true;
      });
      environment.language = this.LANG;

      let getLangOptions: object = {
        token: false,
        data: false,
        bucket: local ? !local : true,
        local: local ? local : false
      }
      let endpoint = local ? ("lang/" + this.LANG + ".json") : ( environment.interfaceText + this.LANG + ".json");
      this.webservice.get(endpoint, getLangOptions, async (data) => {
        this.textData = data;
        resolve(data);
        this.eventManager.trigger('language.ready');
        // console.log(data)
      }, error => {
        if(!local) this.loadInterfaceText(true);
        if(local) reject(error);
        console.log(error);
      });

    });
  }



  async getInterfaceText(section: string, callback?: (re: any) => any) {
    if (this.textData) {
      if (callback) {
        if (section == "all") {
          callback(this.textData);
        } else {
          callback(this.textData[section]);
        }
      }
    } else {

    }

  }

  getBodyText(screen_name: string) {
    if (!this.textData) {
      return {};
    }

    let deepLoop = (obj: any) => {
      if (typeof obj == 'string') {
        obj = this.sanitizer.bypassSecurityTrustHtml(obj);
      } else if (Array.isArray(obj)) {
        obj.forEach(k => {
          deepLoop(k);
        });
      } else {
        Object.keys(obj).forEach((k) => {
          deepLoop(k);
        });
      }
    }
    deepLoop(this.textData[screen_name]);
    // console.log(this.textData[screen_name]);
    return this.textData[screen_name];
  }

}
