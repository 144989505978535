// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  language: "es",
  layoutVersion: 3,
  webserviceEndpoint: 'https://emouserdev.englishmobile.com/',
  webserviceEndpoint2: 'http://localhost:3000/dev/',
  facebookLoginProvider: '641644189643172',
  googleLoginProvider: "43825857626-019bdlq7n5seha3l9hlhuedc3h1vur5k.apps.googleusercontent.com",
  paypalPlan: {
    monthly: 'P-6CY73132ST503391FLZYLIMA',
    yearly: 'P-8WM92224NY882500YLZYLIJA'
  },
  paypalClientId: 'AZYRd_2OX7f7hkcm8_wiOFdFHtePfbxwPg-lWizl2AJBg9NbiuGGNbMPLjfsKyk48fu6pm60Z-5Va1iL',
  stripePublishKey: 'pk_test_37pLTQJdpTMADC2R06IbxVCI',
  firebaseConfig: {
    apiKey: "AIzaSyBmFBW9FLDeLSSYjFK9uBAw2E79X75s88k",
    authDomain: "bbc-emo.firebaseapp.com",
    databaseURL: "https://bbc-emo.firebaseio.com",
    projectId: "bbc-emo",
    storageBucket: "bbc-emo.appspot.com",
    messagingSenderId: "43825857626",
    appId: "1:43825857626:web:fb375f75663ad672630463",
    measurementId: "G-W8DC1T3LP2"
  },
  passMarks: 70,

  vapidKey: 'BBuQgtm_2DDdevG7pWTrRTDaeQ75UcBjJ06FshsCr9gGjMV8IIs63p5aUPl8WryP--l4JnvIGhtTrOzwmFV1afs',
  
  
  
  
  appleSigninRedirect2: 'https://nilay-air1.local:8080',
  appleSigninRedirect: 'https://pwadev.englishmobile.com',
  s3_base: 'https://englishmobile.s3.eu-central-1.amazonaws.com/',

  // post publish urls
  appVersionConfig: 'dashboard20/app_version/version.json',

  // pre publish urls
  contentVersionFile: 'content30/tree/v3/version.',
  contentTreePath: 'content30/tree/v3/content.',
  contentZipPath: 'zips20/v4/',
  glDir: 'dashboard20/grammar/',
  tipsDir: 'dashboard20/tips/',
  surveyPrefix: 'survey.sandbox.v2/',
  popupConfig: 'popups.test.json',
  plansConfig: 'plans20/v3/products.sandbox.json',
  plansConfigDir: 'plans20/v3/',
  plansConfig2: 'course/products.sandbox.json',
  subtitleDir: 'dashboard20/subtitles/',
  video_sprites: 'content30/images/v2/video_sprite/',
  flagMap: 'popups20/flags.v2/flags.sandbox.json',
  interfaceText: 'dashboard20/interface_texts/v4/dev/',
  guidedTourConfig: 'guidedtour20/v4/tutorials.test',
  exe_image_base_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/content30/images/v2/exercises/',
  vocab_image_base_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/content30/images/v2/vocabulary/',
  vocab_audio_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/content30/audios/v2/vocabulary/',
  exer_audio_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/content30/audios/v2/exercises/',
  legal_faq: 'legal/2023/FAQ.html',
  legal_advice: 'legal/2023/aviso-legal.html',
  legal_privacy: 'legal/2023/politica-de-privacidad.html',
  legal_conditions: 'legal/2023/condiciones-generales.html',

  redeemOptions2: {
    successUrl: "http://localhost:4200/#/redeem?status=success",
    cancelUrl: "http://localhost:4200/#/redeem?status=cancel"
  },
  redeemOptions: {
    successUrl: "https://redeendev.bbcfollowme.com/#/redeem?status=success",
    cancelUrl: "https://redeendev.bbcfollowme.com/#/redeem?status=cancel"
  }
  
  // exe_image_base_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/img20/',
  // vocab_image_base_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/vocab20/images/',
  // vocab_audio_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/vocab20/audios/',
  // exer_audio_path: 'https://englishmobile.s3.eu-central-1.amazonaws.com/audio/exercise/'
};
/*
export let globals = {
  userData: null,
  coursedata:null
}*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
