import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { SharedDataService } from '../../utils/common/shared-data.service';
import { UserApiService } from '../../utils/user-api.service';
import { InterfaceTextService } from '../../utils/interface-text.service';

@Component({
  selector: 'app-app-rate-popup',
  templateUrl: './app-rate-popup.page.html',
  styleUrls: ['./app-rate-popup.page.scss'],
})
export class AppRatePopupPage implements OnInit {
  @Input() options: any;
  private eventListeners: any = {};
  bodyText: any = {};

  constructor(
    private modalCtrl: ModalController,
    private firebaseEvents:FirebaseEventsService,
    private eventManager: EventManagerService,
    private userAPI: UserApiService,
    public sharedDataService: SharedDataService,
    private interfaceText: InterfaceTextService
  ) {
    this.eventListeners.ratingRequestedListener = this.eventManager.bind('rating.ratingRequested').subscribe(async ()=>{
      this.showLoader = false;
      this.sharedDataService.appData.appRatePopup.dismiss();
    });
  }

  showLoader:boolean = false;
  currentPrompt:any = null;
  ngOnInit() {
    this.currentPrompt = (this.options && this.options.promptId) ? this.options.promptId : "1";
    this.firebaseEvents.logEvents('apprate_popup'+this.currentPrompt+'_display');
  }

  ionViewWillEnter() {
    this.renderText();
  }

  renderText() {
    let screen_name = "app_rate_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  onAcceptClick(e:Event, promptId:any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    switch(promptId) {
      case "1":
        this.currentPrompt = "2";
        this.firebaseEvents.logEvents('apprate_popup1_action',{action:'accept'});
        this.firebaseEvents.logEvents('apprate_popup2_display');
      break;
      case "2":
        this.showLoader = true;
        this.eventManager.trigger("rating.onRateApp");
        this.firebaseEvents.logEvents('apprate_popup2_action',{action:'accept'});
      break;
      case "3":
        this.options.survey.token = this.userAPI.user.jwt;
        this.eventManager.trigger("rating.onRatingSurvey", this.options.survey);
        this.firebaseEvents.logEvents('apprate_popup3_action',{action:'accept'});
        this.sharedDataService.appData.appRatePopup.dismiss();
      break;
    }
  }

  onCancelClick(e:Event, promptId:any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    switch(promptId) {
      case "1":
        this.currentPrompt = "3";
        this.firebaseEvents.logEvents('apprate_popup1_action',{action:'decline'});
        this.firebaseEvents.logEvents('apprate_popup3_display');
      break;
      case "2":
        this.sharedDataService.appData.appRatePopup.dismiss().then(()=>{});
        this.eventManager.trigger("rating.onRateLater");
        this.firebaseEvents.logEvents('apprate_popup2_action',{action:'decline'});
      break;
      case "3":
        this.sharedDataService.appData.appRatePopup.dismiss().then(()=>{});
        this.firebaseEvents.logEvents('apprate_popup3_action',{action:'decline'});
      break;
    }
  }


}
