import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTabbingClick]',
  host: {
    "tabindex": "0",
    // "role": "button",
    "(keydown.enter)": "$event.preventDefault() ; $event.target.click() ;",
  }
})
export class TabbingClickDirective{
  constructor(private elRef: ElementRef) { }

  @HostListener('click') onClick() {
    this.elRef.nativeElement.blur();
  }
}
