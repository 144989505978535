import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserApiService } from '../../utils/user-api.service';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { SocialLoginService } from './services/social-login.service';
import { AppleSigninService } from './services/apple-signin.service';
import { NavController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthMethodsPopupPage } from './components/auth-methods-popup/auth-methods-popup.page';
import { ChangeMethodPage } from './components/change-method/change-method.page';
import { OfflineAlertPage } from '../../popups/offline-alert/offline-alert.page';
import { TutorSelectionPage } from '../../popups/tutor-selection/tutor-selection.page';
import { myEnterAnimation } from '../../animations/modal-zoom-in';
import { myLeaveAnimation } from '../../animations/modal-zoom-out';
import { SharedDataService } from '../../utils/common/shared-data.service';
import { WebElementfocusService } from '../../utils/common/web-elementfocus.service';
import { environment } from '../../../environments/environment';

declare let $: any;

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.page.html',
  styleUrls: ['./authentication.page.scss'],
})


export class AuthenticationPage implements OnInit {

  socialLoginInitListener: Subscription;
  socialLoginEndListener: Subscription;
  appleLoginInitListener: Subscription;
  appleLoginEndListener: Subscription;
  isProcessing: boolean = false;
  private eventListeners: any = {};
  bodyText: any = {};
  language: any = null;
  toggleIndex:any;
  layoutVersion: any = environment.layoutVersion ? environment.layoutVersion : null;

  constructor(
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private socialLoginService: SocialLoginService,
    private appleSigninService: AppleSigninService,
    private interfaceText: InterfaceTextService,
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    public webElementfocusService: WebElementfocusService
  ) {

    if (this.socialLoginInitListener) this.socialLoginInitListener.unsubscribe();
    this.socialLoginInitListener = this.socialLoginService.onLoginStart.subscribe(() => {
      this.isProcessing = true;
    });

    if (this.appleLoginInitListener) this.appleLoginInitListener.unsubscribe();
    this.appleLoginInitListener = this.appleSigninService.onLoginStart.subscribe(() => {
      this.isProcessing = true;
    });

    if (this.socialLoginEndListener) this.socialLoginEndListener.unsubscribe();
    this.socialLoginEndListener = this.socialLoginService.onLoginEnd.subscribe((response: any) => {
      if (response.ok) {
        this.navCtrl.navigateRoot(["/tabs/tab1"]);
      } else if (response.change_method) {
        this.isProcessing = false;
        this.changeMethod(response.change_method);
      } else if(response.error == "offline") {
        let popupText = this.interfaceText.getBodyText("no_internet_popup");
        this.openNoInternetPopup(popupText['message_1']);
        this.isProcessing = false;
      } else {
        this.isProcessing = false;
      }
    });

    if (this.appleLoginEndListener) this.appleLoginEndListener.unsubscribe();
    this.appleLoginEndListener = this.appleSigninService.onLoginEnd.subscribe((response: any) => {
      if (response.ok) {
        this.navCtrl.navigateRoot(["/tabs/tab1"]);
      } else if (response.change_method) {
        this.isProcessing = false;
        this.changeMethod(response.change_method);
      } else if(response.error == "offline"){
        let popupText = this.interfaceText.getBodyText("no_internet_popup");
        this.openNoInternetPopup(popupText['message_1']);
        this.isProcessing = false;
      } else {
        this.isProcessing = false;
      }
    });

    // this.route.params.subscribe(param => {
    //   if (param.methods == "true") {
    //     this.openModal();
    //   } else {
    //     if (this.chooseModal) async () => await this.chooseModal.dismiss();
    //   }
    // });
  }

  showLangScreen: boolean = null;
  langSelectionScreenIndex:number = 0;
  tutorLangConfig: any = [];
  ngOnInit() {
    this.route.params.subscribe(async (param) => {
      this.language = this.interfaceText.LANG;
      let langScreenLaunched = await this.interfaceText.isLangSelectionLaunched();
      this.langSelectionScreenIndex = 0;
      // tmp
      // langScreenLaunched = false;
      this.tutorLangConfig = [];
      if(!langScreenLaunched && this.sharedDataService.settings.multilingual) {
        this.showLangScreen = true;
        this.interfaceText.tutorLangList.forEach((item)=>{
          if(item.available) {
            this.tutorLangConfig.push(item);
          }
        })
        // create new modal
        // this.showTutorSelectionPopup();
      } else {
        this.showLangScreen = false;
        console.log('dont create new modal');
        this.userApiService.getUser().then((user: any) => {
          if (user.jwt) {
            this.navCtrl.navigateRoot(["/tabs/tab1"]);
          }
        }).catch(() => {
          // stay in this page
        });
      }
      setTimeout(()=>{
        this.renderText();
      }, 500);
    });
    if(this.eventListeners.onLanguageReady) this.eventListeners.onLanguageReady.unsubscribe();
    this.eventListeners.onLanguageReady = this.eventManager.bind('language.ready').subscribe(async (fl: any) => {
      this.renderText();
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let RIGHT_ARROW = event.key === this.webElementfocusService.KEY_CODE.RIGHT_ARROW;
    let LEFT_ARROW = event.key === this.webElementfocusService.KEY_CODE.LEFT_ARROW;
    let UP_ARROW = event.key === this.webElementfocusService.KEY_CODE.UP_ARROW;
    let DOWN_ARROW = event.key === this.webElementfocusService.KEY_CODE.DOWN_ARROW;

    if (this.webElementfocusService.isPWA && !this.chooseModal && !this.sharedDataService.appData.PopupPrinted) {
      switch (this.webElementfocusService.tabIndex) {
        case "authenticationShowCourseDetails": {
          if (DOWN_ARROW) {
            this.webElementfocusService.setPositionsFocus("DOWN_ARROW");
          }
          break;
        }
        case "authenticationAlreadyHaveAccount": {
          if (UP_ARROW) {
            this.webElementfocusService.setPositionsFocus("UP_ARROW");
          }
          break;
        }
        case "authenticationLangSelectionToggle": {
          if (DOWN_ARROW && this.toggleIndex < this.tutorLangConfig.length - 1) {
            this.toggleIndex++;
            this.webElementfocusService.setManualFocusTo("authenticationLangSelectionToggle", "toggle", this.toggleIndex, "class");
          } else if (DOWN_ARROW && this.toggleIndex ===  this.tutorLangConfig.length - 1) {
           this.webElementfocusService.setFocusTo("authenticationLangSelection")
          }
          if (UP_ARROW && this.toggleIndex > 0) {
            this.toggleIndex--;
            this.webElementfocusService.setManualFocusTo("authenticationLangSelectionToggle", "toggle", this.toggleIndex, "class");
          }
          break;
        }
        case "authenticationLangSelection": {
          if (UP_ARROW) {
            this.toggleIndex = this.tutorLangConfig.length - 1;
            this.webElementfocusService.setManualFocusTo("authenticationLangSelectionToggle", "toggle", this.toggleIndex, "class");
          }
          break;
        }
        case "authenticationOnLangConfirmed": {
          if (UP_ARROW || LEFT_ARROW) {
            this.webElementfocusService.setPositionsFocus("UP_ARROW");
          }
          break;
        }
        case "authenticationOnLangConfirmedBackButton": {
          if (DOWN_ARROW || RIGHT_ARROW) {
            this.webElementfocusService.setPositionsFocus("DOWN_ARROW");
          }
          break;
        }
      }
    }
  }

  @HostListener('window:click', ['$event'])
  clickout(event) {
    if (this.webElementfocusService.isPWA && !this.chooseModal  && !this.sharedDataService.appData.PopupPrinted) {
      this.webElementfocusService.setFocusTo(this.webElementfocusService.tabIndex);
      switch (this.webElementfocusService.tabIndex) {
        case "authenticationLangSelectionToggle": {
          this.webElementfocusService.setManualFocusTo("authenticationLangSelectionToggle", "toggle", this.toggleIndex, "class");
          break;
        }
      }
    }
  }

  async onLangSelected(e:Event, lang:any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (this.webElementfocusService.isPWA) {
      this.toggleIndex = lang==="zh" ? 1 : 0;
      this.webElementfocusService.setManualFocusTo("authenticationLangSelectionToggle", "toggle", this.toggleIndex, "class");
    }
    await this.interfaceText.changeLanguage(lang);
    this.eventManager.trigger('language.updated');
    this.eventManager.trigger('language.update_confirmed');
    this.renderText();
    
  }
  onLangSelectionNext() {
    this.langSelectionScreenIndex = 1;
    if (this.webElementfocusService.isPWA) {
      setTimeout(() => {
       this.webElementfocusService.setFocusTo("authenticationOnLangConfirmed");
      }, 100)
    }
  }

  backButton(e:Event) {
    // e.preventDefault();
    // e.stopPropagation();
    // e.stopImmediatePropagation();
    setTimeout(() => {
      this.webElementfocusService.setFocusTo("authenticationLangSelection");
     }, 100)
    this.langSelectionScreenIndex = 0;
  }

  contentLoading:boolean = false;
  async onLangConfirmed() {
    this.contentLoading = true;
    await this.interfaceText.loadContentTree();
    await this.interfaceText.langSelectionLaunched();
    if (this.webElementfocusService.isPWA) {
      setTimeout(() => {
       this.webElementfocusService.setFocusTo("authenticationShowCourseDetails");
      }, 100)
    }
    this.contentLoading = false;
    this.showLangScreen = false;
  }

  async showTutorSelectionPopup() {
    let popup = await this.modalCtrl.create({
      component: TutorSelectionPage,
      enterAnimation: myEnterAnimation,
      leaveAnimation: myLeaveAnimation,
      cssClass: 'popup-center-modal',
      componentProps: {
        getCaller: () => {
          return popup
        },
        updateCallerText: ()=>{
          this.renderText();
        }
      }
    });
    popup.onDidDismiss().then((re: any) => {
      this.chooseModal = null;
    });
    await popup.present();
  }

  ionViewWillEnter() {
    if (this.webElementfocusService.isPWA) {
      setTimeout(() => {
        this.showLangScreen ? this.webElementfocusService.setFocusTo("authenticationLangSelection")
          : this.webElementfocusService.setFocusTo("authenticationShowCourseDetails");
      }, 300)
    }
    this.renderText();
  }

  renderText() {
    let screen_name = "onboarding_authentication";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  chooseModal: any = null;
  async openModal() {
    this.chooseModal = await this.modalCtrl.create({
      component: AuthMethodsPopupPage,
      cssClass: 'my-custom-modal-css',
    });
    this.chooseModal.onDidDismiss().then((re: any) => {
      this.chooseModal = null;
      this.courseDetailsVisible ? this.webElementfocusService.setFocusTo("authenticationTry14Days")
        : this.webElementfocusService.setFocusTo("authenticationAlreadyHaveAccount");
    });
    await this.chooseModal.present();
  }

  courseDetailsVisible:boolean = false;
  showCourseDetails(){
    this.courseDetailsVisible = true;
    if (this.webElementfocusService.isPWA) {
      setTimeout(() => {
       this.webElementfocusService.setFocusTo("authenticationTry14Days")
      }, 0)
    }
  }

  changeMethodModal: any = null;
  async changeMethod(userInfo: any) {
    
    this.changeMethodModal = await this.modalCtrl.create({
      component: ChangeMethodPage,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        options: userInfo
      }
    });
    this.changeMethodModal.onDidDismiss().then((re: any) => {
      this.changeMethodModal = null;
      if(re.data.change_language) {
        this.langSelectionScreenIndex = 0;
        this.showLangScreen = true;
      }
    });
    await this.changeMethodModal.present();
    
  }

  ngOnDestroy() {
    if (this.socialLoginInitListener) this.socialLoginInitListener.unsubscribe();
    if (this.socialLoginEndListener) this.socialLoginEndListener.unsubscribe();
    if (this.chooseModal) this.chooseModal.dismiss();
    if (this.changeMethodModal) this.changeMethodModal.dismiss();
  }

  ionViewWillLeave() {
    this.isProcessing = false;
  }

  offlineModal: any = null;
  async openNoInternetPopup(msg: any) {
    if (this.offlineModal) {
      this.offlineModal.dismiss();
      this.offlineModal = null;
    }
    this.offlineModal = await this.modalCtrl.create({
      component: OfflineAlertPage,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        options: {
          message: msg
        }
      }
    });
    this.offlineModal.onDidDismiss().then((re: any) => {
      this.offlineModal = null;
    });
    await this.offlineModal.present();
  }
}