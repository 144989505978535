import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { SharedDataService } from '../../utils/common/shared-data.service';
import { WebserviceService } from '../../utils/webservice.service';
import { UserApiService } from '../../utils/user-api.service';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-delete-account-confirm',
  templateUrl: './delete-account-confirm.page.html',
  styleUrls: ['./delete-account-confirm.page.scss'],
})
export class DeleteAccountConfirmPage implements OnInit {
  @Input() options: any;
  constructor(
    private firebaseEvents:FirebaseEventsService,
    public sharedDataService: SharedDataService,
    private webservice: WebserviceService,
    private userApi: UserApiService,
    private eventManager: EventManagerService,
    private interfaceText: InterfaceTextService,
    private navCtrl: NavController
  ) { }
  bodyText: any = {};
  deletionStep: number = 0;
  deletionStepMsg: string = '';
  deleteConfirmStep2: string = 'delete';
  userInput:string = '';
  // documentRef: any = {
  //   "deletion": "dashboard20/legal/deletion_info." + environment.language + ".html"
  // }
  user: any = {};
  userSubscriptionInfo:any = null;
  ngOnInit() {
    this.renderText();
    this.user = this.userApi.user;
    this.userInput = '';
    this.firebaseEvents.logEvents('account_deletion_step1');
    this.getUserSubscriptionDetails();

    this.eventManager.bind('language.ready').subscribe(()=>{
      this.renderText();
    });
  }
  ionViewWillEnter() {
    this.renderText();
  }
  renderText() {
    let screen_name = "account_delete_popup";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }
  onBackClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.sharedDataService.popups.deleteAccountConfirmAlert.dismiss();
  }

  getUserSubscriptionDetails() {
    let o: object = { token: this.user.jwt }
    this.webservice.post('suscriptiondetails', o, (data: any) => {
      console.log(data);
      console.log(this.user)
      let today = new Date().getTime();
      this.userSubscriptionInfo = data;
      this.userSubscriptionInfo.is_in_future = (new Date(data.expiry_date).getTime() > today);
      if(this.options.delete) {
        this.deletionStep = 4;
      } else if(this.options.cancel_delete) {
        this.deletionStep = 5;
      } else {
        this.deletionStep = 1;
      }
      
    })
  }
  showLoader:boolean = false;
  sendAccountDeletionMail(e:Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    let o: object = { token: this.user.jwt, data:{type: (this.user.premium ? 'premium' : 'free'), link: 'https://pwa.englishmobile.com/#/tabs/tab4?id=immidiate_deletion'} }
    this.showLoader = true;
    this.webservice.post('accountdeletemail', o, (response: any) => {
      this.showLoader = false;
      this.deletionStep = 3;
      this.deletionStepMsg = response.ok ? (this.bodyText['mail_sent_success'][0] + this.user.email + this.bodyText['mail_sent_success'][1]) : this.bodyText['mail_sent_error'];
    });
  }


  confirmDelayedAccountDeletion(e:Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.deletionStep = 2;
  }

  setDelayedAccountDeletion(e:Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.goDelete(e, true);
  }


  
  goDelete(e?:any, delayed?:boolean) {
    if(e){
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
    
    let postData:any = { token: this.user.jwt, data: { delayed: delayed} };
    this.showLoader = true;
    this.webservice.post("deluser", postData, response => {
      this.showLoader = false;
      if(response.message.includes('has been deleted')) {
        this.deletionStep = 6;
        let email = this.user.email;
        this.deletionStepMsg = this.bodyText['account_deleted_msg'][0] + email + this.bodyText['account_deleted_msg'][1];
        this.logoutLocally();
      } else {
        this.deletionStep = 3;
        this.deletionStepMsg = this.bodyText['msg_code'] && this.bodyText['msg_code'][response.message_code] ? this.bodyText['msg_code'][response.message_code] : response.message;
      }
    }, (error:any) => {
      console.log(error);
    });
  }

  cancelDeletion(e?:any) {
    if(e){
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    this.showLoader = true;
    let postData:any = { token: this.user.jwt };
    this.webservice.post("accountrevokedelete", postData, response => {
      this.showLoader = false;
      this.deletionStep = 3;
      this.deletionStepMsg = this.bodyText['msg_code'] && this.bodyText['msg_code'][response.message_code] ? this.bodyText['msg_code'][response.message_code] : response.message;
    });
  }


  logoutLocally(e?:Event) {
    if(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    this.eventManager.trigger('debug.resetApp');
    this.navCtrl.navigateRoot(['authentication']);
    if(e) this.sharedDataService.popups.deleteAccountConfirmAlert.dismiss();
    
  }

  getPreviousDay(_date) {
    let date = _date ? new Date(_date) : new Date();
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
  
    return previous;
  }

  getNextDay(_date?) {
    let date = _date ? new Date(_date) : new Date();
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() + 1);
  
    return previous;
  }
}
