import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { EventManagerService } from './common/event-manager.service';
import { UserFlagsService } from './common/user-flags.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FacebookEventsHandlerService } from './analytics/facebook-events-handler.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppRateService {
  private eventListeners: any = {};
  private browserOptions: InAppBrowserOptions = {
    location: 'yes',
    hidden: 'no',
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes',
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no',
    closebuttoncaption: 'Close',
    closebuttoncolor: "#ffffff",
    disallowoverscroll: 'no',
    toolbar: 'yes',
    toolbarcolor: '#032a53',
    enableViewportScale: 'no',
    allowInlineMediaPlayback: 'yes',
    fullscreen: 'yes',
    hidenavigationbuttons: "yes",
    lefttoright: "no",
    toolbarposition: "top",
  };
  private surveyBasepath = "https://englishmobile.s3.eu-central-1.amazonaws.com/popups20/" + environment.surveyPrefix;
  constructor(
    private appReview: LaunchReview,
    private platform: Platform,
    private eventManager: EventManagerService,
    private inAppBrowser: InAppBrowser,
    private statusBar: StatusBar,
    private userFlags: UserFlagsService,
    private facebookEventsHandler: FacebookEventsHandlerService
  ) {
    this.eventListeners.setCurrentFlag = this.eventManager.bind('rating.onRateApp').subscribe(async () => {
      this.rateApp();
    });
    this.eventListeners.setCurrentFlag = this.eventManager.bind('rating.onRatingSurvey').subscribe(async (survey) => {
      survey ? this.openSurvey(survey) : () => { };
    });
    this.eventListeners.setCurrentFlag = this.eventManager.bind('rating.onRateLater').subscribe(async () => {
      this.userFlags.setFlag('RATE_LATER');
    });
  }

  showRatingPrompt(promptOption: any) {
    if (this.platform.is("cordova")) {
      if(!this.userFlags.getFlag('ASK_FOR_RATE')) this.userFlags.setFlag('ASK_FOR_RATE');
      else this.userFlags.setFlag('ASK_FOR_RATE_2');
      this.eventManager.trigger("rating.onAppRatePrompt", promptOption);
    } else {
      // if(!this.userFlags.getFlag('ASK_FOR_RATE')) this.userFlags.setFlag('ASK_FOR_RATE');
      // else this.userFlags.setFlag('ASK_FOR_RATE_2');
      // this.eventManager.trigger("rating.onAppRatePrompt", promptOption);
    }
  }


  // private inAppReviewTimeout:any = null;
  rateApp() {
    if (this.appReview.isRatingSupported()) {
      this.appReview.launch().then(() => {
        this.eventManager.trigger("rating.ratingRequested");
        this.facebookEventsHandler.logEvent({event: 'fb_emo_rated'});
        this.userFlags.setFlag('APP_RATE');
      });
      // this.appReview.rating().subscribe((re) => {
      //   console.log("ios app rate observable >> ", re);
      //   // if(this.inAppReviewTimeout) {clearTimeout(this.inAppReviewTimeout); this.inAppReviewTimeout = null;}
      //   this.eventManager.trigger("rating.ratingRequested");
      //   this.userFlags.setFlag('APP_RATE');
      // });

      // if(this.inAppReviewTimeout) {clearTimeout(this.inAppReviewTimeout); this.inAppReviewTimeout = null;}
      // this.inAppReviewTimeout = setTimeout(()=>{ 
      //   this.appReview.launch().then(() => {
      //     console.log("launched on ios");
      //    });
      //   this.eventManager.trigger("rating.ratingRequested");
      //   this.userFlags.setFlag('APP_RATE');
      // }, 10000);
    } else {
      this.appReview.launch().then(() => { });
      this.eventManager.trigger("rating.ratingRequested");
      this.userFlags.setFlag('APP_RATE');
    }
  }


  openSurvey(survey: any) {
    let url:string;
    if(survey.path) url = survey.path;
    else url = this.surveyBasepath + survey.file + "?token="+survey.token;
    let browser = this.inAppBrowser.create(url, '_blank', this.browserOptions);
    this.userFlags.setFlag(survey.id);
    if (this.platform.is("cordova")) browser.on('exit').subscribe(() => {
      this.statusBar.show();
      setTimeout(() => {
        this.statusBar.hide();
      }, 1000);
    }, err => {
      console.error(err);
    });
  }

}
