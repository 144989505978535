import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';


@Injectable({
  providedIn: 'root'
})
export class FacebookEventsHandlerService {
  private fbWebSDK: any = null;
  constructor(
    private platform: Platform,
    private FBX: Facebook
  ) { 
    if(!this.fbWebSDK) {
      const { FB } = (window as any);
      this.fbWebSDK = FB ? FB : false;
      console.log(window)
    }

    this.enableAdvertiserTracking();
  }

  advertiserTrackingEnabled: boolean = false;
  enableAdvertiserTracking() {
    return new Promise((resolve)=>{
      if(this.platform.is('ios') && !this.advertiserTrackingEnabled) {
        this.FBX.setAdvertiserTrackingEnabled(true).then(()=> {
          console.log('setAdvertiserTrackingEnabled success');
          this.advertiserTrackingEnabled=true;
          resolve(true);
        }, ()=> {
          this.advertiserTrackingEnabled=false;
          console.error('setAdvertiserTrackingEnabled failure');
          resolve(false);
        });
      } else {
        this.advertiserTrackingEnabled=true;
        resolve(true);
      }
    });
  }

  private async logFBEvent(options:any) {
    console.log("fb event ", JSON.stringify(options));
    if(this.platform.is('cordova')) {
      await this.enableAdvertiserTracking();
      this.FBX.logEvent(options.event, options.params).then((res)=>{
        console.log('fb log res >>> ' , JSON.stringify(res))
      }).catch((e)=>{
        console.log('errrr >> ',e)
      });
    } else {
      if(!this.fbWebSDK) {
        const { FB } = (window as any);
        this.fbWebSDK = FB ? FB : false;
      }
      if(this.fbWebSDK) this.fbWebSDK.AppEvents.logEvent(options.event, {fields: options.params});
    }
  }

  logEvent(options){
    this.logFBEvent(options);
  }
}

