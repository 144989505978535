import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { WebserviceService } from '../../utils/webservice.service';
import { EncodeService } from '../../utils/encode.service';
import { FirebaseEventsService } from '../../utils/firebase-events.service';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { EventManagerService } from '../../utils/common/event-manager.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.page.html',
  styleUrls: ['./reset-password-form.page.scss'],
})
export class ResetPasswordFormPage implements OnInit {
  @Input() options: any;
  code = '';
  formValid;
  newPassFormData = { password1: '', password2: '' };
  email = '';
  resetFormVisible: boolean = true;
  updateInfo: any = null;
  isLoading: boolean = false;
  eventListeners:any = {};
  constructor(
    private webservice: WebserviceService,
    private encodeService: EncodeService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private firebaseEvents: FirebaseEventsService,
    private interfaceText: InterfaceTextService,
    private eventManager: EventManagerService
  ) { }


  @ViewChild('inputElem', { static: false }) set input(input: any) {
    setTimeout(() => {
      input.setFocus().then(() => {
        console.log("focus");
      }).catch((err: any) => {
        console.log("err > ", err);
      });
    }, 1000);
  }
  
  ngOnInit() {
    this.code = this.options.code;
    let screenObj = {
      name: "SCREEN_reset_password"
    }
    this.firebaseEvents.setScreen(screenObj);
    if(this.eventListeners.onLanguageReady) this.eventListeners.onLanguageReady.unsubscribe();
    this.eventListeners.onLanguageReady = this.eventManager.bind('language.ready').subscribe(async (fl: any) => {
      this.renderText();
    });
    this.renderText();
  }

  bodyText: any = {};
  renderText() {
    let screen_name = "reset_password";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
  }

  async newPass(fNewPass: NgForm) {
    if (fNewPass.invalid) { return; }
    if (this.newPassFormData.password1 === this.newPassFormData.password2) {
      this.isLoading = true;
      this.webservice.post("resetpass2?password=" + this.encodeService.encode(this.newPassFormData.password1) + "&code=" + this.code, false, response => {
        if (response.ok && response.email) {
          this.email = response.email;
          this.webservice.get("isuser?email=" + response.email, false, re => {
            if (re.ok) {
              this.updateInfo = { email: this.email, name: re.name };
              this.resetFormVisible = false;
              this.isLoading = false;
            } else {
              this.updateInfo = { error: true };
              this.resetFormVisible = false;
              this.isLoading = false;
            }
          }, error => {
            this.updateInfo = { error: true };
            this.resetFormVisible = false;
            this.isLoading = false;
          });
        }
      }, error => {
        this.updateInfo = { error: true };
        this.resetFormVisible = false;
        this.isLoading = false;
      });
    } else {
      this.formValid = false;
      return;
    }
  }

  continueToLogin(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.navCtrl.navigateRoot(["authentication/email-login", { email: this.updateInfo.email, name: this.updateInfo.name }]);
    this.modalCtrl.dismiss().then(() => { });
  }

  continueResetPass(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.resetFormVisible = true;
  }

  onBackClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.navCtrl.navigateRoot(["authentication/"]);
    this.modalCtrl.dismiss();
  }
}
