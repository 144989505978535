import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from 'angularx-social-login';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { WebserviceService } from '../../../utils/webservice.service';
import { UserApiService } from '../../../utils/user-api.service';
import { FirebaseEventsService } from '../../../utils/firebase-events.service';
import { NetworkService } from '../../../utils/network.service';
import { environment } from '../../../../environments/environment';
import { FacebookEventsHandlerService } from '../../../utils/analytics/facebook-events-handler.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',

})
export class SocialLoginService implements OnDestroy {
  user: SocialUser;
  onAuthStateReceive: any;
  constructor(
    private authService: SocialAuthService,
    private platform: Platform,
    private fb: Facebook,
    private googlePlus: GooglePlus,
    private webservice: WebserviceService,
    private userApiService: UserApiService,
    private firebaseEvents: FirebaseEventsService,
    private networkService: NetworkService,
    private facebookEventsHandler: FacebookEventsHandlerService
  ) {
    /*this.authService.authState.subscribe((user) => {
      this.user = user;
      if (this.onAuthStateReceive) this.onAuthStateReceive(this.user);
    });*/
  }

  private login_start = new Subject<any>();
  onLoginStart = this.login_start.asObservable();

  private login_end = new Subject<any>();
  onLoginEnd = this.login_end.asObservable();

  getCountry() {
    let promise = new Promise((resolve, reject) => {
      let options = {
        responseType: 'text/plain'
      }
      this.webservice.get("country", options, (res) => {
        let countryCode:string = res.split(";")[1];
        resolve(countryCode);
        // resolve('AFG');
      }, (err) => {
        reject("es");
      });
    });
    return promise;

  }


  getLoginStatus(callback: (n: any) => any) {
    if (this.user) {
      //console.log(this.user)
      this.onAuthStateReceive = null;
      callback(this.user);
    } else {
      this.onAuthStateReceive = callback;
    }
  }

  signin(provider: string) {
    this.login_start.next();
    return new Promise((resolve, reject) => {
      this.networkService.getNetworkStatus((re: any) => {
        if(re.isOnline) {
          switch (provider) {
            case "google":
              if (this.platform.is('cordova')) {
                this.nativeGoogleLogin().then((user) => {
                  resolve(user);
                }).catch((err) => {
                  console.log("google login error > ", err);
                  reject(err);
                });
              } else {
                this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
                  console.log("google login pwa ", user);
                  resolve(user);
                }).catch((err) => {
                  console.log(err)
                  reject(err);
                });
              }
              break;
    
            case "facebook":
              if (this.platform.is('cordova')) {
                this.nativeFBLogin().then((user) => {
                  resolve(user);
                }).catch((err) => {
                  reject(err);
                });
              } else {
                this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
                  resolve(user);
                }).catch((err) => {
                  reject(err);
                });
              }
              break;
          }
        } else {
          reject({error: "offline"});
        }
        
      });

      
    });
  }

  signOut(providerCode?: any) {
    return new Promise((resolve, reject)=>{
      try{
        if (this.platform.is('cordova')) {
          if (providerCode == '1') {
            this.googlePlus.logout();
          } else if (providerCode == '2') {
            this.fb.logout();
          }
        } else {
          this.authService.signOut();
        }
        resolve({ok:true});
      } catch(e){
        reject();
      }
    });
  }

  ngOnDestroy() {
    console.log("social login service destroyed");
  }






  /**
   * cordova favebook login
   */
  nativeFBLogin() {
    return new Promise((resolve, reject) => {
      this.fb.login(['public_profile', 'email']).then((res: FacebookLoginResponse) => {
        this.fb.api("/me?fields=id,name,email,first_name,last_name,picture", ["public_profile", "email"]).then((response) => {
          let user = {
            authToken: res.authResponse.accessToken,
            provider: 'facebook',
            email: response.email,
            id: response.id,
            gender: response.gender,
            firstName: response.first_name,
            lastName: response.last_name,
            photoUrl: response.picture.data.url,
            location: response.location
          }
          resolve(user);
        }).catch(e => {
          reject(e);
        });
      }).catch(e => {
        reject(e);
      });
    });
  }


  /**
   * cordova google login
   */
  nativeGoogleLogin() {
    return new Promise((resolve, reject) => {
      let loginConfig = this.platform.is('android') ? { webClientId: "43825857626-1rjburnnrh93n4fk1gdve7gf8sjm3seh.apps.googleusercontent.com", client_secret: "ybIxk0pWvflFV_mmO4yREpU-" } : {};
      try {
        this.googlePlus.login(loginConfig).then(res => {



          let user = {
            authToken: res.accessToken,
            provider: 'google',
            email: res.email,
            id: res.userId,
            name: res.displayName,
            firstName: res.givenName,
            lastName: res.familyName,
            photoUrl: res.imageUrl
          }
          resolve(user);
        }).catch(err => {
          reject(err);
        });
      } catch (e) {
        reject(e);
      }
    });
  }


  initGoogleSignIn() {
    this.signin("google").then((user: any) => {
      if (!user) {
        this.onError("unknown error", "1");
        return;
      }
      console.log(user);
      // get firebase token 
      this.userApiService.getDeviceFBT().then((fbtoken) => {
        // get unique user name
        //let userName = "g"+user.id + "@" + user.provider.toLowerCase() + ".com";
        let userName = user.email;
        this.userApiService.user.username = userName; ////////////////////////////////////////////////////// temp;

        // check if user already exist
        this.webservice.get("isuser?email=" + userName, {}, async response => {
          if (response.ok) {
            if (response.code == 1 && response.language == environment.language) {
              // if user already exist get jwt
              this.webservice.get("getjwt?email=" + userName + "&password=&accesstoken=" + user.authToken + "&fbt=" + fbtoken + "&provider=" + response.code, {}, re => {
                this.firebaseEvents.logEvents("login", { method: user.provider.toLowerCase() });

                const responseData: any = {};
                responseData.jwt = re.jwt;
                responseData.photoUrl = user.photoUrl;
                this.onSuccess(responseData);

              }, err => {
                this.onError(err);
              });
            } else {
              let end_options :any = {
                ok: false,
                change_method: {
                  email: userName,
                  name: response.name,
                  attempt: '1'
                }
              }
              if(response.language != environment.language) {
                end_options.change_method.language = response.language;
                end_options.change_method.change_language = true;
              } else {
                end_options.change_method.code = response.code;
              }
              this.login_end.next(end_options);
            }
          } else {

            // if user already does not exist then create user
            let regOptions: any = {
              token: false,
              data: {
                username: userName,
                password: "",
                accesstoken: user.authToken,
                jwt: "",
                fbt: fbtoken,
                name: user.firstName,
                surname: user.lastName,
                email: userName,
                gender: 'm',
                provider: user.provider.toLowerCase(),
                active: true,
                country: await this.getCountry(),
                language: environment.language,
                created_at: "",
                deleted_at: "",
                last_access: "",
                premium: false,
                receive_mail: true
              }
            }

            this.webservice.post("create", regOptions, response => {
              this.firebaseEvents.logEvents("sign_up", { method: user.provider.toLowerCase() });

              let skipGetUser = true;
              const responseData = regOptions.data;
              responseData.jwt = response.jwt;
              responseData.photoUrl = user.photoUrl;
              this.onSuccess(responseData, skipGetUser);
              this.facebookEventsHandler.logEvent({event: 'fb_emo_complete_registration', params: {method: 'google', currency: '', value: ''}});
            }, error => {
              this.onError(error);
            });

          }
        }, error => {
          this.onError(error);
        });
      }).catch(err => {
        this.onError(err);
      });
    }).catch(err => {
      console.log("google login err >> ", err);
      this.onError(err);
    });
  }

  initFacebookSignIn() {
    this.signin("facebook").then((user: any) => {
      if (!user) {
        this.onError("unknown error", "2");
        return;
      } else if (user && !user.email) {
        this.onError("no_email", "2");
        this.signOut("2");
        return;
      }

      // get firebase token 
      this.userApiService.getDeviceFBT().then((fbtoken) => {
        // get unique user name
        //console.log(user);
        //let userName = "fb" + user.id + "@" + user.provider.toLowerCase() + ".com";
        let userName = user.email;
        this.userApiService.user.username = userName;
        // check if user already exist
        this.webservice.get("isuser?email=" + userName, {}, async response => {
          if (response.ok) {
            if (response.code == 2 && response.language == environment.language) {
              // if user already exist get jwt
              this.webservice.get("getjwt?email=" + userName + "&password=&accesstoken=" + user.authToken + "&fbt=" + fbtoken + "&provider=" + response.code, {}, re => {
                this.firebaseEvents.logEvents("login", { method: user.provider.toLowerCase() });

                const responseData: any = {};
                responseData.jwt = re.jwt;
                responseData.photoUrl = user.photoUrl;
                this.onSuccess(responseData);

              }, err => {
                this.onError(err);
              });
            } else {
              let end_options:any = {
                ok:false,
                change_method: {
                  email: userName,
                  name: response.name,
                  attempt: '2'
                }
              }
              if(response.language != environment.language) {
                end_options.change_method.language = response.language;
                end_options.change_method.change_language = true;
              } else {
                end_options.change_method.code = response.code;
              }
              this.login_end.next(end_options);
            }
          } else {
            // if user already does not exist then create user
            let regOptions: any = {
              token: false,
              data: {
                username: userName,
                password: "",
                accesstoken: user.authToken,
                jwt: "",
                fbt: fbtoken,
                name: user.firstName,
                surname: user.lastName,
                email: userName,
                gender: user.gender || 'm',
                provider: user.provider.toLowerCase(),
                active: true,
                country: user.location || await this.getCountry(),
                language: environment.language,
                created_at: "",
                deleted_at: "",
                last_access: "",
                premium: false,
                receive_mail: true
              }
            }
            this.webservice.post("create", regOptions, response => {
              this.firebaseEvents.logEvents("sign_up", { method: user.provider.toLowerCase() });

              let skipGetUser = true;
              const responseData = regOptions.data;
              responseData.jwt = response.jwt;
              responseData.photoUrl = user.photoUrl;
              this.onSuccess(responseData, skipGetUser);
              this.facebookEventsHandler.logEvent({event: 'fb_emo_complete_registration', params: {method: 'facebook',  currency: '', value: ''}});
            }, error => {
              this.onError(error);
            });
          }
        }, error => {
          this.onError(error);
        });
      }).catch((error) => {
        this.onError(error);
      });
    }).catch((error) => {
      this.onError(error);
    });
  };

  async onSuccess(response: any, skipGetUser?: any) {
    await this.userApiService.onLoginSuccess(response, skipGetUser);
    this.login_end.next({ok:true});
  }

  onError(error: any, method?: any) {
    if (error == "no_email") {
      this.signOut(method);
      this.no_email_facebook.next({ error: "no_email" });
    } else {
      this.signOut(method);
    }
    
    this.login_end.next(error);
  }

  private no_email_facebook = new Subject<any>();
  onNoEmailFacebook = this.no_email_facebook.asObservable();

}
