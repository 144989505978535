import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { SharedDataService } from '../../utils/common/shared-data.service';


@Component({
  selector: 'app-app-debug-mode',
  templateUrl: './app-debug-mode.page.html',
  styleUrls: ['./app-debug-mode.page.scss'],
})
export class AppDebugModePage implements OnInit {
  eventListeners:any = {};
  constructor(
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    private navCtrl: NavController
  ) { }
  
  resetProgress: boolean = false;
  ngOnInit() {
    let debug_config = localStorage.getItem('debug_config');
    try{
      this.sharedDataService.appData.appDebug = JSON.parse(debug_config);
    } catch(e) {
      this.sharedDataService.appData.appDebug = null;
    }

    if(!this.sharedDataService.appData.appDebug) {
      this.sharedDataService.appData.appDebug = {};
      this.sharedDataService.appData.appDebug.disableTutorial = false;
      this.sharedDataService.appData.appDebug.disableShuffling = false;
      this.sharedDataService.appData.appDebug.disableInAppPopup = false;
      this.sharedDataService.appData.appDebug.dragToClick = false;
      
    }
    this.sharedDataService.appData.appDebug.multilingual = this.sharedDataService.settings.multilingual;

    if(this.eventListeners.onAppResetComplete) this.eventListeners.onAppResetComplete.unsubscribe();
    this.eventListeners.onAppResetComplete = this.eventManager.bind('debug.onResetComplete').subscribe(async ()=>{
      this.resetProgress = false;
      this.navCtrl.navigateRoot([""]);
      this.sharedDataService.appData.appDebugPopup.dismiss();
    });
  }

  closeAppDebugPopup() {
    this.sharedDataService.appData.appDebugPopup.dismiss();
  }

  onDebugOptionUpdate() {
    localStorage.setItem('debug_config', JSON.stringify(this.sharedDataService.appData.appDebug));
    this.sharedDataService.settings.multilingual = this.sharedDataService.appData.appDebug.multilingual;
  }

  resetApp() {
    this.resetProgress = true;
    this.eventManager.trigger('debug.resetApp');
  }
}
