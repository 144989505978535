import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private nav_from_subscribe_modal = new Subject<any>();

  settings : any = {
    multilingual: false,
    voiceRecognationAvailable: true,
    videoSeekPreview: true
  }
  appData : any = {
    currentLesson: null,
    currentPath: null,
    progress: 0,
    trialLeftInSec: 0
  };

  popups: any = {};

  appEvent : any = {
    onNavFromSubscriptionModal : this.nav_from_subscribe_modal.asObservable()
  }

  constructor(
    private platform: Platform
  ) { 

  }

  navFromSubscribeModal() {
    this.nav_from_subscribe_modal.next();
  }

  reset() {

  }

}
