import { Injectable } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Platform } from '@ionic/angular';
import { WebserviceService } from './webservice.service';
import { AppStorageService } from './app-storage.service';
import { DevDataService } from "./dev-data.service";
import { ContentDownloadService } from "./content-download.service";
import { NetworkService } from './network.service';
import { FirebaseEventsService } from './firebase-events.service';
import { SharedDataService } from './common/shared-data.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { EventManagerService } from './common/event-manager.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserApiService {
  verifyPromptCounter: number = 0;
  networkChangeListener: Subscription;
  eventListeners:any = {
    saveFlag: null
  };

  dataSyncInterval:any;
  // user should be a private variable later on
  user: any = {};
  constructor(
    private appStorageService: AppStorageService,
    private webservice: WebserviceService,
    private authService: SocialAuthService,
    private fb: Facebook,
    private googlePlus: GooglePlus,
    private platform: Platform,
    private appDataService: DevDataService,
    private networkService: NetworkService,
    private contentDownloadService: ContentDownloadService,
    private firebaseService: FirebaseEventsService,
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    private localNotifications: LocalNotifications
  ) {
    if (this.networkChangeListener) this.networkChangeListener.unsubscribe();
    this.networkChangeListener = this.networkService.onNetworkChange.subscribe((re: any) => {
      this.forceSync = true;
      this.syncCourse();
    });
    this.setDatasyncInterval();


    // save user flag in server
    this.eventListeners.saveFlag = this.eventManager.bind('user.saveFlag').subscribe(async (flag:any)=>{
      if(this.user && this.user.jwt) {
        let op = { token: this.user.jwt, data: {flags: flag}};
        this.webservice.post("setflags", op, async (res) => {this.setUser({jwt: this.user.jwt}).then((re)=>{});});
      }
    });

    // set user flag in session
    this.eventListeners.userUpdate = this.eventManager.bind('user.update').subscribe(async (user:any)=>{
      this.eventManager.trigger('user.setCurrentFlag', (user.deleted_at ? user.deleted_at : 0));
    });


    this.eventListeners.onAppReset = this.eventManager.bind('debug.resetApp').subscribe(async ()=>{
      await this.logoutUser({skipSync:true});
      await this.appStorageService.reset();
      localStorage.clear();
      this.eventManager.trigger('debug.onResetComplete');
    });
  }

  private on_user_update = new Subject<any>();
  onUserUpdate = this.on_user_update.asObservable();
  immidiateAfterLogin: boolean = false;
  async onLoginSuccess(loginResponse: any, skipGetUser?:any) {
    this.immidiateAfterLogin = true;
    let loginSuccess = new Promise((resolve, reject) => {
      try {
        this.setUser(loginResponse, skipGetUser).then(() => {
          this.forceSync = true;
          this.syncCourse();
          resolve();
        }).catch((e) => {
          reject(e);
        });
      } catch (e) {
        reject(e);
      }
    });
    return loginSuccess;
  }


  async setUser(options: any, skipGetUser?:any) {
    let promise = new Promise((resolve, reject) => {
      try {
        let getUserData = {
          token: options.jwt
        }
        this.user.jwt = options.jwt;
        this.webservice.get('getuser', getUserData, (response) => {
          const userData = response.data;
          this.user.username = userData.username;
          this.user.password = userData.password;
          this.user.accesstoken = userData.accesstoken;
          this.user.fbt = userData.fbt;
          this.user.name = userData.name;
          this.user.surname = userData.surname;
          this.user.email = userData.email;
          this.user.gender = userData.gender;
          this.user.active = userData.active;
          this.user.country = userData.country;
          this.user.language = userData.country ? userData.language : environment.language,
          this.user.created_at = userData.created_at;
          this.user.deleted_at = userData.deleted_at;
          this.user.last_access = userData.last_access;
          this.user.receive_mail = userData.receive_mail ? userData.receive_mail : false;
          this.user.premium = userData.premium ? userData.premium : false;
          this.user.suscription = userData.suscription ? userData.suscription : {};
          this.user.trial_expiry_time = userData.trial_expiry_time ? userData.trial_expiry_time : false;
          let now = Math.round(new Date().getTime()/1000);
          this.user.free_trial = this.user.trial_expiry_time ? this.user.trial_expiry_time < now ? false : true : false;
          this.user.provider = userData.provider ? userData.provider : "";

          userData.photoUrl ? this.user.photoUrl = userData.photoUrl : options.photoUrl ? options.photoUrl : () => { };
          
          this.appStorageService.setWithPromise("user", this.user).then(() => {
            resolve();
            this.on_user_update.next({ok:true, user: this.user});
            this.eventManager.trigger('user.update', this.user);
          }).catch((e) => {
            reject(e);
            this.on_user_update.next({ok:false});
            this.eventManager.trigger('user.update', this.user);
          });
        }, (error) => {
          console.log(error);
        });
      } catch (e) {
        reject(e);
      }

    });
    return promise;
  }


  resetJWT(skipPending?:boolean) {
    return new Promise((resolve, reject)=>{
      let providerCode = this.user.provider == "email" ? 0 : this.user.provider == "google" ? 1 : this.user.provider == "facebook" ? 2: 3;
        this.webservice.get("getjwt?email=" + this.user.email + "&password=" + this.user.password + "&accesstoken="+this.user.accesstoken + "&fbt="+this.user.fbt+"&provider="+providerCode, {}, response => {
          if (response.jwt) {
            let successRes:any = {};
            successRes.jwt = response.jwt;
            successRes.email = this.user.email;
            this.onLoginSuccess(successRes).then(()=>{
              let data = {
                jwt : successRes.jwt
              }
              if(!skipPending) this.webservice.processPendingRequest(data);
            });
          } else { }
          resolve();
        }, error => {
          console.log(error);
          reject();
        });
    })
  }
  

  private on_trial_start = new Subject<any>();
  onTrialStart = this.on_trial_start.asObservable();
  startTrialPeriod(){
    return new Promise((resolve, reject)=> {
      if(this.user && this.user.jwt && this.user.active) {
        let reqOption = {
          token: this.user.jwt,
          data: {}
        }
        this.webservice.post("trialaccepted", reqOption, async (response) => {
          if(response.ok) {
            let opt = {
              jwt: response.token
            }
            this.setUser(opt).then((re)=>{
              this.on_trial_start.next();
              resolve(this.user);
            }).catch((err)=>{
              reject(err);
              console.log(response);
            });
          } else {
            reject(response);
          }
        }, error => {
          reject(error);
          console.log(error);
        });
      }
    });
  }

  private cancelReminder() {
    if(this.platform.is("cordova")) {
      this.localNotifications.cancelAll();
    }
  }

  expireFreeTrial() {
    return new Promise((resolve, reject)=> {
      this.user.free_trial = false;
      this.setUser(this.user).then(() => {
        if(!this.user.premium) this.cancelReminder();
        this.eventManager.trigger('user.trialExpired', this.user);
        resolve(false);
      }).catch((e) => {
        this.cancelReminder();
        this.eventManager.trigger('user.trialExpired', this.user);
        reject(e);
      });
    });
  }



  /**
  * error codes:
  * 1: cannot set user
  * 2: webservice call fail
  * 3: not logged in
  * 4: user mismatch
  * 5: not an user
  */

 private veryfying_user = new Subject<any>();
 onVeryfyingUser = this.veryfying_user.asObservable();

  async verifyUser(options: any) {
    this.veryfying_user.next();
    let promise = new Promise((resolve, reject) => {
      //if(this.platform.is('cordova')) {
      this.getUser().then((user: any) => {
        if ((user.email && user.email == options.email)) {
          let postData: any = {
            token: false,
            data: {
              code: options.code,
              email: options.email
            }
          };
          let wasConfirmed = user.active;
          this.webservice.post("confirm", postData, response => {
            if (response.ok) {
              let setOption: any = {
                jwt: user.jwt,
                email: user.email
              }
              this.setUser(setOption).then(() => {
                let opt = {
                  user: this.user,
                  isUnique: wasConfirmed ? false : true
                }
                resolve(opt);
              }).catch(() => {
                reject(1);
              });
            } else {
              reject(2);
            }
          }, (error: any) => {
            reject(2);
          });
        } else {
          if (!user.email) {
            reject(3);
          } else {
            reject(4);
          }
        }
      }).catch((er) => {
        reject(3);
      });
      //} else {
      /*let postData: any = {
        token: false,
        data: {
          code: options.code,
          email: options.email
        }
      };
      this.webservice.post("confirm", postData, response => {
        console.log(response)
        if(response.message == "account confirmed") {
          resolve({email: options.email, active: true});
        } else {
          reject(5);
        }
      }, (error: any) => {
        reject(5);
      });*/
      //}
    });
    return promise;
  }





  async getUser() {
    let promise = new Promise((resolve, reject) => {
      try {
        this.appStorageService.getWithPromise("user").then((user) => {
          if (user) {
            this.user = user;
            resolve(user);
          } else {
            reject("No user found");
          }
        }).catch((e) => {
          reject(e);
        });
      } catch (e) {
        reject(e);
      }
    });
    return promise;
  }

  async updateUser(options: any) {

    let promise = new Promise((resolve, reject) => {
      try {

        if (options.email) {
          let postData: any = {
            token: this.user.jwt,
            data: {
              email: options.email,
              fbt: "abcd"
            }
          };
          this.webservice.post("changeMail", postData, response => {
            //console.log(response);
            if (!response.ok) {
              reject(response.ok);
            } else {
              let setOption: any = {
                jwt: response.jwt,
                email: options.email
              }
              this.setUser(setOption).then(() => {
                resolve();
              });
            }

          }, (error: any) => {
            console.log(error);
          });
        } else if (options.name || options.surname || options.country || options.hasOwnProperty("receive_mail")) {

          let postData: any = {
            token: this.user.jwt,
            data: {}
          };
          options.name ? postData.data.name = options.name : () => { };
          options.surname ? postData.data.surname = options.surname : () => { };
          options.country ? postData.data.country = options.country : () => { };
          options.hasOwnProperty("receive_mail") ? postData.data.receive_mail = options.receive_mail : () => { };

          this.webservice.post("updateuser", postData, response => {
            if (response.ok) {
              let setOption: any = {
                jwt: this.user.jwt,
                email: this.user.email
              }
              this.setUser(setOption).then(() => {
                resolve();
              });
            } else {
              reject(response.ok);
            }
          }, (error: any) => {
            console.log(error);
          });
        } else if (options.password) {
          this.user.password = options.password;
          let postData: any = {
            token: this.user.jwt,
            data: {
              password: options.password
            }
          };
          this.webservice.post("changepass", postData, response => {
            if (response.ok) {
              console.log(response);
              let setOption: any = {
                jwt: this.user.jwt,
                email: this.user.email
              }
              this.setUser(setOption).then(() => {
                this.firebaseService.logEvents("password_changed", this.user);
                resolve();
              });
            } else {
              reject(response.ok);
            }
          }, (error: any) => {
            console.log(error);
          });

        } else {
          console.log("here in no result");
        }
      } catch (e) {
        reject(e);
      }
    });
    return promise;
  }

  resetUser() {
    this.user = {
      username: <string>null,
      password: <string>null,
      jwt: <string>null,
      fbt: <string>null,
      name: <string>null,
      surname: <string>null,
      email: <string>null,
      gender: <string>null,
      provider: <string>null,
      active: <boolean>null,
      country: <string>null,
      created_at: <string>null,
      deleted_at: <string>null,
      last_access: <string>null,
      photoUrl: <string>null
    };
    this.appDataService.loadCourse();
  }

  async resetSectionActivity(lessonid?: any) {

  }

  async setSectionActivity(sectionid: any, options: any) {
    let promise = new Promise((resolve, reject) => {
      this.getSectionActivity().then((data: any) => {
        Object.keys(options).forEach(key => {
          if (!data[sectionid]) data[sectionid] = {};
          data[sectionid][key] = options[key];
        });
        this.appStorageService.set({ "sectionActivity": data }, function (re) {
          resolve();
        });
      }).catch(() => {
        try {
          let d: any = {};
          d[sectionid] = options;
          this.appStorageService.set({ "sectionActivity": d }, function (re) {
            resolve();
          });
        } catch (e) {
          reject();
        }
      });
    });
    return promise;
  }


  async getSectionActivity(sectionid?: any) {
    let promise = new Promise((resolve, reject) => {
      this.appStorageService.get("sectionActivity").then((data: any) => {
        // console.log("section activity data > ", data);
        sectionid ? resolve(data[sectionid]) : resolve(data);
      }).catch((e) => {
        // console.log("section activity er > ", e);
        reject(e);
      });
    });
    return promise;
  }


  async setCurrentLevel(levelId) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.appStorageService.set({ "currentLevel": levelId }, function (re) {
          resolve();
        });
      } catch (e) {
        reject();
      }
    });
    return promise;
  }

  async getCurrentLevel() {
    let promise = new Promise((resolve, reject) => {
      try {
        this.appStorageService.get("currentLevel").then((index) => {
          resolve(index);
        }).catch(() => {
          resolve(0);
        });
      } catch (e) {
        resolve(0);
      }
    });
    return promise;
  }

  async setDownloadSettings(settings:any) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.appStorageService.set({ "downloadSettings": settings }, function (re) {
          resolve();
        });
      } catch (e) {
        reject();
      }
    });
    return promise;
  }

  async getDownloadSettings() {
    let promise = new Promise((resolve, reject) => {
      this.appStorageService.get("downloadSettings").then((settings:any) => {
        resolve(settings);
      }).catch(() => {
        resolve({wifi:false});
      });
    });
    return promise;
  }

  async setExerSettings(options) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.appStorageService.set({ "exerSettings": options }, function (re) {
          resolve();
        });
      } catch (e) {
        reject();
      }
    });
    return promise;
  }

  async getExerSettings() {
    let promise = new Promise((resolve, reject) => {
      try {
        this.appStorageService.get("exerSettings").then((settings) => {
          resolve(settings);
        }).catch(() => {
          resolve(0);
        });
      } catch (e) {
        resolve(0);
      }
    });
    return promise;
  }


  async logoutUser(options:{skipSync:boolean} = {skipSync:false}) {
    let promise = new Promise(async (resolve, reject) => {
      if(!options.skipSync) {
        this.forceSync = true;
        await this.syncCourse();
      }
      this.courseResumed = false;
      this.appStorageService.removeWithPromise("user").then(async () => {
        await this.appStorageService.removeWithPromise("appData");
        this.courseSyncedOnce = false;
        if (this.user.provider == "facebook" || this.user.provider == "google") {
          if (this.platform.is('cordova')) {
            if (this.user.provider == "facebook") {
              this.fb.logout().then(() => {
                resolve(null);
                this.resetUser();
                this.contentDownloadService.remove();
              }).catch(() => {
                resolve(null);
                this.contentDownloadService.remove();
              });
            } else if (this.user.provider == "google") {
              this.googlePlus.logout().then(() => {
                resolve(null);
                this.resetUser();
                this.contentDownloadService.remove();
              }).catch(() => {
                resolve(null);
                this.contentDownloadService.remove();
              });
            }
          } else {
            this.authService.signOut().then(() => {
              resolve();
              this.resetUser();
              this.contentDownloadService.remove();
            }).catch(() => {
              resolve();
              this.contentDownloadService.remove();
            });
          }
        } else {
          resolve();
          this.resetUser();
          this.contentDownloadService.remove();
        }
      }).catch((e) => {
        reject(e);
      });
      
    });
    return promise;
  }



  private deviceFBT:any=null;
  async getDeviceFBT() {
    let promise = new Promise((resolve, reject) => {
      try {
        if(this.deviceFBT) {
          console.log("Firebase token ", this.deviceFBT);
          resolve(this.deviceFBT);
        } else {
          this.firebaseService.getToken().then(token => {
            this.deviceFBT = token;
            console.log("Firebase token ", token);
            resolve(token);
          }).catch(error => {
            reject(false);
          });
        }
      } catch (e) {
        reject(false);
      }
    });

    return promise;
  }

  private veryfy_prompt = new Subject<any>();
  onVeryfyPrompt = this.veryfy_prompt.asObservable();

  showVeryfyPrompt() {
    if (!this.user || !this.user.email) {
      this.veryfy_prompt.next(false);
    } else if (this.user && !this.user.active && this.user.provider == "email") {
      this.veryfy_prompt.next(this.user);
    }
  }


  async saveProgress(nodeList: Array<any>, updateData?: any) {
    let promise = new Promise((resolve, reject) => {
      try {
        if (this.user && this.user.email && this.user.active) {
          this.verifyPromptCounter = 0;
          this.appStorageService.get(this.user.email).then((data) => {
            let userData: any = data;
            if (!userData) userData = {};
            if (!userData.progress) userData.progress = {};
            if (!userData.pending) userData.pending = {};
            if (nodeList.length > 0) {
              for (let i = 0; i < nodeList.length; i++) {
                let node = this.appDataService.getNode(nodeList[i]);
                
                if(node.progress.hasOwnProperty('passed') && node.progress.hasOwnProperty('ts')){
                  userData.progress[node.id] = { "progress": node.progress };
                  userData.pending[node.id] = { "progress": node.progress };
                } else if(node.progress.hasOwnProperty('duration') && node.progress.hasOwnProperty('ts')) {
                  userData.progress[node.id] = { "progress": node.progress };
                  userData.pending[node.id] = { "progress": node.progress };
                } else {
                  userData.progress[node.id] = { "progress": node.progress };
                  userData.pending[node.id] = { "progress": node.progress };
                }
              }
            } else if (updateData) {
              userData.progress = updateData.prog;
              userData.lastUpdate = updateData.lastUpdate;
            }
            let obj: any = {};
            obj[this.user.email] = userData;
            this.appStorageService.set(obj, (re)=> {
              console.log(JSON.parse(re));
              resolve();
            });
          }).catch((e) => {
            reject();
          });
        } else {
          if (this.verifyPromptCounter == 5) {
            this.verifyPromptCounter = 0;
            if (!this.user || !this.user.email) {
              this.veryfy_prompt.next(false);
            } else if (this.user && !this.user.active && this.user.provider == "email") {
              this.veryfy_prompt.next(this.user);
            }
          } else {
            this.verifyPromptCounter++;
          }
          reject();
        }
      } catch (e) {
        reject(false);
      }
    });
    return promise;
  }

  saveInServer() {
    let promise = new Promise((resolve, reject) => {
      let _ts = this.appDataService.nowTimestamp();
      this.syncCourse(_ts);
      resolve();
    });
    return promise;
  }

  private syncWithServer(ts, postData){
    console.log("in sync with server", Object.keys(postData));
    let promise = new Promise((resolve, reject) => {
      if(Object.keys(postData).length == 0) {
        resolve();
      } else {
        this.webservice.post("progress?ts=" + ts, postData, response => {
          console.log("sync with server response ", response);
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
      }

      


    });
    return promise;
  }

  canSyncWithServer(userData:any) {
    let canSync = false;
    // console.log("if > ", (!this.immediateSync && Object.keys(userData.pending).length > 0 && Object.keys(userData.pending).length < 10));
    // console.log("else 1 > ", (Object.keys(userData.pending).length >= 10));
    // console.log("else 2 > ", this.immediateSync, userData.pending, Object.keys(userData.pending).length);
    // console.log("else 3 > ", this.forceSync);

    if(!this.forceSync && !this.immediateSync && Object.keys(userData.pending).length > 0 && Object.keys(userData.pending).length < 10) {
      for(let key in userData.pending) {
        if(userData.pending[key].progress.passed === true || userData.pending[key].progress.passed === false) {
          canSync = true;
          break;
        }
      }
    } else if(Object.keys(userData.pending).length >= 10 || (this.immediateSync && Object.keys(userData.pending).length > 0) || this.forceSync) {
      canSync = true;
    }
    return canSync;
  }
  
  retriveProgress(now?:any) {
    let promise = new Promise((resolve, reject) => {
      try {
        if (this.user && this.user.email && this.user.active) {
          this.appStorageService.get(this.user.email).then((data) => {
            let self = this;
            let userData: any = data;
            if (!userData) userData = {};
            if (!userData.progress) userData.progress = {};
            if (!userData.pending) userData.pending = {};
            userData.lastUpdate = now ? now : userData.lastUpdate ? userData.lastUpdate : '198001010000.00';
            console.log("will check if can sync with server");
            if(this.canSyncWithServer(userData)) {
              console.log("can sync with server")

              let postData: any = {
                token: this.user.jwt,
                data: userData.pending
              };
              this.syncWithServer(userData.lastUpdate, postData).then((serverdata:any) => {
                let obj: any = {};
                userData.pending = null;
                obj[self.user.email] = userData;
                self.setDatasyncInterval();
                if (serverdata && serverdata.ok) {
                  self.appStorageService.set(obj, function (re) {
                    userData.lastUpdate = serverdata.lts;
                    self.compareProgress(userData.progress, serverdata.list).then((prog) => {
                      let updateData = {
                        prog: prog,
                        lastUpdate: userData.lastUpdate,
                        size: serverdata.size
                      }
                      resolve(updateData);
                    });
                  });
                } else {
                  reject();
                }
              }).catch((err) => {
                self.compareProgress(userData.progress, []).then((prog:any) => {
                  prog.error = err;
                  resolve(prog);
                  console.log("detected progress with exception: ", err, prog);
                });
              });
            } else {
              console.log("cant sync with server")
              reject();
            }
          }).catch((e) => {
            console.log("Error 3: ", e);
            reject();
          });
        } else {
          console.log("Error 2: ");
          reject();
        }
      } catch (e) {
        console.log("Error 1: ", e);
        reject(false);
      }
    });
    return promise;
  }


  compareProgress(local: any, remote: any) {
    let promise = new Promise((resolve, reject) => {
      if (remote) {
        if (!local) {
          local = remote;
        } else {
          for (let i in remote) {
            if (!local[i]) {
              local[i] = remote[i];
            } else if (remote[i].progress.ts >= local[i].progress.ts) {
              if (remote[i].progress.passed !== local[i].progress.passed) {
                local[i] = remote[i];
              } else if (remote[i].progress.played != undefined) {
                if (remote[i].progress.played !== local[i].progress.played) {
                  local[i] = remote[i];
                }
              }
            } else { }
          }
        }
      }
      resolve(local);
    });
    return promise;
  }

  private sync_complete = new Subject<any>();
  onSyncComplete = this.sync_complete.asObservable();
  
  getNewest: boolean = true;
  newestNode:any=null;
  resumeNode: any = null;
  courseResumed: boolean = false;
  courseSyncedOnce: boolean = false;




  syncCourse(now?:any) {
    let promise = new Promise((resolve, reject) => {
      this.sharedDataService.appData.resumeNode = null;
      this.retriveProgress(now).then(async (updateData: any) => {
        this.sharedDataService.appData.progress = updateData.size;
        if (updateData.prog) {
          let latest = 0;
          for (let p in updateData.prog) {
            let tmp, tmpParent, tmpSection;
            if(this.appDataService.storageType == "object") {
              tmp = this.appDataService.devDataStorage[p];
              tmp = JSON.parse(tmp);
              tmpParent = this.appDataService.devDataStorage[tmp.fatherid];
              tmpParent = JSON.parse(tmpParent);
              tmpParent = this.appDataService.devDataStorage[tmpParent.fatherid];
              tmpParent = JSON.parse(tmpParent);
              tmpSection = this.appDataService.devDataStorage[tmpParent.fatherid];
              tmpSection = JSON.parse(tmpSection);
            } else {
              tmp = localStorage.getItem(p);
              tmp = JSON.parse(tmp);
              tmpParent = localStorage.getItem(tmp.fatherid);
              tmpParent = JSON.parse(tmpParent);
              tmpParent = localStorage.getItem(tmpParent.fatherid);
              tmpParent = JSON.parse(tmpParent);
              tmpSection = localStorage.getItem(tmpParent.fatherid);
              tmpSection = JSON.parse(tmpSection);
            }

            if (tmp.subtype == "exercise") {
              if (updateData.prog[p].progress.passed) {
                this.appDataService.passExercise(p, updateData.prog[p].progress.ts);
              } else if (!updateData.prog[p].progress.passed && updateData.prog[p].progress.passed === false) {
                this.appDataService.failExercise(p, updateData.prog[p].progress.ts);
              } else if (!updateData.prog[p].progress.passed && updateData.prog[p].progress.passed === null) {
                this.appDataService.skipExercise(p, updateData.prog[p].progress.ts);
              } else {
                this.appDataService.resetExercise(p, updateData.prog[p].progress.ts);
              }
            } else if (tmp.subtype == "grammar") {
              if (updateData.prog[p].progress.passed) {
                this.appDataService.passGrammar(p, updateData.prog[p].progress.ts);
              } else {
                this.appDataService.resetGrammar(p, updateData.prog[p].progress.ts);
              }
            } else if (tmp.subtype == "video") {
              let played = updateData.prog[p].progress.played;
              this.appDataService.setVideoPlaytime(p, played, updateData.prog[p].progress.ts);
            }
            
            if(this.getNewest && updateData.prog[p].progress.ts >= latest && tmpSection.subtype != "vocab_level") {
              latest = updateData.prog[p].progress.ts;
              this.newestNode = p;
            } else if(!this.getNewest) {
              this.newestNode = null;
              this.resumeNode = null;
            }
          }
        }

        // this.getNewest = false;
        await this.saveProgress([], updateData).catch(()=>{
          console.log("not saved successfully");
        });
        if(this.newestNode) {
          let _node = this.appDataService.getNode(this.newestNode);
          if(_node.subtype == 'video') {
            let _fatherNode = this.appDataService.getNode(_node.fatherid);
            if(_fatherNode.subtype == 'test') this.resumeNode = {id: this.newestNode}
            else this.resumeNode = this.appDataService.bigAncestors(this.newestNode);
          } else this.resumeNode = this.appDataService.bigAncestors(this.newestNode);
        }
        this.sharedDataService.appData.resumeNode = this.resumeNode;
        this.sync_complete.next(this.resumeNode);
        this.newestNode = null;
        this.resumeNode = null;
        this.immediateSync = false;
        this.forceSync = false;
        this.courseSyncedOnce = true;
        if(updateData.error) {
          resolve(updateData.error);
        } else {
          resolve(null);
        }
        console.log("syc complete")
      }).catch((e) => {
        // this.getNewest = false;
        this.immediateSync = false;
        this.forceSync = false;
        this.sync_complete.next();
        resolve();
        console.log("syc complete")
      });
    });
    
    return promise;
  }

  forceSync:boolean = false;
  immediateSync:boolean = false;
  setDatasyncInterval() {
    clearInterval(this.dataSyncInterval);
    this.dataSyncInterval = setInterval(()=>{
      this.immediateSync = true;
      this.syncCourse();
    }, 60000);
  }
}
