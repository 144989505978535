import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { EventManagerService } from '../../utils/common/event-manager.service';
import { SharedDataService } from '../../utils/common/shared-data.service';
import { InterfaceTextService } from '../../utils/interface-text.service';
import { WebElementfocusService } from '../../utils/common/web-elementfocus.service';
import { FacebookEventsHandlerService } from '../../utils/analytics/facebook-events-handler.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.page.html',
  styleUrls: ['./plan-list.page.scss'],
})
export class PlanListPage implements OnInit {
  @Input() options: any;
  private eventListeners: any = {};
  bodyText: any = {};
  layoutVersion:any = null;
  constructor(
    private eventManager: EventManagerService,
    public sharedDataService: SharedDataService,
    private modalCtrl: ModalController,
    private interfaceText: InterfaceTextService,
    private webElementfocusService: WebElementfocusService,
    private platform: Platform,
    private facebookEventsHandler: FacebookEventsHandlerService
  ) { }
  purchaseButtonConfig: Array<any>;
  isAndroid:boolean = false;
  ngOnInit() {
    this.layoutVersion = environment.layoutVersion;
    this.isAndroid = this.platform.is('android');
    this.purchaseButtonConfig = this.options;
    this.purchaseButtonConfig.sort((a, b) => (a.given_index > b.given_index) ? 1 : ((b.given_index > a.given_index) ? -1 : 0));
    this.purchaseButtonConfig.forEach(b => {
      b.discount = this.getDiscountAmount(b);
      b.price = b.amount + b.currency;
    });
  }

  ionViewWillEnter() {
    this.renderText();
  }

  renderText() {
    let screen_name = "plans_list";
    this.bodyText = this.interfaceText.getBodyText(screen_name);
    console.log(this.bodyText)
  }

  private basePrice: any;
  private getDiscountAmount(b: any) {
    if (!this.basePrice) this.purchaseButtonConfig.forEach(_b => {
      if (_b.duration_info.interval == 1) this.basePrice = _b.amount / _b.duration_info.interval;
    });
    let tp = b.amount / b.duration_info.interval;
    let tmpval: any = (((tp - this.basePrice) / this.basePrice) * 100).toFixed(1);
    tmpval = Math.round(tmpval) !== 0 ? (tmpval + "%") : ""
    return tmpval;
  }

  popupCancelled(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.webElementfocusService.setFocusTo("subscriptionSubscribeButton")
    this.sharedDataService.appData.planListPopup.dismiss();
  }

  async subscribe(config: any) {
    await this.sharedDataService.appData.planListPopup.dismiss();
    this.facebookEventsHandler.logEvent({event: 'fb_emo_initiated_checkout', params: config});
    this.eventManager.trigger('purchase.subscribeClicked', config);
    // this.dismissAllModal();
  }
  private async dismissAllModal() {
    let modal = await this.modalCtrl.getTop();
    if(modal) {
      await modal.dismiss();
      this.dismissAllModal();
    }
    
  }
}
