import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

declare let $: any;

@Injectable({
  providedIn: 'root'
})

export class WebElementfocusService {

  KEY_CODE = {
    RIGHT_ARROW: 'ArrowRight',
    LEFT_ARROW: 'ArrowLeft',
    UP_ARROW: 'ArrowUp',
    DOWN_ARROW: 'ArrowDown',
    ENTER: 'Enter',
    // TAB: 'Tab'
  }

  elementFocusMap = {
    "exerciseCloseX": {
      "tabIndex": "tabIndex1",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "DOWN_ARROW": "--"
      },
    },
    "exerciseOptions": {
      "tabIndex": "tabIndex2",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "UP_ARROW": "exerciseCloseX"
      },
    },
    "exerciseAyuda": {
      "tabIndex": "tabIndex3",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "LEFT_ARROW": "exerciseOptions",
        "ENTER": "exerciseOpenGrammar"
      },
    },
    "exerciseSkip": {
      "tabIndex": "tabIndex4",
      "positionsMap": {
        "RIGHT_ARROW": "exerciseCheck",
        "LEFT_ARROW": "--"
      },
    },
    "exerciseCheck": {
      "tabIndex": "tabIndex5",
      "positionsMap": {
        "LEFT_ARROW": "exerciseSkip"
      },
    },
    "exerciseCloseXTip": {
      "tabIndex": "tabIndex6",
      "positionsMap": {
        "RIGHT_ARROW": "exerciseOpenGrammar",
        "ENTER": "exerciseAyuda"
      },
    },
    "exerciseOpenGrammar": {
      "tabIndex": "tabIndex7",
      "positionsMap": {
        "LEFT_ARROW": "exerciseCloseXTip"
      },
    },
    "exerciseSettingsTranslation": {
      "tabIndex": "tabIndex8",
      "positionsMap": {
        "DOWN_ARROW": "--"
      },
    },
    "exerciseSettingsAudio": {
      "tabIndex": "tabIndex9",
      "positionsMap": {
        "DOWN_ARROW": "--"
      },
    },
    "exerciseSettingsCloseButton": {
      "tabIndex": "tabIndex111",
      "positionsMap": {
        "UP_ARROW": "--"
      },
    },
    "LessonMenuCloseX": {
      "tabIndex": "tabIndex10",
      "positionsMap": {
        "DOWN_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenuPrevButton": {
      "tabIndex": "tabIndex11",
      "positionsMap": {
        "UP_ARROW": "LessonMenuCloseX",
        "RIGHT_ARROW": "--"
      },
    },
    "LessonMenuNextButton": {
      "tabIndex": "tabIndex12",
      "positionsMap": {
        "LEFT_ARROW": "--",
      },
    },
    "LessonMenuVideoButton": {
      "tabIndex": "tabIndex13",
      "positionsMap": {
        "UP_ARROW": "LessonMenuSpeakingIcon",
        "RIGHT_ARROW": "LessonMenuGrammarButton",
        "DOWN_ARROW": "LessonMenuExerciseButton",
        "LEFT_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenuGrammarButton": {
      "tabIndex": "tabIndex14",
      "positionsMap": {
        "UP_ARROW": "LessonMenuVocabularyIcon",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "DOWN_ARROW": "LessonMenuExerciseButton",
        "LEFT_ARROW": "LessonMenuVideoButton"
      },
    },
    "LessonMenuExerciseButton": {
      "tabIndex": "tabIndex15",
      "positionsMap": {
        "UP_ARROW": "LessonMenuVideoButton",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenulisteningIcon": {
      "tabIndex": "listening",
      "positionsMap": {
        "DOWN_ARROW": "LessonMenuSpeakingIcon",
        "RIGHT_ARROW": "LessonMenuWritingIcon",
        "LEFT_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenuWritingIcon": {
      "tabIndex": "writing",
      "positionsMap": {
        "DOWN_ARROW": "LessonMenuSpeakingIcon",
        "RIGHT_ARROW": "LessonMenuReadingIcon",
        "LEFT_ARROW": "LessonMenulisteningIcon"
      },
    },
    "LessonMenuReadingIcon": {
      "tabIndex": "reading",
      "positionsMap": {
        "DOWN_ARROW": "LessonMenuVocabularyIcon",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuWritingIcon"
      },
    },
    "LessonMenuSpeakingIcon": {
      "tabIndex": "speaking",
      "positionsMap": {
        "DOWN_ARROW": "LessonMenuVideoButton",
        "RIGHT_ARROW": "LessonMenuVocabularyIcon",
        "LEFT_ARROW": "LessonMenuPrevButton",
        "UP_ARROW": "LessonMenulisteningIcon"
      },
    },
    "LessonMenuVocabularyIcon": {
      "tabIndex": "vocabulary",
      "positionsMap": {
        "DOWN_ARROW": "LessonMenuGrammarButton",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuSpeakingIcon",
        "UP_ARROW": "LessonMenuWritingIcon"
      },
    },
    "LessonMenuSubscribeButton": {
      "tabIndex": "tabIndex20",
      "positionsMap": {
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuPrevButton",
      },
    },
    "LessonMenubbcStoryButton": {
      "tabIndex": "tabIndex21",
      "positionsMap": {
        "DOWN_ARROW": "--",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenuStartTestButton": {
      "tabIndex": "tabIndex22",
      "positionsMap": {
        "UP_ARROW": "LessonMenubbcStoryButton",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenuRepeatButton": {
      "tabIndex": "tabIndex23",
      "positionsMap": {
        "UP_ARROW": "LessonMenubbcStoryButton",
        "RIGHT_ARROW": "LessonMenuNextTestButton",
        "LEFT_ARROW": "LessonMenuPrevButton"
      },
    },
    "LessonMenuNextTestButton": {
      "tabIndex": "tabIndex24",
      "positionsMap": {
        "UP_ARROW": "LessonMenubbcStoryButton",
        "RIGHT_ARROW": "exerciseCloseXTip",
        "LEFT_ARROW": "LessonMenuRepeatButton",
        "ENTER": "LessonMenuNextButton"
      },
    },
    "LessonMenuResumeButton": {
      "tabIndex": "tabIndex25",
      "positionsMap": {
        "UP_ARROW": "LessonMenubbcStoryButton",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuPrevButton",
      },
    },
    "LessonMenuImproveScoreButton": {
      "tabIndex": "tabIndex26",
      "positionsMap": {
        "UP_ARROW": "LessonMenubbcStoryButton",
        "RIGHT_ARROW": "LessonMenuNextButton",
        "LEFT_ARROW": "LessonMenuPrevButton",
      },
    },
    "grammarCloseX": {
      "tabIndex": "tabIndex27",
      "positionsMap": {},
    },

    "videoCloseButton": {
      "tabIndex": "tabIndex28",
      "positionsMap": {
        "DOWN_ARROW": "videoBackwardButton",
        "RIGHT_ARROW": "videoToggleSpeedButton"
      },
    },
    "videoBackwardButton": {
      "tabIndex": "tabIndex30-1",
      "positionsMap": {
        "UP_ARROW": "videoCloseButton",
        "RIGHT_ARROW": "videoPlayButton"
      },
    },
    "videoPlayButton": {
      "tabIndex": "tabIndex30",
      "positionsMap": {
        "UP_ARROW": "videoCloseButton",
        "LEFT_ARROW": "videoBackwardButton",
        "RIGHT_ARROW": "videoForwardButton"
      },
    },
    "videoForwardButton": {
      "tabIndex": "tabIndex30-2",
      "positionsMap": {
        "UP_ARROW": "videoCloseButton",
        "LEFT_ARROW": "videoPlayButton",
        "RIGHT_ARROW": "videoToggleSubtitleButton"
      },
    },

    "videoToggleSubtitleButton": {
      "tabIndex": "tabIndex29",
      "positionsMap": {
        "UP_ARROW": "videoCloseButton",
        "LEFT_ARROW": "videoForwardButton",
        "RIGHT_ARROW": "videoToggleSpeedButton"
      },
    },
    
    "videoToggleSpeedButton": {
      "tabIndex": "tabIndex117",
      "positionsMap": {
        "UP_ARROW": "videoCloseButton",
      },
    },



    "exerciseCloseXContinuar": {
      "tabIndex": "tabIndex36",
      "positionsMap": {
        "DOWN_ARROW": "exerciseCloseXExit"
      },
    },
    "exerciseCloseXExit": {
      "tabIndex": "tabIndex37",
      "positionsMap": {
        "UP_ARROW": "exerciseCloseXContinuar"
      },
    },
    "ResultCloseX": {
      "tabIndex": "tabIndex38",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "DOWN_ARROW": "--"
      },
    },
    "ResultListeningIcon": {
      "tabIndex": "listening",
      "positionsMap": {
        "RIGHT_ARROW": "ResultWritingIcon",
        "DOWN_ARROW": "ResultSpeakingIcon",
        "LEFT_ARROW": "ResultCloseX",
        "UP_ARROW": "ResultCloseX"
      },
    },
    "ResultWritingIcon": {
      "tabIndex": "writing",
      "positionsMap": {
        "RIGHT_ARROW": "ResultReadingIcon",
        "DOWN_ARROW": "ResultSpeakingIcon",
        "LEFT_ARROW": "ResultListeningIcon"
      },
    },
    "ResultReadingIcon": {
      "tabIndex": "reading",
      "positionsMap": {
        "DOWN_ARROW": "ResultVocabularyIcon",
        "LEFT_ARROW": "ResultWritingIcon"
      },
    },
    "ResultSpeakingIcon": {
      "tabIndex": "speaking",
      "positionsMap": {
        "RIGHT_ARROW": "ResultVocabularyIcon",
        "UP_ARROW": "ResultListeningIcon",
        "DOWN_ARROW": "--"
      },
    },
    "ResultVocabularyIcon": {
      "tabIndex": "vocabulary",
      "positionsMap": {
        "LEFT_ARROW": "ResultSpeakingIcon",
        "UP_ARROW": "ResultWritingIcon",
        "DOWN_ARROW": "--"
      },
    },
    "ResultVideoButton": {
      "tabIndex": "tabIndex44",
      "positionsMap": {
        "RIGHT_ARROW": "ResultGrammarButton",
        "UP_ARROW": "ResultSpeakingIcon"
      },
    },
    "ResultGrammarButton": {
      "tabIndex": "tabIndex45",
      "positionsMap": {
        "RIGHT_ARROW": "ResultNextLessonButton",
        "UP_ARROW": "ResultVocabularyIcon",
        "LEFT_ARROW": "ResultVideoButton"
      },
    },
    "ResultReviewButton": {
      "tabIndex": "tabIndex46",
      "positionsMap": {
        "RIGHT_ARROW": "ResultNextLessonButton",
        "UP_ARROW": "ResultSpeakingIcon"
      },
    },
    "ResultNextLessonButton": {
      "tabIndex": "tabIndex47",
      "positionsMap": {
        "LEFT_ARROW": "--",
        "UP_ARROW": "ResultVocabularyIcon"
      },
    },
    "ResultImproveResult": {
      "tabIndex": "tabIndex48",
      "positionsMap": {
        "RIGHT_ARROW": "ResultNextLessonButtonTest",
        "UP_ARROW": "ResultCloseX",
        "LEFT_ARROW": "ResultCloseX"
      },
    },
    "ResultNextLessonButtonTest": {
      "tabIndex": "tabIndex49",
      "positionsMap": {
        "UP_ARROW": "ResultCloseX",
        "LEFT_ARROW": "--"
      },
    },
    "exerciseListen": {
      "tabIndex": "tabIndex50",
      "positionsMap": {
        "RIGHT_ARROW": "exerciseRecord",
        "LEFT_ARROW": "--",
        "UP_ARROW": "exerciseCloseX"
      },
    },
    "exerciseRecord": {
      "tabIndex": "tabIndex51",
      "positionsMap": {
        "RIGHT_ARROW": "exerciseCompare",
        "LEFT_ARROW": "exerciseListen"
      },
    },
    "exerciseCompare": {
      "tabIndex": "tabIndex52",
      "positionsMap": {
        "RIGHT_ARROW": "exerciseSkip",
        "LEFT_ARROW": "exerciseRecord"
      },
    },
    "exerciseMultil": {
      "tabIndex": "tabIndex53",
      "positionsMap": {
        "LEFT_ARROW": "exerciseCloseX",
        "DOWN_ARROW": "exerciseChoose0"
      },
    },
    "exerciseChoose0": {
      "tabIndex": "choose0",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "LEFT_ARROW": "--",
        "UP_ARROW": "--",
        "DOWN_ARROW": "--"
      },
    },
    "exerciseChoose1": {
      "tabIndex": "choose1",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "LEFT_ARROW": "--",
        "UP_ARROW": "--",
        "DOWN_ARROW": "--"
      },
    },
    "exerciseChoose2": {
      "tabIndex": "choose2",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "LEFT_ARROW": "--",
        "UP_ARROW": "--",
        "DOWN_ARROW": "--"
      },
    },
    "subscriptionPlanListPopupCancelledButton": {
      "tabIndex": "tabIndex64",
      "positionsMap": {
        "RIGHT_ARROW": "--",
        "DOWN_ARROW": "--"
      },
    },
    "subscriptionPaymentCondition": {
      "tabIndex": "tabIndex73",
      "positionsMap": {
        "RIGHT_ARROW": "subscriptionSubscribeButton",
        "LEFT_ARROW": "--",
        "UP_ARROW": "--"
      },
    },
    "subscriptionSubscribeButton": {
      "tabIndex": "tabIndex75",
      "positionsMap": {
        "LEFT_ARROW": "subscriptionPaymentCondition",
        "UP_ARROW": "--"
      },
    },
    "repasoLevelSelect": {
      "tabIndex": "tabIndex76",
      "positionsMap": {
        "RIGHT_ARROW": "repasoIniciarButton",
        "DOWN_ARROW": "RepasoLessonsList",
        "LEFT_ARROW": "--"
      },
    },
    "repasoIniciarButton": {
      "tabIndex": "tabIndex77",
      "positionsMap": {
        "DOWN_ARROW": "RepasoLessonsList",
        "LEFT_ARROW": "repasoLevelSelect"
      },
    },
    "RepasoLessonsList": {
      "tabIndex": "tabIndex78",
      "positionsMap": {
        "UP_ARROW": "repasoLevelSelect",
        "LEFT_ARROW": "--"
      },
    },
    //81
    "closeTutorial": {
      "tabIndex": "tabIndex81",
      "positionsMap": {
        "ENTER": "--"
      },
    },
    "mainGoSubscriptionButton": {
      "tabIndex": "tabIndex90",
      "positionsMap": {
        "DOWN_ARROW": "--",
        "RIGHT_ARROW": "--"
      },
    },
    "mainDownloadCertificateButton": {
      "tabIndex": "tabIndex91",
      "positionsMap": {
        "DOWN_ARROW": "--",
        "RIGHT_ARROW": "--"
      },
    },
    "userAccountChooseLevel": {
      "tabIndex": "tabIndex71",
      "positionsMap": {
        "DOWN_ARROW": "--",
        "RIGHT_ARROW": "--",
        "UP_ARROW": "userAccountPictureClick"
      },
    },
    "userAccountPictureClick": {
      "tabIndex": "tabIndex109",
      "positionsMap": {
        "DOWN_ARROW": "userAccountChooseLevel",
        "RIGHT_ARROW": "--"
      },
    },
    "userAccountCloseSession": {
      "tabIndex": "tabIndex79",
      "positionsMap": {
        "DOWN_ARROW": "userAccountCancelLogoutPopup"
      },
    },
    "userAccountCancelLogoutPopup": {
      "tabIndex": "tabIndex80",
      "positionsMap": {
        "UP_ARROW": "userAccountCloseSession"
      },
    },
    "userAccountCertificatesBackButton": {
      "tabIndex": "tabIndex88",
      "positionsMap": {
        "DOWN_ARROW": "--",
        "RIGHT_ARROW": "--"
      },
    },
    "leccionesIncluidasBackButton": {
      "tabIndex": "tabIndex89",
      "positionsMap": {
        "DOWN_ARROW": "leccionesIncluidasLevelSelect",
        "RIGHT_ARROW": "leccionesIncluidasLevelSelect"
      },
    },
    "leccionesIncluidasLevelSelect": {
      "tabIndex": "tabIndex110",
      "positionsMap": {
        "LEFT_ARROW": "leccionesIncluidasBackButton",
        "DOWN_ARROW": "--",
        "UP_ARROW": "leccionesIncluidasBackButton",
      },
    },
    "authenticationShowCourseDetails": {
      "tabIndex": "tabIndex102",
      "positionsMap": {
        "DOWN_ARROW": "authenticationAlreadyHaveAccount"
      },
    },
    "authenticationAlreadyHaveAccount": {
      "tabIndex": "tabIndex103",
      "positionsMap": {
        "UP_ARROW": "authenticationShowCourseDetails"
      },
    },
    "authenticationTry14Days": {
      "tabIndex": "tabIndex104",
      "positionsMap": {},
    },
    "authenticationLangSelection": {
      "tabIndex": "tabIndex114",
      "positionsMap": {},
    },
    "authenticationOnLangConfirmed": {
      "tabIndex": "tabIndex115",
      "positionsMap": {
        "UP_ARROW": "authenticationOnLangConfirmedBackButton",
        "LEFT_ARROW": "authenticationOnLangConfirmedBackButton"
      },
    },
    "authenticationOnLangConfirmedBackButton": {
      "tabIndex": "tabIndex116",
      "positionsMap": {
        "DOWN_ARROW": "authenticationOnLangConfirmed",
        "RIGHT_ARROW": "authenticationOnLangConfirmed"
      },
    },
    "authenticationMethodsEmail": {
      "tabIndex": "tabIndex105",
      "positionsMap": {
        "DOWN_ARROW": "authenticationMethodsGoogle",
        "RIGHT_ARROW": "authenticationMethodsFacebook"
      },
    },
    "authenticationMethodsFacebook": {
      "tabIndex": "tabIndex106",
      "positionsMap": {
        "DOWN_ARROW": "authenticationMethodsApple",
        "LEFT_ARROW": "authenticationMethodsEmail"
      },
    },
    "authenticationMethodsGoogle": {
      "tabIndex": "tabIndex107",
      "positionsMap": {
        "UP_ARROW": "authenticationMethodsEmail",
        "RIGHT_ARROW": "authenticationMethodsApple"
      },
    },
    "authenticationMethodsApple": {
      "tabIndex": "tabIndex108",
      "positionsMap": {
        "UP_ARROW": "authenticationMethodsFacebook",
        "LEFT_ARROW": "authenticationMethodsGoogle"
      },
    },
  }

  elementManualFocusMap = ["exerciseDiscard0", "exerciseOrder0", "exerciseFill", "videoSubtitleLanguages",
    "mainTab1Lesson", "mainVocabLesson", "tabsMenu", "mainLessonLevel", "userAccountSelectLevelOptions",
    "userAccountOptions", "userAccountCertificates", "subscriptionPlansBenifits", "subscriptionPlanListOptions",
    "repasoLevelSelectOptions", "leccionesIncluidasLevelSelectOptions", "leccionesIncluidasItemsList", 
    "authenticationLangSelectionToggle", "videoSpeed", "closeTutorial"];

  SPEECH_LANGUAGE = 'en-GB';
  tabIndex: any;
  mainMenuIndex: any;
  isPWA: any;
  isFillKeyboardEnabled: any = "enable";
  focusEnable: boolean = true;
  isLandscapeView:boolean = true;
  constructor(
    private platform: Platform
  ) {
    this.isPWA = this.platform.is("cordova") ? false : true;
    this.isLandscapeView = window.innerWidth > 991 ? true : false;
    $(window).resize(()=>{
      this.isLandscapeView = window.innerWidth > 991 ? true : false;
    });
  }

  setFocusTo(htmlElement) {
    let isReady = this.isPWA && this.elementFocusMap[htmlElement];
    if (isReady) {
      let elementIndex = this.elementFocusMap[htmlElement].tabIndex;
      let htmlElementFocus = $('.' + elementIndex);
      if(htmlElementFocus.parents('.lesson-cards')[0]) {
        htmlElementFocus = $(".swiper-slide-active").find('.' + elementIndex);
      }
      htmlElementFocus.focus();
      this.tabIndex = htmlElement;
    }
  }

  hasFocus(htmlElement) {
    let isReady = this.isPWA && this.elementFocusMap[htmlElement];
    if (isReady) {
      if(this.tabIndex == htmlElement) return true;
      else false;
    }
  }

  setManualFocusTo(htmlElement, name, index, type) {
    let isReady = this.isPWA && this.elementManualFocusMap.includes(htmlElement);
    if (isReady) {
      let htmlElementFocus = type == "class" ? $('.' + name + index) : $('#' + name + index);
      htmlElementFocus.focus();
      this.tabIndex = htmlElement;
    }
  }

  setPositionsFocus(position) {
    this.setFocusTo(this.elementFocusMap[this.tabIndex].positionsMap[position]);
  }
}
